import { Box, Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import OptimizedFlats from "../OptimizedFlats";
import AddNewTrimDialog from "./AddNewTrimDialog";
import { v4 as uuidv4 } from "uuid";
import CopingCapPiece from "./CopingCapPiece";

const CopingCap = ({
  companyId,
  flatSheetSize,
  defaultVariables,
  copingCap,
  onUpdateCopingCap,
}) => {
  const [copingCaporderItem, setCopingCapOrderItem] = useState(null);

  const [addTrimDialogOpen, setAddTrimDialogOpen] = useState(false);

  // TODO: capture quantity and stretchout updates here. Need to reoptimize flats before state update

  const onAddTrim = (newTrim) => {
    let capPiecesCopy = [...copingCaporderItem.capPieces];
    capPiecesCopy.push({
      quantity: 1,
      stretchOut: 1,
      cap: newTrim,
      hits: 1,
      variables: { ...defaultVariables },
    });

    setCopingCapOrderItem((prev) => ({ ...prev, capPieces: capPiecesCopy }));
  };

  const onRemovePiece = (index) => {
    let capPiecesCopy = [...copingCaporderItem.capPieces];
    capPiecesCopy.splice(index, 1);

    setCopingCapOrderItem((prev) => ({ ...prev, capPieces: capPiecesCopy }));
  };

  const onUpdateTrimPiece = (trimPieceIndex, updateObject) => {
    let capPiecesCopy = [...copingCaporderItem.capPieces];
    capPiecesCopy[trimPieceIndex] = {
      ...capPiecesCopy[trimPieceIndex],
      ...updateObject,
    };

    setCopingCapOrderItem((prev) => ({ ...prev, capPieces: capPiecesCopy }));
  };

  useEffect(() => {
    if (copingCap) {
      setCopingCapOrderItem(copingCap);
    } else {
      setCopingCapOrderItem(null);
    }
  }, [copingCap]);

  if (copingCaporderItem)
    return (
      <Box>
        <Typography sx={{ flex: 1 }} variant="h6">
          Coping Cap
        </Typography>
        {/* TODO: Need to transfer control out of OptimizedFlats unto copingCap */}
        <Box sx={{ marginTop: "8px" }}>
          <OptimizedFlats
            flatSheetSize={flatSheetSize}
            pieces={copingCaporderItem.capPieces}
            onOptimize={(totalFlats, totalInchesDrop) => {
              console.log("OnOptimize", totalFlats, totalInchesDrop);
              onUpdateCopingCap({
                ...copingCaporderItem,
                totalFlats,
                totalInchesDrop,
              });
            }}
          />
        </Box>

        <Box>
          {copingCaporderItem &&
            copingCaporderItem.capPieces &&
            copingCaporderItem.capPieces.map((piece, index) => {
              return (
                <CopingCapPiece
                  key={piece.id ?? uuidv4()}
                  piece={piece}
                  onUpdatePiece={(updateObject) => {
                    onUpdateTrimPiece(index, updateObject);
                  }}
                  onRemovePiece={() => {
                    onRemovePiece(index);
                  }}
                />
              );
            })}
        </Box>
        <Box>
          <Button
            onClick={() => {
              setAddTrimDialogOpen(true);
            }}
          >
            + Add New
          </Button>
        </Box>
        <AddNewTrimDialog
          companyId={companyId}
          trimType="CopingCap"
          open={addTrimDialogOpen}
          handleClose={() => {
            setAddTrimDialogOpen(false);
          }}
          handleSubmit={(newTrim) => {
            onAddTrim(newTrim);
          }}
        />
      </Box>
    );
};

export default CopingCap;
