import {
  Box,
  Card,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import SelectCustomer from "./SelectCustomer";
import SelectProject from "./SelectProject";
import { Edit } from "@mui/icons-material";
import SelectPrimaryContact from "./SelectPrimaryContact";
import { useTheme } from "@emotion/react";

const TopSection = ({ quote, onUpdateQuote }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onUpdateCustomer = (company) => {
    // Set Company Value and Set Project and vendorProjectName to Null
    let update = {
      company: company,
      project: null, // Reset Project
      vendorProjectName: null, // Reset Vendor Project Name
      primaryContact: null, // Reset Primary Contact Selected
      estimateRequestedBy: null, // Reset Primary Contact Selected
      estimateAssignedApprover: null, // Reset Primary Contact Selected
    };
    onUpdateQuote(update);
  };

  const onUpdateProject = (project) => {
    // Set Company Value and Set Project to Null
    let update = { project: project };
    onUpdateQuote(update);
  };

  const onUpdatePrimaryContact = (primaryContact) => {
    // Set Company Value and Set Project to Null
    let update = {
      primaryContact: primaryContact,
      estimateRequestedBy: primaryContact,
      estimateAssignedApprover: primaryContact,
    };
    onUpdateQuote(update);
  };

  if (quote)
    return (
      <Box>
        <Typography variant="h6">Project Details</Typography>
        <Box sx={{ marginTop: "8px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            <Box sx={{ flex: 1 }}>
              <SelectCustomer
                customer={quote.company}
                onSelect={(company) => {
                  onUpdateCustomer(company);
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                marginLeft: isSmallScreen ? 0 : "8px",
                marginTop: isSmallScreen ? "8px" : 0,
              }}
            >
              <SelectProject
                selectedCompany={quote.company}
                project={quote.project}
                onSelect={(project) => {
                  onUpdateProject(project);
                }}
              />
            </Box>
            <Box
              sx={{
                flex: 1,
                marginLeft: isSmallScreen ? 0 : "8px",
                marginTop: isSmallScreen ? "8px" : 0,
              }}
            >
              <SelectPrimaryContact
                selectedCompany={quote.company}
                primaryContact={quote.estimateRequestedBy}
                onSelect={(primaryContact) => {
                  onUpdatePrimaryContact(primaryContact);
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: isSmallScreen ? 0 : "8px",
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
            }}
          >
            {quote.company && quote.company.isVendorOrSupplier && (
              <TextField
                sx={{
                  flex: 1,
                  marginTop: isSmallScreen ? "8px" : 0,
                  marginRight: isSmallScreen ? 0 : "8px",
                }}
                variant="outlined"
                label="Vendor/Supplier Project Name"
                id="project-name"
                name="Project Name"
                size="small"
                fullWidth
                value={quote.vendorProjectName}
                onChange={(e) => {
                  let update = { vendorProjectName: e.target.value };
                  onUpdateQuote(update);
                }}
              />
            )}
            <TextField
              sx={{
                flex: 1,

                marginTop: isSmallScreen ? "8px" : 0,
              }}
              variant="outlined"
              label="Sub-Project Name (ie Bldg #, etc)"
              id="sub-project"
              name="Sub-Project Name (ie Bldg #, etc)"
              size="small"
              fullWidth
              value={quote.subProject}
              onChange={(e) => {
                let update = { subProject: e.target.value };
                onUpdateQuote(update);
              }}
            />
            <TextField
              sx={{
                flex: 1,
                marginLeft: isSmallScreen ? 0 : "8px",
                marginTop: isSmallScreen ? "8px" : 0,
              }}
              variant="outlined"
              label="P.O.#"
              id="po-number"
              name="PO Number"
              size="small"
              fullWidth
              value={quote.poNumber}
              onChange={(e) => {
                let update = { poNumber: e.target.value };
                onUpdateQuote(update);
              }}
            />
          </Box>
        </Box>
      </Box>
    );
};

export default TopSection;
