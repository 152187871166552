import { Slider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";

const DebouncedSlider = ({ value, onChange, ...props }) => {
  const [sliderVal, setSliderVal] = useState(value ?? 0);
  const [sliderValDebounced] = useDebounce(sliderVal, 1000);

  useEffect(() => {
    let e = {
      target: {
        value: sliderValDebounced,
      },
    };
    if (onChange) onChange(e);
  }, [sliderValDebounced]);

  return (
    <Slider
      {...props}
      color={sliderVal == 100 ? "success" : "warning"}
      onChange={(e) => {
        setSliderVal(e.target.value);
      }}
      value={sliderVal}
    />
  );
};

export default DebouncedSlider;
