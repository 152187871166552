import {
  Avatar,
  Box,
  Card,
  Checkbox,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import usePaintManufacturers from "../../../../../hooks/usePaintManufacturers";
import useAllColorsbyManufacturer from "../../../../../hooks/useAllColorsByManufacturer";

const SelectMaterial = ({ quote, onUpdateQuote }) => {
  const { paintManufacturers } = usePaintManufacturers();
  const { colors, getColors } = useAllColorsbyManufacturer();
  const [coilWidthOptions, setCoilWidthOptions] = useState([]);

  const handleManufacturerChange = (e) => {
    let manufacturerName = e.target.value;
    let manufacturer = paintManufacturers.find(
      (manufacturer) => manufacturer.name == manufacturerName
    );
    getColors(manufacturer.id);
    setCoilWidthOptions([]);

    let update = {
      manufacturer: manufacturer.name,
      color: null,
      gauge: null,
      flatSheetCost: 0,
      flatSheetCostOriginal: 0,
      coilCostPerSqFt: 0,
      coilCostPerSqFtOriginal: 0,
      flatSheetSize: null,
    };
    onUpdateQuote(update);
  };

  const handleColorChange = (e) => {
    let colorName = e.target.value;
    let color = colors.find((c) => c.name == colorName);

    let materialData = {
      color: color.name,
      flatSheetCost: 0,
      flatSheetCostOriginal: 0,
      coilCostPerSqFt: 0,
      coilCostPerSqFtOriginal: 0,
      flatSheetSize: null,
    };
    onUpdateQuote(materialData);
  };

  const handleGaugeChange = (gaugeValue) => {
    let selectedColor = colors.find((c) => c.name == quote.color);
    let gauge = selectedColor.gauges.find(
      (g) => parseFloat(g.gauge) == parseFloat(gaugeValue)
    );

    if (gauge) {
      setCoilWidthOptions(gauge.coilWidths);
    }

    let materialData = {
      gauge: parseFloat(gaugeValue).toFixed(4),
      flatSheetCost: 0,
      flatSheetCostOriginal: 0,
      coilCostPerSqFt: 0,
      coilCostPerSqFtOriginal: 0,
      flatSheetSize: null,
    };
    onUpdateQuote(materialData);
  };

  const handleFSOptionSelect = (fsWidthOption) => {
    const index = coilWidthOptions.findIndex((c) => c.width == fsWidthOption);
    let coilOptionSelected = coilWidthOptions[index];

    // If quote is customer supplied, then set all prices to zero
    if (quote.isCustomerSupplied) {
      let update = {
        flatSheetSize: fsWidthOption,
        flatSheetCost: 0,
        flatSheetCostOriginal: 0,
        coilCostPerSqFt: 0,
        coilCostPerSqFtOriginal: 0,
      };
      onUpdateQuote(update);
    } else {
      let update = {
        flatSheetSize: fsWidthOption,
        flatSheetCost: coilOptionSelected ? coilOptionSelected?.price : 0,
        flatSheetCostOriginal: coilOptionSelected
          ? coilOptionSelected?.price
          : 0,
        coilCostPerSqFt: coilOptionSelected
          ? coilOptionSelected?.pricePerSqFt
          : 0,
        coilCostPerSqFtOriginal: coilOptionSelected
          ? coilOptionSelected?.pricePerSqFt
          : 0,
      };
      onUpdateQuote(update);
    }
  };

  useEffect(() => {
    // If manufacturer list is loaded and a manufacturer has been selected, then load the colors
    if (
      paintManufacturers &&
      paintManufacturers.length > 0 &&
      quote.manufacturer
    ) {
      let selectedManufacturer = paintManufacturers.find(
        (m) => m.name == quote.manufacturer
      );
      if (selectedManufacturer) getColors(selectedManufacturer.id);
    }
  }, [paintManufacturers, quote.manufacturer]);

  useEffect(() => {
    // If colors are loaded and color and gauge are selected, then set the coil width options
    if (colors && colors.length > 0 && quote.manufacturer) {
      let selectedColor = colors.find((c) => c.name == quote.color);
      if (selectedColor) {
        let selectedGauge = selectedColor.gauges.find(
          (g) => parseFloat(g.gauge) == parseFloat(quote.gauge)
        );

        if (selectedGauge) {
          setCoilWidthOptions(selectedGauge.coilWidths);
        }
      }
    }
  }, [colors, quote.color, quote.gauge]);

  return (
    <Box>
      <TextField
        size="small"
        sx={{ marginTop: "16px" }}
        fullWidth
        select
        label="Manufacturer"
        value={quote.manufacturer ? quote.manufacturer : null}
        onChange={handleManufacturerChange}
      >
        {paintManufacturers.map((m, index) => (
          <MenuItem key={m.id} value={m.name}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {m.name}
            </Box>
          </MenuItem>
        ))}
      </TextField>
      <TextField
        size="small"
        sx={{ marginTop: "16px" }}
        disabled={!quote.manufacturer}
        fullWidth
        select
        label="Color"
        value={quote.color ? quote.color : null}
        onChange={handleColorChange}
      >
        {colors.map((theColor, index) => (
          <MenuItem key={theColor.id} value={theColor.name}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Avatar
                src={theColor.image}
                sx={{
                  width: 24,
                  height: 24,
                  marginRight: "1rem",
                }}
              />{" "}
              {theColor.name} - {theColor.paintManufacturer.name} - [
              {theColor.status}]
            </Box>
          </MenuItem>
        ))}
      </TextField>

      <TextField
        size="small"
        sx={{ marginTop: "16px" }}
        fullWidth
        disabled={!quote.color}
        select
        label="Gauge"
        value={
          quote.gauge
            ? parseFloat(parseFloat(quote.gauge).toFixed(4)).toString()
            : null
        }
        onChange={(e) => {
          handleGaugeChange(e.target.value);
        }}
      >
        {quote.color &&
          colors.find((c) => c.name == quote.color) &&
          colors
            .find((c) => c.name == quote.color)
            .gauges.map((theGauge, index) => (
              <MenuItem
                key={theGauge.id}
                value={parseFloat(
                  parseFloat(theGauge.gauge).toFixed(4)
                ).toString()}
              >
                {parseFloat(parseFloat(theGauge.gauge).toFixed(4)).toString()}
              </MenuItem>
            ))}
      </TextField>

      <TextField
        size="small"
        sx={{ marginTop: "16px" }}
        disabled={!quote.gauge}
        // sx={{ width: 400 }}
        fullWidth
        label="Material Width"
        select
        value={quote.flatSheetSize ? parseFloat(quote.flatSheetSize) : null}
        onChange={(e) => {
          handleFSOptionSelect(e.target.value);
        }}
      >
        {coilWidthOptions?.map((coilWidth, index) => (
          <MenuItem key={index} value={parseFloat(coilWidth.width)}>
            {coilWidth.width} in. - ${coilWidth.price} Per FS, $
            {coilWidth.pricePerSqFt} Per Sq Ft
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

export default SelectMaterial;
