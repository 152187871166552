import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import { HeadingContextProvider } from "../context/HeadingContext";
// import Alerts from "../pages/employee/Alerts/Alerts";
// import Settings from "../pages/customer/Settings/Settings";
import Error404 from "../pages/error/Error404";
import { MobileOrderContextProvider } from "../context/MobileOrderContext";
import ShopDashboard from "../pages/shop/ShopDashboard";
import Orders from "../pages/shop/Orders/Orders";
import TimeCards from "../pages/shop/TimeCards/TimeCards";
import TimeApprovals from "../pages/shop/TimeApprovals/TimeApprovals";
import CreateNewTimeCard from "../pages/shop/TimeCards/CreateNewTimeCard";
import Order from "../pages/shop/Orders/Order/Order";
import TimeApproval from "../pages/shop/TimeApprovals/TimeApproval";
import TimeCardDetails from "../pages/shop/TimeCards/TimeCardDetails";
import Jobs from "../pages/shop/Jobs/Jobs";
import OrderPickupHistoryPage from "../pages/shop/ReadyForPickup/OrderPickupHistory";
import NewPickupPage from "../pages/shop/ReadyForPickup/NewPickupPage";
import PickupDetailsPage from "../pages/shop/ReadyForPickup/PickupDetailsPage";
import ReadyForPickupPage from "../pages/shop/ReadyForPickup/ReadyForPickupPage";

const ShopRoutes = () => {
  console.log("Shop Employee Routes");
  return (
    <HeadingContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<ShopDashboard />}>
            <Route
              path="/"
              element={
                <MobileOrderContextProvider>
                  <Jobs />
                </MobileOrderContextProvider>
              }
            />
            <Route path="/shop-schedule" element={<Jobs />} />
            <Route path="ready-for-pickup" element={<ReadyForPickupPage />} />
            <Route
              path="ready-for-pickup/:orderId"
              element={<OrderPickupHistoryPage />}
            />
            <Route
              path="ready-for-pickup/:orderId/new-pickup"
              element={<NewPickupPage />}
            />
            <Route
              path="ready-for-pickup/:orderId/pickups/:pickupId"
              element={<PickupDetailsPage />}
            />
            <Route
              path="/orders/active"
              element={<Orders resource="orders" activeTab="active" />}
            />
            <Route
              path="/orders/in-progress"
              element={<Orders resource="orders" activeTab="in-progress" />}
            />
            <Route
              path="/orders/ready"
              element={<Orders resource="orders" activeTab="ready" />}
            />
            <Route
              path="/orders"
              element={<Orders resource="orders" activeTab="in-progress" />}
            />
            <Route
              path="/orders/:id"
              element={
                <MobileOrderContextProvider>
                  <Order />
                </MobileOrderContextProvider>
              }
            />
            <Route path="time-cards" element={<TimeCards />} />
            <Route path="time-cards/new" element={<CreateNewTimeCard />} />
            <Route path="time-cards/:id" element={<TimeCardDetails />} />
            <Route path="time-approvals" element={<TimeApprovals />} />
            <Route path="time-approvals/:id" element={<TimeApproval />} />
            {/* <Route path="settings" element={<Settings />} /> */}
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HeadingContextProvider>
  );
};
export default ShopRoutes;
