import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  DatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AuthContext } from "../../../../context/AuthContext";
import { CheckCircle, Close } from "@mui/icons-material";
import moment from "moment-timezone";
import useShopEmployees from "../../../../hooks/useShopEmployees";

const EditJobDialog = ({
  dialogData,
  handleClose,
  handleSubmit,
  errorMessage,
}) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { shopEmployees } = useShopEmployees(authToken);

  const [calendarEvent, setCalendarEvent] = useState(null);
  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateError, setDateError] = useState(false);
  const [assignedTo, setAssignedTo] = useState(null);
  const [phases, setPhases] = useState([]);

  const [scheduledSubPhases, setScheduledSubPhases] = useState([]);
  const [loadingPhases, setLoadingPhases] = useState(false);

  const [isOriginalDate, setOriginalDate] = useState(true); // To prevent updating times on inital load

  const getEventDetails = async (eventId) => {
    try {
      setLoadingPhases(true);
      const res = await ezorder.get(`/shop/schedule/events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let event = res.data.calendarEvent;
      setCalendarEvent(event);
      setAssignedTo(event.assignedTo);
      setDate(event.startDate);
      setStartDate(event.startDate);
      setEndDate(event.endDate);

      // setPhases(event.order.phases);
      setScheduledSubPhases([...event.subPhases]);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoadingPhases(false);
    }
  };

  const getJobPhases = async (jobId) => {
    try {
      setLoadingPhases(true);
      const res = await ezorder.get(`/shop/jobs/${jobId}/phases`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(res);
      let jobInfo = res.data.job;

      setPhases(jobInfo.phases);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoadingPhases(false);
    }
  };

  useEffect(() => {
    if (dialogData.open && dialogData.calendarEvent) {
      // TODO: get jobPhases
      getEventDetails(dialogData.calendarEvent.id);
      getJobPhases(dialogData.calendarEvent.order.id);
    } else {
      setAssignedTo(null);
      setDate(null);
      setPhases([]);
      setStartDate(null);
      setEndDate(null);
      setScheduledSubPhases([]);
    }
  }, [dialogData]);

  // When changing the date, update the date on the start and end time
  useEffect(() => {
    if (date && !isOriginalDate) {
      let start = moment(date)
        .tz("America/Phoenix")
        .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      let end = moment(date)
        .tz("America/Phoenix")
        .set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      console.log(start, end);
      setStartDate(start);
      setEndDate(end);
    }
  }, [date, isOriginalDate]);

  if (dialogData)
    return (
      <Dialog
        open={dialogData.open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
      >
        {dialogData.calendarEvent && (
          <>
            <DialogTitle sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                {dialogData.calendarEvent.order &&
                  dialogData.calendarEvent.order.company &&
                  dialogData.calendarEvent.order.project &&
                  dialogData.calendarEvent.order.orderStatus && (
                    <>
                      {!dialogData.calendarEvent.order.orderStatus ||
                      !dialogData.calendarEvent.order.orderStatus.name ||
                      dialogData.calendarEvent.order.orderStatus.name ==
                        "SAVED" ||
                      dialogData.calendarEvent.order.orderStatus.name ==
                        "QUOTE_REQUESTED" ||
                      dialogData.calendarEvent.order.orderStatus.name ==
                        "ESTIMATE_APPROVAL_NEEDED"
                        ? "Quote "
                        : "Order "}
                      #{dialogData.calendarEvent.order.id} -{" "}
                      {dialogData.calendarEvent.order.company.name}
                      <DialogContentText sx={{ display: "block" }}>
                        {dialogData.calendarEvent.order.company
                          .isVendorOrSupplier
                          ? "Vendor/Supplier Customer"
                          : "Project"}
                        : {dialogData.calendarEvent.order.project.name}
                      </DialogContentText>
                      {dialogData.calendarEvent.order.vendorProjectName && (
                        <DialogContentText sx={{ display: "block" }}>
                          Project:{" "}
                          {dialogData.calendarEvent.order.vendorProjectName}
                        </DialogContentText>
                      )}
                      {dialogData.calendarEvent.order.subProject && (
                        <DialogContentText sx={{ display: "block" }}>
                          Sub-Project:{" "}
                          {dialogData.calendarEvent.order.subProject}
                        </DialogContentText>
                      )}
                      <DialogContentText sx={{ display: "block" }}>
                        {dialogData.calendarEvent.order.poNumber
                          ? `PO#: ${dialogData.calendarEvent.order.poNumber}`
                          : ""}
                      </DialogContentText>
                    </>
                  )}
              </Box>
              <Box>
                <IconButton onClick={handleClose} sx={{}}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>

            {loadingPhases ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "200px",
                }}
              >
                <CircularProgress size="3rem" />
              </Box>
            ) : (
              <>
                {calendarEvent && (
                  <DialogContent>
                    <Divider sx={{ marginBottom: "10px" }} />
                    <Box>
                      {/* <DialogContentText>
                        Assigned To: {calendarEvent?.assignedTo?.fullName}
                      </DialogContentText> */}
                      {/* Employee Select */}
                      <Box sx={{ marginTop: "12px" }}>
                        <TextField
                          size="small"
                          fullWidth
                          label="Assign To"
                          select
                          value={assignedTo?.id}
                          onChange={(e) => {
                            let selectedEmployee = shopEmployees.find(
                              (employee) => employee.id == e.target.value
                            );
                            setAssignedTo(selectedEmployee);
                          }}
                        >
                          {shopEmployees &&
                            shopEmployees.map((employee, index) => (
                              <MenuItem key={employee.id} value={employee.id}>
                                {employee.fullName}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Box>

                      <Box sx={{ width: "100%", paddingTop: "8px" }}>
                        {/* Date */}
                        <Box sx={{ marginTop: "16px" }}>
                          <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                              sx={{ marginRight: "10px", marginBottom: "15px" }}
                              format="MM/DD/YYYY"
                              label="Date"
                              value={date}
                              onChange={(newValue) => {
                                setOriginalDate(false);
                                setDate(newValue?.toDate());
                              }}
                              renderInput={(params) => (
                                <TextField
                                  size="small"
                                  fullWidth
                                  sx={{ marginTop: "8px" }}
                                  {...params}
                                />
                              )}
                            />
                            {date && (
                              <Box
                                sx={{ display: "flex", flexDirection: "row" }}
                              >
                                <DesktopTimePicker
                                  label="From"
                                  value={startDate}
                                  onChange={(newValue) => {
                                    setStartDate(newValue?.toDate());
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      fullWidth
                                      sx={{
                                        marginTop: "8px",
                                        marginRight: "8px",
                                      }}
                                      {...params}
                                    />
                                  )}
                                />
                                <DesktopTimePicker
                                  label="To"
                                  value={endDate}
                                  onChange={(newValue) => {
                                    setEndDate(newValue?.toDate());
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      size="small"
                                      fullWidth
                                      sx={{
                                        marginTop: "8px",
                                        marginLeft: "8px",
                                      }}
                                      {...params}
                                    />
                                  )}
                                />
                              </Box>
                            )}
                          </LocalizationProvider>
                        </Box>
                        <Divider sx={{ marginY: "10px" }} />

                        <Typography variant="h6">Tasks</Typography>

                        {/* Phases */}

                        <Box>
                          {phases &&
                            phases.map((phase) => (
                              <Box key={phase.id}>
                                <DialogContentText>
                                  {phase.name}
                                </DialogContentText>
                                <Box sx={{ paddingLeft: "16px" }}>
                                  {phase.subPhases &&
                                    phase.subPhases.map((subPhase) => {
                                      let lastScheduled = "TBD";
                                      if (
                                        subPhase.calendarEvents &&
                                        subPhase.calendarEvents.length > 0
                                      ) {
                                        let latest = null;
                                        for (let event of subPhase.calendarEvents) {
                                          latest =
                                            latest > new Date(event.endDate)
                                              ? latest
                                              : new Date(event.endDate);
                                        }

                                        lastScheduled = `${
                                          subPhase.calendarEvents[0].assignedTo
                                            ? subPhase.calendarEvents[0]
                                                .assignedTo.fullName
                                            : ""
                                        } on  ${moment(latest)
                                          .tz("America/Phoenix")
                                          .format("MM/DD/YY")}`;
                                      }
                                      return (
                                        <Box key={subPhase.id}>
                                          <Box>
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                magin: 0,
                                                padding: 0,
                                              }}
                                            >
                                              {subPhase.isComplete ? (
                                                <CheckCircle color="success" />
                                              ) : (
                                                <Checkbox
                                                  disabled={subPhase.isComplete}
                                                  style={{
                                                    magin: 0,
                                                    padding: 0,
                                                  }}
                                                  checked={
                                                    // TODO: IF this subPhase is in the calendarEvent.subPhases, then true. Else false
                                                    scheduledSubPhases.some(
                                                      (scheduledSubPhase) =>
                                                        scheduledSubPhase.id ==
                                                        subPhase.id
                                                    )
                                                  }
                                                  onChange={(e) => {
                                                    // TODO: add to calendarEvent.subPhases
                                                    if (e.target.checked) {
                                                      setScheduledSubPhases([
                                                        ...scheduledSubPhases,
                                                        subPhase,
                                                      ]);
                                                    } else {
                                                      let scheduledSubPhasesCopy =
                                                        [...scheduledSubPhases];
                                                      let idxToRemove =
                                                        scheduledSubPhasesCopy.findIndex(
                                                          (scheduledSubPhase) =>
                                                            scheduledSubPhase.id ==
                                                            subPhase.id
                                                        );
                                                      scheduledSubPhasesCopy.splice(
                                                        idxToRemove,
                                                        1
                                                      );
                                                      setScheduledSubPhases(
                                                        scheduledSubPhasesCopy
                                                      );
                                                    }
                                                  }}
                                                />
                                              )}
                                              <DialogContentText>
                                                {subPhase.name} (last assigned -{" "}
                                                {lastScheduled})
                                              </DialogContentText>
                                            </div>
                                          </Box>
                                        </Box>
                                      );
                                    })}
                                </Box>
                              </Box>
                            ))}
                        </Box>

                        {dateError ? (
                          <Typography
                            sx={{ color: "#d32f2f", marginTop: "5px" }}
                          >
                            Please select a valid date and time
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Box>
                      {errorMessage ? (
                        <Typography sx={{ color: "#d32f2f", marginTop: "5px" }}>
                          {errorMessage}
                        </Typography>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </DialogContent>
                )}
              </>
            )}
            <DialogActions>
              <Button
                onClick={() => {
                  setDateError(false);

                  if (endDate < startDate) setDateError("Invalid time range");

                  handleSubmit(
                    dialogData.calendarEvent.id,
                    assignedTo,
                    scheduledSubPhases,
                    startDate,
                    endDate
                  );
                }}
              >
                Update
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
};

export default EditJobDialog;
