import {
  Box,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

const SplicePlate = (props) => {
  const { splicePlate } = props;
  if (!splicePlate) return null;

  return (
    <Box {...props}>
      <TableContainer component={Box}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Qty (pc)</TableCell>
              <TableCell align="right">Piece Length</TableCell>
              <TableCell align="right">Stretch Out</TableCell>
              <TableCell align="right">Hits</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {splicePlate.plates.map((plate, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {plate.quantity}
                </TableCell>
                <TableCell align="right">{plate.length}</TableCell>
                <TableCell align="right">{plate.stretchOut}</TableCell>
                <TableCell align="right">{plate.hits}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SplicePlate;
