import { useTheme } from "@emotion/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { toThreeDecimals } from "../../../../../components/NewDetail/calculations/utils";

// Longest to shortest
const sortPanelCutList = (panelCuts) => {
  panelCuts.sort((a, b) => {
    let aLength = parseFloat(a.feet) + parseFloat(a.inches) / 12;
    let bLength = parseFloat(b.feet) + parseFloat(b.inches) / 12;
    return bLength - aLength;
  });
};

const Panel = (props) => {
  const { panel, isEstimate } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (!panel) return null;

  let cutList = panel ? panel.cuts : [];
  sortPanelCutList(cutList);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          // justifyContent: isSmallScreen ? "flex-start" : "center",
          alignItems: "flex-start",
          color: "#ccc8c8", //off-white off white offwhite
        }}
      >
        <Box
          sx={{
            paddingRight: "16px",
            display: "flex",
            flexDirection: !isSmallScreen ? "column" : "row",
          }}
        >
          <Typography sx={{ marginRight: "8px", fontWeight: "bold" }}>
            SYSTEM:{" "}
          </Typography>
          <Typography>{panel.panelSystem}</Typography>
        </Box>
        <Box
          sx={{
            paddingRight: "16px",
            display: "flex",
            flexDirection: !isSmallScreen ? "column" : "row",
          }}
        >
          <Typography sx={{ marginRight: "8px", fontWeight: "bold" }}>
            PANEL:{" "}
          </Typography>
          <Typography>{panel.panelType}</Typography>
        </Box>
        <Box
          sx={{
            paddingRight: "16px",
            display: "flex",
            flexDirection: !isSmallScreen ? "column" : "row",
          }}
        >
          <Typography sx={{ marginRight: "8px", fontWeight: "bold" }}>
            PANEL WIDTH:{" "}
          </Typography>
          <Typography>{panel.panelWidth}</Typography>
        </Box>
        <Box
          sx={{
            paddingRight: "16px",
            display: "flex",
            flexDirection: !isSmallScreen ? "column" : "row",
          }}
        >
          <Typography sx={{ marginRight: "8px", fontWeight: "bold" }}>
            COVERAGE:{" "}
          </Typography>
          <Typography>
            {toThreeDecimals(
              (panel.calculations.linealFeetOfCoilNoWaste * panel.panelWidth) /
                12
            )}{" "}
            SF
          </Typography>
        </Box>
        <Box
          sx={{
            paddingRight: "16px",
            display: "flex",
            flexDirection: !isSmallScreen ? "column" : "row",
          }}
        >
          <Typography sx={{ marginRight: "8px", fontWeight: "bold" }}>
            MASTER COIL WIDTH:
          </Typography>
          <Typography>{panel.calculations.masterCoilSizeNeeded} in.</Typography>
        </Box>
      </Box>
      <TableContainer component={Box} sx={{ marginTop: "8px" }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Cut List</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Feet</TableCell>
              <TableCell align="right">Inches</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cutList.map((cut, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {cut.name}
                </TableCell>
                <TableCell align="right">{cut.quantity}</TableCell>
                <TableCell align="right">{cut.feet}</TableCell>
                <TableCell align="right">{cut.inches}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Panel;
