import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useContext, useEffect, useState } from "react";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import ImageCropper from "../../../../../../components/ImageCropper/ImageCropper";
import { AuthContext } from "../../../../../../context/AuthContext";

const AddNewTrimDialog = ({
  trimType,
  companyId,
  open,
  handleClose,
  handleSubmit,
}) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const [trim, setTrim] = useState({ name: "" });
  const [localImage, setLocalImage] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [imageBlob, setImageBlob] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBlobCropped, setImageBlobCropped] = useState(null);
  const [imageUrlCropped, setImageUrlCropped] = useState(null);

  const getPresignedUrl = async (presignedRequest) => {
    try {
      const response = await ezorder.post(
        `/files/presignedUrl`,
        presignedRequest,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("presigned response", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
      throw Error("Unable to load presigned URL for Trim image", {
        cause: error,
      });
    }
  };

  // uplaod image to S3 bucket
  const uploadImage = async (presignedResponse, file) => {
    console.log("file", file);
    console.log("presignedUrlData", presignedResponse);
    const { uploadUrl, key } = presignedResponse;
    const response = await fetch(uploadUrl, {
      method: "PUT",
      body: file,
      headers: {
        "Content-Type": file.type, // DO NOT PASS IN A BEARER TOKEN
      },
    });

    if (response.ok) {
      console.log("Upload success");
      return key;
    } else throw "Image Upload Failed";
  };
  const handleImage = async (imageBlob) => {
    const identifier = companyId;
    let presignedRequest = {
      actionType: "WRITE",
      identifier: identifier,
      fileName: imageBlob.name,
      contentType: imageBlob.type,
      resource: getResourceByTrimType(trimType),
    };
    let presignedResponse = await getPresignedUrl(presignedRequest);
    let s3Key = await uploadImage(presignedResponse, imageBlob);
    // setImageUrl(s3Key); // for display
    setImageUrl(null);
    setTrim((prev) => ({ ...prev, image: s3Key }));
  };

  const getResourceByTrimType = (trimType) => {
    const resource =
      trimType === "TrimAndFlashing"
        ? "trims"
        : trimType === "CopingCap"
        ? "coping-caps"
        : trimType === "CopingCapCleat"
        ? "cleats"
        : // : TRIM_TYPE === "SplicePlate"
        // ? ""
        trimType === "BenchWork"
        ? "benchPieces"
        : null;
    return resource;
  };
  const getEndpointByTrimType = (trimType) => {
    const endpoint =
      trimType === "TrimAndFlashing"
        ? "trims"
        : trimType === "CopingCap"
        ? "caps"
        : trimType === "CopingCapCleat"
        ? "cleats"
        : // : TRIM_TYPE === "SplicePlate"
        // ? ""
        trimType === "BenchWork"
        ? "benchPieces"
        : null;
    return endpoint;
  };
  const getKeyByTrimType = (trimType) => {
    const key =
      trimType === "TrimAndFlashing"
        ? "trim"
        : trimType === "CopingCap"
        ? "cap"
        : trimType === "CopingCapCleat"
        ? "cleat"
        : // : TRIM_TYPE === "SplicePlate"
        // ? ""
        trimType === "BenchWork"
        ? "benchPiece"
        : null;
    return key;
  };

  const onSaveTrim = async (trimType, trimObj) => {
    let resourceType = getEndpointByTrimType(trimType);
    let responseDataKey = getKeyByTrimType(trimType);

    try {
      const res = await ezorder.post(
        `/admin/products/${resourceType}`,
        { companyId, ...trimObj },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      handleSubmit(res.data[responseDataKey]);
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTrim({ name: "" });
    setLocalImage(null);
    setImageUrl(null);
    setImageBlob(null);
    setImageUrlCropped(null);
    setImageBlobCropped(null);
  }, [open]);

  return (
    <Dialog
      // disableEnforceFocus
      keepMounted
      scroll={"paper"}
      maxWidth="md"
      fullWidth={true}
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      <DialogTitle>Add New</DialogTitle>
      <DialogContent sx={{}}>
        <Box sx={{}}>
          <TextField
            sx={{ marginTop: "8px" }}
            label="Name"
            fullWidth
            value={trim.name}
            onChange={(e) => {
              setTrim((prev) => ({ ...prev, name: e.target.value }));
            }}
          />
          <Box
            sx={{
              display: " flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: " flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ marginTop: "12px" }}
                >
                  Image
                </Typography>

                <Box sx={{ maxWidth: "400px" }}>
                  {imageUrl ? (
                    <Box sx={{ width: "100%" }}>
                      <ImageCropper
                        image={imageUrl}
                        onSave={async (fileBlob, url) => {
                          console.log("CROPPED", fileBlob, url);
                          setImageUrl(null);
                          setImageBlob(null);

                          await handleImage(fileBlob);
                          setImageUrlCropped(url);
                          setImageBlobCropped(fileBlob);
                        }}
                        onClear={() => {
                          setImageUrl(null);
                          setImageBlob(null);
                          setImageUrlCropped(null);
                          setImageBlobCropped(null);
                        }}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <Box>
                        {imageUrlCropped && (
                          <Box style={{ position: "relative" }}>
                            <img
                              src={imageUrlCropped}
                              style={{
                                marginTop: 16,
                                // maxWidth: "100%",
                                // maxHeight: 200,
                                width: "100%",
                              }}
                            />
                            <Box
                              onClick={() => {
                                setImageUrlCropped(null);
                                setImageBlobCropped(null);
                              }}
                            >
                              <Close
                                style={{
                                  position: "absolute",
                                  top: 8,
                                  right: 8,
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                      </Box>
                      {imageBlob === null &&
                        imageUrl === null &&
                        imageBlobCropped === null &&
                        imageUrlCropped === null && (
                          <Box
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <label>
                              <input
                                style={{ display: "none" }}
                                type="file"
                                accept="image/*"
                                // capture="environment"
                                onChange={(event) => {
                                  console.log(event.target.files[0]);
                                  console.log(
                                    "Image",
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                  setImageBlob(event.target.files[0]);
                                  setImageUrl(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                  setImageBlobCropped(null);
                                  setImageUrlCropped(null);
                                }}
                              />
                              <Box
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  padding: 12,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  style={{
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderColor: "#d2d2d2",
                                    color: "#d2d2d2",
                                    width: 100,
                                    height: 100,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Upload sx={{ fontSize: 40 }} />
                                </Box>
                              </Box>
                            </label>
                            <label>
                              <input
                                style={{ display: "none" }}
                                type="file"
                                accept="image/*"
                                capture="environment"
                                onChange={(event) => {
                                  console.log(event.target.files[0]);
                                  console.log(
                                    "Image",
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                  setImageBlob(event.target.files[0]);
                                  setImageUrl(
                                    URL.createObjectURL(event.target.files[0])
                                  );
                                  setImageBlobCropped(null);
                                  setImageUrlCropped(null);
                                }}
                              />
                              <Box
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                  padding: 12,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  style={{
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    borderColor: "#d2d2d2",
                                    color: "#d2d2d2",
                                    width: 100,
                                    height: 100,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <CameraAlt sx={{ fontSize: 40 }} />
                                </Box>
                              </Box>
                            </label>
                          </Box>
                        )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            color="success"
            disabled={imageBlobCropped === null}
            onClick={() => {
              onSaveTrim(trimType, trim);
            }}
          >
            Submit
          </Button>
        </Box>
        <Button
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AddNewTrimDialog;
