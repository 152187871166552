import {
  Card,
  Typography,
  Box,
  Button,
  TextField,
  InputAdornment,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Close, Search } from "@mui/icons-material";

const SearchBar = (props) => {
  let { label, searchQuery, setSearchQuery } = props;
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        // paddingLeft: 16,
        // paddingRight: 16,
      }}
    >
      <TextField
        size={props.size ? props.size : "small"}
        style={{ flex: 1 }}
        color="primary"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" size="small">
              <Search size="small" />
            </InputAdornment>
          ),
        }}
        margin="normal"
        fullWidth
        id="search"
        label={label}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {/* <Close
        style={{ marginLeft: 8 }}
        onClick={() => {
          setSearchQuery("");
        }}
      /> */}
    </Box>
  );
};
export default SearchBar;
