import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import DebouncedSlider from "../../../components/DebouncedSlider";
import { useNavigate } from "react-router-dom";
import { Close } from "@mui/icons-material";

const JobProgressDialog = ({
  open,
  jobDetails,
  handleClose,
  handleSchedule,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      fullScreen={isSmallScreen}
    >
      {jobDetails && (
        <>
          <DialogTitle>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                {jobDetails &&
                  jobDetails.company &&
                  jobDetails.project &&
                  jobDetails.orderStatus && (
                    <>
                      {!jobDetails.orderStatus ||
                      !jobDetails.orderStatus.name ||
                      jobDetails.orderStatus.name == "SAVED" ||
                      jobDetails.orderStatus.name == "QUOTE_REQUESTED" ||
                      jobDetails.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED"
                        ? "Quote "
                        : "Order "}
                      #{jobDetails.id} - {jobDetails.company.name}
                      <DialogContentText sx={{ display: "block" }}>
                        {jobDetails.company.isVendorOrSupplier
                          ? "Vendor/Supplier Customer"
                          : "Project"}
                        : {jobDetails.project.name}
                      </DialogContentText>
                      {jobDetails.vendorProjectName && (
                        <DialogContentText sx={{ display: "block" }}>
                          Project: {jobDetails.vendorProjectName}
                        </DialogContentText>
                      )}
                      {jobDetails.subProject && (
                        <DialogContentText sx={{ display: "block" }}>
                          Sub-Project: {jobDetails.subProject}
                        </DialogContentText>
                      )}
                      <DialogContentText sx={{ display: "block" }}>
                        {jobDetails.poNumber
                          ? `PO#: ${jobDetails.poNumber}`
                          : ""}
                      </DialogContentText>
                    </>
                  )}
                <Button
                  sx={{ padding: 0, margin: 0 }}
                  onClick={() => {
                    navigate(`/orders/${jobDetails.id}`);
                  }}
                >
                  View Order
                </Button>
              </Box>
              <Box>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box>
              {jobDetails.phases &&
                jobDetails.phases.map((phase) => (
                  <Box>
                    <Box sx={{ padding: "12px" }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <DialogContentText sx={{ flex: 1, fontWeight: "bold" }}>
                          {phase.name}
                        </DialogContentText>
                      </Box>
                      <Box sx={{ paddingLeft: "16px" }}>
                        {phase.subPhases &&
                          phase.subPhases.map((subPhase) => {
                            let lastScheduled = "TBD";
                            if (
                              subPhase.calendarEvents &&
                              subPhase.calendarEvents.length > 0
                            ) {
                              lastScheduled = `${
                                subPhase.calendarEvents[0].assignedTo
                                  ? subPhase.calendarEvents[0].assignedTo
                                      .fullName
                                  : ""
                              } on  ${moment(
                                subPhase.calendarEvents[0].startDate
                              )
                                .tz("America/Phoenix")
                                .format("MM/DD/YY")}`;
                            }
                            return (
                              <Box>
                                <Box
                                  key={subPhase.id}
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <DialogContentText sx={{ flex: 1 }}>
                                    {subPhase.name} ({lastScheduled})
                                  </DialogContentText>
                                  <Box sx={{ paddingX: "16px" }}>
                                    <Typography
                                      sx={{
                                        fontWeight: "bold",
                                        color:
                                          subPhase.progress == 100
                                            ? "green"
                                            : "orange",
                                      }}
                                    >
                                      {subPhase.progress ?? 0}%
                                    </Typography>
                                    {/* <DebouncedSlider
                                      disabled={true}
                                      sx={{
                                        color:
                                          subPhase.progress == 100
                                            ? "green"
                                            : "orange",
                                        "& .MuiSlider": {
                                          color:
                                            subPhase.progress == 100
                                              ? "green"
                                              : "orange",
                                        },
                                        "& .MuiSlider-thumb": {
                                          color:
                                            subPhase.progress == 100
                                              ? "green"
                                              : "orange",
                                        },
                                      }}
                                      size="small"
                                      onChange={(e) => {
                                        if (
                                          subPhase.progress != e.target.value
                                        ) {
                                          // Only do api update if the value is different to prevent unnecessary updates
                                          // alert(e.target.value);
                                          // updateSubPhase(
                                          //   subPhase.id,
                                          //   e.target.value
                                          // );
                                        }
                                      }}
                                      value={subPhase.progress ?? 0}
                                      aria-label="Progress"
                                      getAriaValueText={(val) => {
                                        return `${val}%`;
                                      }}
                                      alueLabelDisplay="auto"
                                      step={25}
                                      marks={[
                                        {
                                          value: 0,
                                          label: "0%",
                                        },
                                        {
                                          value: 100,
                                          label: "100%",
                                        },
                                      ]}
                                      min={0}
                                      max={100}
                                    /> */}
                                  </Box>
                                </Box>
                              </Box>
                            );
                          })}
                      </Box>
                    </Box>
                    <Divider />
                  </Box>
                ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleSchedule(jobDetails.id);
              }}
            >
              Schedule Unfinished
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default JobProgressDialog;
