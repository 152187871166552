import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { Edit } from "@mui/icons-material";

const SubmitForQuoteDialog = ({ open, setOpen, order, onSubmit }) => {
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const [quoteApprovers, setQuoteApprovers] = useState([]);
  const [isEditingApprover, setIsEditingApprover] = useState(false);
  const [assignedApprover, setAssignedApprover] = useState(null);
  const getCompanyQuoteApproversByRequester = async () => {
    try {
      const res = await ezorder.get(`/users/${decoded.id}/estimate-approvers`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let approvers = res.data.quoteApprovers;
      setQuoteApprovers(approvers);

      let permissionNames = [];
      for (const permission of decoded.permissions) {
        permissionNames.push(permission.name);
      }

      // If the requester can approve quotes, auto-set as approver
      if (
        decoded.role == "OWNER" ||
        permissionNames.includes("Request Quote And Approve Own Quotes") ||
        permissionNames.includes("Request Quote And Approve All Quotes")
      ) {
        setAssignedApprover(
          approvers.find((approver) => (approver.id = decoded.id))
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getCompanyQuoteApproversByRequester();
  }, []);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogTitle>Select Quote Approver</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select who will receive notifications to review and approve the quote.
        </DialogContentText>
        {assignedApprover && !isEditingApprover ? (
          <Card
            style={{
              height: "100%",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ListItem>
                <ListItemText
                  primary={assignedApprover.fullName}
                  secondary={
                    <>
                      <Typography
                        style={{
                          fontSize: 12,
                        }}
                      >
                        Email: {assignedApprover.email}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Edit
                onClick={() => {
                  setIsEditingApprover(true);
                }}
              />
            </Box>
          </Card>
        ) : (
          <Box>
            <Card
              style={{
                height: "100%",
              }}
            >
              <div style={{}}>
                <div
                  style={{
                    display: "block",
                    maxHeight: 200,
                    maxWidth: "100%",
                    overflow: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {quoteApprovers.map((quoteApprover, index) => (
                    <ListItem
                      onClick={() => {
                        setAssignedApprover(quoteApprover);
                        setIsEditingApprover(false);
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          background: "#eeeeee",
                        },
                      }}
                    >
                      <ListItemText
                        primary={quoteApprover.fullName}
                        secondary={
                          <>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                            >
                              Email: {quoteApprover.email}
                            </Typography>
                            <Typography
                              style={{
                                fontSize: 12,
                              }}
                            >
                              Phone: {quoteApprover.phone}
                            </Typography>
                          </>
                        }
                      />
                    </ListItem>
                  ))}
                </div>
              </div>
            </Card>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!assignedApprover || isEditingApprover}
          variant="contained"
          color="primary"
          onClick={() => {
            // TODO:
            onSubmit(assignedApprover.id);
          }}
        >
          Submit Request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitForQuoteDialog;
