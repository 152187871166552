import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import DebouncedSlider from "../../../components/DebouncedSlider";
import { useNavigate, useParams } from "react-router-dom";
import { Add, Close, NotificationAdd } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import SendPickupAvailableNotificationDialog from "./SendPickupAvailableNotificationDialog";
import PrintableQRCode from "../../../components/QrCode/PrintableQRCode";

const OrderPickupHistory = () => {
  const { orderId } = useParams();
  const { ezorder, authToken, role } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);
  const [pickupItems, setPickupItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrderWithPickupHistory = async (orderId) => {
    try {
      setLoading(true);
      const res = await ezorder.get(`/shop/jobs/${orderId}/pickup-history`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrderDetails(res.data.order);
      setPickupItems(res.data.order.pickupHistory.items);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderWithPickupHistory(orderId);
    }
  }, [orderId]);

  return (
    <Box>
      {orderDetails && (
        <>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                {orderDetails &&
                  orderDetails.company &&
                  orderDetails.project &&
                  orderDetails.orderStatus && (
                    <>
                      {!orderDetails.orderStatus ||
                      !orderDetails.orderStatus.name ||
                      orderDetails.orderStatus.name == "SAVED" ||
                      orderDetails.orderStatus.name == "QUOTE_REQUESTED" ||
                      orderDetails.orderStatus.name ==
                        "ESTIMATE_APPROVAL_NEEDED"
                        ? "Quote "
                        : "Order "}
                      #{orderDetails.id} - {orderDetails.company.name}
                      <DialogContentText sx={{ display: "block" }}>
                        {orderDetails.company.isVendorOrSupplier
                          ? "Vendor/Supplier Customer"
                          : "Project"}
                        : {orderDetails.project.name}
                      </DialogContentText>
                      {orderDetails.vendorProjectName && (
                        <DialogContentText sx={{ display: "block" }}>
                          Project: {orderDetails.vendorProjectName}
                        </DialogContentText>
                      )}
                      {orderDetails.subProject && (
                        <DialogContentText sx={{ display: "block" }}>
                          Sub-Project: {orderDetails.subProject}
                        </DialogContentText>
                      )}
                      <DialogContentText sx={{ display: "block" }}>
                        {orderDetails.poNumber
                          ? `PO#: ${orderDetails.poNumber}`
                          : ""}
                      </DialogContentText>
                    </>
                  )}
                <Button
                  sx={{ padding: 0, margin: 0 }}
                  onClick={() => {
                    navigate(`/orders/${orderDetails.id}`);
                  }}
                >
                  View Order
                </Button>
              </Box>
              <Box>
                {orderDetails?.id && (
                  <PrintableQRCode
                    jobId={orderDetails.id}
                    customerName={
                      orderDetails.company ? orderDetails.company.name : ""
                    }
                    jobName={
                      orderDetails.project ? orderDetails.project.name : ""
                    }
                    displaySize={100}
                    url={`${window.location.host}/ready-for-pickup/${orderDetails.id}`}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {loading || !orderDetails ? (
            <Box
              sx={{
                width: "100%",
                minHeight: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size="3rem" />
            </Box>
          ) : (
            <Box sx={{ marginTop: "16px" }}>
              {/* Pickups */}
              <Box>
                <Typography variant="h6" sx={{ marginTop: "16px" }}>
                  Pickups & Deliveries
                </Typography>
                <TableContainer component={Box} sx={{ margin: 0, padding: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">ID</TableCell>
                        <TableCell align="left">Created</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Signed On</TableCell>
                        <TableCell align="left">Status</TableCell>
                        {/* <TableCell align="left" sx={{ width: "120px" }}>
                        Receive
                      </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails.pickupHistory &&
                        orderDetails.pickupHistory.pickups &&
                        orderDetails.pickupHistory.pickups.map(
                          (pickup, index) => {
                            return (
                              <>
                                <TableRow
                                  key={pickup.id}
                                  onClick={() => {
                                    navigate(
                                      `/ready-for-pickup/${orderId}/pickups/${pickup.id}`
                                    );
                                  }}
                                >
                                  <TableCell align="left">
                                    {pickup.id}
                                  </TableCell>
                                  <TableCell align="left">
                                    {moment(pickup.createdAt)
                                      .tz("America/Phoenix")
                                      .format("MM/DD/YYYY")}
                                  </TableCell>
                                  <TableCell align="left">
                                    {pickup.pickupType}
                                  </TableCell>
                                  <TableCell align="left">
                                    {pickup.signedOn
                                      ? moment(pickup.signedOn)
                                          .tz("America/Phoenix")
                                          .format("MM/DD/YYYY")
                                      : ""}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {pickup.pickupStatus}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell align="left">
                                <TextField
                                  label={item.uom}
                                  value={item.quantity - item.quantityReceived}
                                />
                              </TableCell> */}
                                  <Divider />
                                </TableRow>
                              </>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Typography variant="h6" sx={{ marginTop: "16px" }}>
                Items
              </Typography>
              <TableContainer component={Box} sx={{ margin: 0, padding: 0 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" sx={{ width: "70px" }}>
                        Type
                      </TableCell>
                      <TableCell align="left">Name</TableCell>
                      {!isSmallScreen && (
                        <TableCell align="left" sx={{ width: "120px" }}>
                          Received
                        </TableCell>
                      )}
                      <TableCell align="left" sx={{ width: "70px" }}>
                        Available
                      </TableCell>
                      {/* <TableCell align="left" sx={{ width: "120px" }}>
                        Receive
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetails.pickupHistory &&
                      orderDetails.pickupHistory.items &&
                      orderDetails.pickupHistory.items.map((item, index) => {
                        return (
                          <>
                            <TableRow key={item.id}>
                              {/* <TableCell align="left">
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {item.itemName}
                                </Typography>
                                <Typography variant="caption">
                                  {item.objectType}
                                </Typography>
                              </TableCell> */}
                              <TableCell align="left">
                                {item.objectType}
                              </TableCell>
                              <TableCell align="left">
                                {item.itemName}
                              </TableCell>
                              {!isSmallScreen && (
                                <TableCell align="left">
                                  <Typography>
                                    {item.quantityReceived} / {item.quantity}{" "}
                                    {item.uom}
                                  </Typography>
                                </TableCell>
                              )}

                              <TableCell align="left">
                                {item.quantity - item.quantityReceived}{" "}
                                {item.uom}
                              </TableCell>
                              {/* <TableCell align="left">
                                <TextField
                                  label={item.uom}
                                  value={item.quantity - item.quantityReceived}
                                />
                              </TableCell> */}
                              <Divider />
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </>
      )}
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "50px",
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        {role == "EZORDER_ADMIN" && (
          <Fab
            variant="extended"
            color="success"
            onClick={() => {
              setOpenNotificationModal(true);
            }}
          >
            Send Availability Notification
          </Fab>
        )}

        <Fab
          variant="extended"
          color="success"
          onClick={() => {
            navigate(`/ready-for-pickup/${orderId}/new-pickup`);
          }}
        >
          + Pickup/Delivery
        </Fab>
      </div>
      {role == "EZORDER_ADMIN" && orderDetails && (
        <SendPickupAvailableNotificationDialog
          orderId={orderDetails.id}
          open={openNotificationModal}
          handleClose={() => {
            setOpenNotificationModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default OrderPickupHistory;
