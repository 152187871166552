import { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  AttachMoney,
  Business,
  Calculate,
  CalendarMonth,
  Construction,
  Logout,
  PeopleOutline,
  RequestQuote,
  ShoppingCartCheckout,
} from "@mui/icons-material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Outlet, useLocation } from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import ErrorBoundary from "../error/ErrorBoundary";
import { useTheme } from "@emotion/react";
import SnackAlert from "../../components/SnackAlert";
import { SnackAlertContext } from "../../context/SnackAlertContext";
import {
  AdminQuoteAndOrderCountContext,
  AdminQuoteAndOrderCountProvider,
} from "../../context/AdminQuoteAndOrderCountContext";
import { TrimIcon } from "../../components/CustomerComponents/TrimIcon";
import Inventory2 from "@mui/icons-material/Inventory2";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function DashboardContent() {
  const location = useLocation();
  const theme = useTheme();
  const { signOut } = useContext(AuthContext);
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const {
    openSnackAlert,
    setOpenSnackAlert,
    snackAlertSeverity,
    snackAlertTitle,
    snackAlertMessage,
  } = useContext(SnackAlertContext);

  const { quoteCount } = useContext(AdminQuoteAndOrderCountContext);

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="absolute"
        open={open}
        sx={{
          background: theme.palette.appBar
            ? theme.palette.appBar.background
            : "inherit",
          color: theme.palette.appBar
            ? theme.palette.appBar.contrastText
            : "inherit",
        }}
      >
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            // color="inherit"
            noWrap
            sx={{
              flexGrow: 1,
            }}
          >
            Industry Metal Co Admin
          </Typography>
          {/* <IconButton color="inherit">
              <Badge badgeContent={400} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          overflow: "hidden", // Hide Scrollbars
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
            background: theme.palette.appBar
              ? theme.palette.appBar.background
              : "inherit",
            color: theme.palette.appBar
              ? theme.palette.appBar.contrastText
              : "inherit",
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon
              sx={{
                color: "#fff",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
            />
          </IconButton>
        </Toolbar>
        <Divider />
        <Box
          sx={{
            overflowY: "hidden", // Hide Scrollbars
          }}
        >
          <Box
            sx={{
              height: "84vh",
              overflowY: "scroll",
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <List component="nav" dense={true}>
              {open && (
                <ListSubheader component="div" id="nested-list-subheader">
                  Quotes & Orders
                </ListSubheader>
              )}

              <ListItemButton
                sx={{
                  color: location.pathname.includes("/quotes")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`quotes`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/quotes")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <Badge
                    color="primary"
                    badgeContent={quoteCount}
                    max={25}
                    // sx={{ width: "100%" }}
                  >
                    <RequestQuote />
                  </Badge>
                </ListItemIcon>

                <ListItemText primary={<Typography>Quotes</Typography>} />
              </ListItemButton>
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/jobs")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`jobs`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/jobs")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <Construction />
                </ListItemIcon>
                <ListItemText primary={<Typography>Jobs</Typography>} />
              </ListItemButton>
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/shop-schedule")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`shop-schedule`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/shop-schedule")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <CalendarMonth />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Shop Schedule</Typography>}
                />
              </ListItemButton>
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/ready-for-pickup")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`ready-for-pickup`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/ready-for-pickup")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <Inventory2 />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Ready For Pickup</Typography>}
                />
              </ListItemButton>
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/sales")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`sales`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/sales")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <AttachMoney />
                </ListItemIcon>
                <ListItemText primary={<Typography>Sales</Typography>} />
              </ListItemButton>

              <Divider
                sx={
                  {
                    // my: 1
                  }
                }
              />
              {open && (
                <ListSubheader component="div" id="nested-list-subheader">
                  Admin
                </ListSubheader>
              )}

              <ListItemButton
                sx={{
                  color: location.pathname.includes("/customers")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`customers`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/customers")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <Business />
                </ListItemIcon>
                <ListItemText primary={<Typography>Customers</Typography>} />
              </ListItemButton>

              <ListItemButton
                sx={{
                  color: location.pathname.includes("/purchase-orders")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`purchase-orders`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/purchase-orders")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <ShoppingCartCheckout />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Purchase Orders</Typography>}
                />
              </ListItemButton>
            </List>
            <List component="nav" dense={true}>
              <Divider
                sx={
                  {
                    // my: 1
                  }
                }
              />
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/sf-pricing")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate("/sf-pricing");
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/sf-pricing")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <Calculate color="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Square Foot Pricing</Typography>}
                />
              </ListItemButton>
              {/* <Divider
          sx={
            {
              // my: 1
            }
          }
        /> */}
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/master-trim-catalog")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`/master-trim-catalog`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/master-trim-catalog")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <TrimIcon color="inherit" />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Master Trim Catalog</Typography>}
                />
              </ListItemButton>
              {/* <Divider
            sx={
              {
                // my: 1
              }
            }
          /> */}
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/panel-catalog")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`panel-catalog`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/panel-catalog")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Panel Catalog</Typography>}
                />
              </ListItemButton>
              {/* <Divider
            sx={
              {
                // my: 1
              }
            }
          /> */}
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/color-catalog")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`color-catalog`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/color-catalog")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Color Catalog</Typography>}
                />
              </ListItemButton>
              {/* <Divider
            sx={
              {
                // my: 1
              }
            }
          /> */}
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/settings")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`settings`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/settings")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography>Order Settings</Typography>}
                />
              </ListItemButton>
              {/* <Divider
            sx={
              {
                // my: 1
              }
            }
          /> */}
            </List>
            <List component="nav" dense={true}>
              <Divider
                sx={
                  {
                    // my: 1
                  }
                }
              />
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/employees")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`employees`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/employees")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <PeopleOutline />
                </ListItemIcon>
                <ListItemText primary={<Typography>Employees</Typography>} />
              </ListItemButton>
              {/* <Divider
            sx={
              {
                // my: 1
              }
            }
          /> */}
              <ListItemButton
                sx={{
                  color: location.pathname.includes("/time-cards")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
                onClick={() => {
                  navigate(`time-cards`);
                }}
              >
                <ListItemIcon
                  sx={{
                    color: location.pathname.includes("/time-cards")
                      ? theme.palette.primary.main
                      : "inherit",
                  }}
                >
                  <AccessTimeIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography>Time Cards</Typography>} />
              </ListItemButton>
              {/* <Divider
            sx={
              {
                // my: 1
              }
            }
          /> */}
            </List>
            <List dense={true}>
              <Divider
                sx={
                  {
                    // my: 1
                  }
                }
              />
              <ListItemButton
                onClick={() => {
                  signOut();
                  navigate(`/`);
                }}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                <ListItemText primary={<Typography>Logout</Typography>} />
              </ListItemButton>
            </List>
            <Divider
              sx={
                {
                  // my: 1
                }
              }
            />
            {open && (
              <ListSubheader component="div" id="nested-list-subheader">
                Deprecated
              </ListSubheader>
            )}

            <ListItemButton
              sx={{
                color: location.pathname.includes("/orders")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
              onClick={() => {
                navigate(`orders`);
              }}
            >
              <ListItemIcon
                sx={{
                  color: location.pathname.includes("/orders")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              >
                <MonetizationOnIcon />
              </ListItemIcon>
              <ListItemText primary={<Typography>Orders</Typography>} />
            </ListItemButton>
            <ListItemButton
              sx={{
                color: location.pathname.includes("/shop-view")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
              onClick={() => {
                navigate(`shop-view`);
              }}
            >
              <ListItemIcon
                sx={{
                  color: location.pathname.includes("/shop-view")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              >
                <Construction />
              </ListItemIcon>
              <ListItemText primary={<Typography>Shop View</Typography>} />
            </ListItemButton>
            <ListItemButton
              sx={{
                color: location.pathname.includes("/fs-optimizer")
                  ? theme.palette.primary.main
                  : "inherit",
              }}
              onClick={() => {
                navigate(`/fs-optimizer`);
              }}
            >
              <ListItemIcon
                sx={{
                  color: location.pathname.includes("/fs-optimizer")
                    ? theme.palette.primary.main
                    : "inherit",
                }}
              >
                <TrimIcon color="inherit" />
              </ListItemIcon>
              <ListItemText primary={<Typography>FS Optimizer</Typography>} />
            </ListItemButton>
          </Box>
        </Box>
      </Drawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4, paddingBottom: "80px" }}>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </Container>
      </Box>

      <SnackAlert
        open={openSnackAlert}
        setOpen={setOpenSnackAlert}
        severity={snackAlertSeverity}
        title={snackAlertTitle}
        message={snackAlertMessage}
      />
    </Box>
  );
}

export default function Dashboard() {
  return (
    <AdminQuoteAndOrderCountProvider>
      <DashboardContent />
    </AdminQuoteAndOrderCountProvider>
  );
}
