import React, { useState, createContext, useEffect, useContext } from "react";
import useQuotesAdmin from "../hooks/useQuotesAdmin";

export const AdminQuoteAndOrderCountContext = createContext();

export const AdminQuoteAndOrderCountProvider = (props) => {
  const getAuthenticationFromLocalStorage = () => {
    // check if auth exists in state
    // if not, check local storage for jwt and decode
    // decode
    // return
    try {
      const token = localStorage.getItem("CSMAZ_APP_TOKEN");
      return token;
    } catch (error) {
      console.log("No token in localstorage");
    }
  };

  let [
    searchQueryQuotes,
    setSearchQueryQuotes,
    quotes,
    onRefreshQuotes,
    refreshingQuotes,
    onLoadMoreQuotes,
    loadingMoreQuotes,
    totalQuotes,
    hasMoreQuotes,
  ] = useQuotesAdmin(getAuthenticationFromLocalStorage(), 10, null);

  return (
    <AdminQuoteAndOrderCountContext.Provider
      value={{
        quoteCount: totalQuotes,
        refreshQuoteCount: onRefreshQuotes,
      }}
    >
      {props.children}
    </AdminQuoteAndOrderCountContext.Provider>
  );
};
