import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  TextField,
  Dialog,
  useMediaQuery,
  DialogContent,
  DialogTitle,
  ListItemText,
  ListItem,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../context/AuthContext";
import useCustomerProjectsByAdmin from "../../../../../hooks/useCustomerProjectsByAdmin";
import SearchBar from "../../../../../components/SearchBar";
import InfiniteScroller from "../../../../../components/InfiniteScroller/InfiniteScroller";
import ListFooter from "../../../../../components/InfiniteScroller/ListFooter";

const SelectProjectDialog = ({
  selectedCompany,
  open,
  handleClose,
  onSelect,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const { authToken, ezorder } = useContext(AuthContext);

  const [
    project_searchQuery,
    project_setSearchQuery,
    project_results,
    project_onRefresh,
    project_refreshing,
    project_onLoadMore,
    project_loadingMore,
    project_total,
    project_hasMore,
  ] = useCustomerProjectsByAdmin(authToken, 20, selectedCompany?.id);
  const [projectName, setProjectName] = useState("");
  const onClose = () => {
    setProjectName("");
    handleClose();
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitProject = async ({ name }) => {
    try {
      if (!name) return;
      setIsSubmitting(true);
      const response = await ezorder.post(
        `/admin/companies/${selectedCompany.id}/projects`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      onSelect(response.data.project);
      onClose();
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    project_onRefresh();
  }, [selectedCompany]);

  return (
    <Dialog
      scroll={"paper"}
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Select Project</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {selectedCompany && (
            <Box sx={{ width: "100%" }}>
              <DialogContentText>
                Create an Order For A New Project
              </DialogContentText>
              <Box sx={{ display: "flex" }}>
                <TextField
                  sx={{ flex: 1, marginTop: "8px" }}
                  size="small"
                  label="New Project"
                  value={projectName}
                  onChange={(e) => {
                    setProjectName(e.target.value);
                  }}
                />
                <Button
                  size="small"
                  sx={{ marginLeft: "16px" }}
                  variant="contained"
                  onClick={() =>
                    handleSubmitProject({
                      name: projectName,
                    })
                  }
                  disabled={isSubmitting}
                >
                  <Add /> New Project
                </Button>
              </Box>
            </Box>
          )}
          {selectedCompany && (
            <Box>
              <DialogContentText>
                Or Select Project from {selectedCompany.name}
              </DialogContentText>
              <Box>
                <SearchBar
                  label="Search Projects"
                  searchQuery={project_searchQuery}
                  setSearchQuery={project_setSearchQuery}
                />
                <InfiniteScroller
                  items={project_results}
                  renderItem={(item, index) => {
                    return (
                      <ListItem
                        onClick={() => {
                          console.log("SELECT ITEM", item);
                          onSelect(item);
                          onClose();
                        }}
                        key={index}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            background: "#eeeeee",
                          },
                        }}
                      >
                        <ListItemText primary={item.name} />
                      </ListItem>
                    );
                  }}
                  onRefresh={project_onRefresh}
                  onEndReached={project_onLoadMore}
                  loading={project_refreshing || project_loadingMore}
                  ListFooterComponent={() => {
                    return (
                      <ListFooter
                        hasMore={project_hasMore}
                        onClick={project_onLoadMore}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectProjectDialog;
