"use client";
import { Close, FileOpen } from "@mui/icons-material";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import compressImage from "../../service/compressFileService";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../context/AuthContext";

// interface Props {
//   file: any;
//   fileType: any;
//   fileExtension: any;
//   fileCustomName: any;
//   localFileUrl: any;
//   onDelete?: Function | undefined;
//   uploading?: boolean;
//   uploadProgress?: number;
// }
const FileAttachment = ({
  s3Key,
  identifier,
  file,
  localFileUrl,
  onDelete,
  onUploaded,
}) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const [uploading, setUploading] = useState(true);
  const [uploadProgress, setUploadProgress] = useState(0);

  const createWritePresignedUrl = async (
    actionType, // READ || WRITE
    identifier,
    fileName, // NULL (if uploading and the user is admin/shop) || COMPANY ID OF OWNER
    contentType,
    resource
  ) => {
    // If no company ID is passed in, set as "GUEST"
    if (!identifier) {
      identifier = "GUEST";
    }
    // const identifier = orderDetails.company && orderDetails.company.id
    //   ? orderDetails.company.id
    //   : "GUEST";

    const response = await ezorder.post(
      "/files/presignedUrl",
      { identifier, actionType, fileName, contentType, resource },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  };

  // Returns S3 Key generated during presigning
  const uploadFile = async (identifier, file) => {
    try {
      setUploading(true);
      const presignedUrlResponse = await createWritePresignedUrl(
        "WRITE", // Action type
        identifier, // Company ID
        file.name, // this name doesn't matter
        file.type, // contentType
        "progress" // resource type progress (s3 folder)
      );
      const presignedUrl = presignedUrlResponse.uploadUrl;
      const s3Key = presignedUrlResponse.key;

      // If file type is image, then we compress
      const compressedFile = file.type.startsWith("image/")
        ? await compressImage(file, file.type)
        : file;

      const response = await ezorder.put(presignedUrl, compressedFile, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: function ({
          loaded,
          total,
          progress,
          bytes,
          estimated,
          rate,
          upload = true,
        }) {
          // Do whatever you want with the Axios progress event
          var percentCompleted = Math.round((loaded * 100) / total);
          console.log("onUploadProgress", percentCompleted);
          setUploadProgress(percentCompleted);
        },
      });

      // TODO If you get a weird error or non-error, AMAZON might be returning XML which fetch doesn't support...
      // SO IT WILL NOT THROW AN ERROR EVEN WITH STATUS 400
      // console.log("fetchResult " + (await fetchResult.text()).toString());
      // console.log("fetchResult " + fetchResult.toString());
      console.log("S3 Key of Uploaded Signature", s3Key);
      //   return s3Key;
      onUploaded(s3Key);
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    if (!s3Key && identifier && file) {
      uploadFile(identifier, file);
    }
  }, [s3Key && identifier, file]);

  if (file.name.endsWith("mp3")) {
    return (
      <Card
        sx={{
          height: "100px",
          margin: "4px",
          minWidth: "300px",
          width: "300px",
          position: "relative",
          cursor: "pointer",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            flex: 1,
            padding: "8px",
            display: "flex",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              maxWidth: "120px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
          >
            {file.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "16px",
            paddingRight: "16px",
            paddingBottom: "8px",
            width: "100%",
            position: "relative",
          }}
        >
          <audio src={localFileUrl} controls />
        </Box>

        <IconButton
          onClick={() => {
            if (onDelete) onDelete();
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Close />
        </IconButton>
        {uploading && (
          <IconButton
            onClick={() => {
              // onDelete();
            }}
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <CircularProgress
              color="primary"
              //   variant="determinate"
              size="1.5rem"
              //   value={uploadProgress}
            />
          </IconButton>
        )}
      </Card>
    );
  } else {
    return (
      <Card
        sx={{
          height: "100px",
          margin: "4px",
          minWidth: "270px",
          width: "270px",
          position: "relative",
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {file.name.toLowerCase().endsWith("apng") ||
        file.name.toLowerCase().endsWith("avif") ||
        file.name.toLowerCase().endsWith("gif") ||
        file.name.toLowerCase().endsWith("jpg") ||
        file.name.toLowerCase().endsWith("jpeg") ||
        file.name.toLowerCase().endsWith("jfif") ||
        file.name.toLowerCase().endsWith("pjpg") ||
        file.name.toLowerCase().endsWith("pjp") ||
        file.name.toLowerCase().endsWith("png") ||
        file.name.toLowerCase().endsWith("svg") ||
        file.name.toLowerCase().endsWith("webp") ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
              height: "100px",
              position: "relative",
            }}
          >
            {/* <Image
              src={localFileUrl}
              alt={fileCustomName}
              fill
              style={{
                objectFit: "contain",
              }}
            /> */}
            <img
              src={localFileUrl}
              style={{
                minHeight: "100px",
                maxHeight: "100px",
                minWidth: "100px",
                maxWidth: "100px",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100px",
              height: "100px",
              position: "relative",
            }}
          >
            <FileOpen sx={{ fontSize: "80px", marign: 0, padding: 0 }} />
          </Box>
        )}

        <Box
          sx={{
            flex: 1,
            padding: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              maxWidth: "120px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}
          >
            {file.name}
          </Typography>
        </Box>

        <IconButton
          onClick={() => {
            if (onDelete) onDelete();
          }}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Close />
        </IconButton>
        {uploading && (
          <IconButton
            onClick={() => {
              // cancel
            }}
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <CircularProgress
              color="primary"
              //   variant="determinate"
              size="1.5rem"
              //   value={uploadProgress}
            />
          </IconButton>
        )}
      </Card>
    );
  }
};

export default FileAttachment;
