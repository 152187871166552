import { useTheme } from "@emotion/react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

const FlatSheet = (props) => {
  const { flatSheet } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (!flatSheet) return null;

  return (
    <TableContainer component={Box} sx={{ marginTop: "8px" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Width(in.) </TableCell>
            <TableCell align="right"> Qty </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell component="th" scope="row">
              {flatSheet.width}
            </TableCell>
            <TableCell align="right"> {flatSheet.quantity} </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlatSheet;
