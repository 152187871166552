import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Badge from "@mui/material/Badge";
import { TrimIcon } from "../TrimIcon";
import {
  Box,
  Card,
  createTheme,
  ThemeProvider,
  Typography,
  Button,
  Stepper,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { HeadingContext } from "../../../context/HeadingContext";
import { useNavigate } from "react-router-dom";
import MenuDrawer from "./MenuDrawer";
import ErrorBoundary from "../../../pages/error/ErrorBoundary";
import { NotificationContext } from "../../../context/NotificationContext";
import {
  FormatQuote,
  RequestQuote,
  ShoppingCart,
  Work,
} from "@mui/icons-material";
import { useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import { useState } from "react";
import { useTheme } from "@emotion/react";

const BottomNav = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { decoded } = useContext(AuthContext);
  const { unreadCount } = useContext(NotificationContext);
  const [value, setValue] = useState("home");
  const { headingText, captionText } = useContext(HeadingContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {/* <ThemeProvider theme={mdTheme}> */}
      <MenuDrawer open={drawerOpen} toggleDrawer={handleDrawerToggle} />
      <Box>
        <AppBar
          component="nav"
          position="fixed"
          elevation={0}
          sx={{
            background: theme.palette.appBar
              ? theme.palette.appBar.background
              : "inherit",
            color: theme.palette.appBar
              ? theme.palette.appBar.contrastText
              : "inherit",
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              sx={{ position: "fixed", color: "white" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <img
                  src="/industry-metal-co-square-logo-light-outlinedicon.png"
                  height={65}
                  onClick={() => {
                    if (decoded.isVendorOrSupplier) {
                      navigate("/customers");
                    } else {
                      navigate("/orders");
                    }
                  }}
                />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Box component="main">
          <Toolbar />

          <Box
            sx={{
              overflow: "auto",
              height: "100vh",
              flexGrow: 1,
              marginTop: "1rem",
              paddingTop: "1rem",
            }}
          >
            <Box sx={{ padding: "8px" }}>
              {/* <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                  MUI
                </Link>
                <Link
                  underline="hover"
                  color="inherit"
                  href="/material-ui/getting-started/installation/"
                >
                  Core
                </Link>
                <Typography color="text.primary">Breadcrumbs</Typography>
              </Breadcrumbs> */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {headingText && (
                  <Typography variant="h3">{headingText}</Typography>
                )}
                {captionText && (
                  <Typography variant="subtitle2">{captionText}</Typography>
                )}
              </Box>
            </Box>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
            <Toolbar />
          </Box>
        </Box>
        {/* <ThemeProvider theme={navTheme}> */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            background: theme.palette.appBar
              ? theme.palette.appBar.background
              : "inherit",
          }}
        >
          <BottomNavigation
            value={value}
            onChange={handleChange}
            sx={{
              background: theme.palette.appBar
                ? theme.palette.appBar.background
                : "#141414",
            }}
          >
            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              color="secondary"
              label="Quotes"
              value="quotes"
              icon={<RequestQuote />}
              onClick={() => navigate("/quotes")}
            />
            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              color="secondary"
              label="Orders"
              value="orders"
              icon={<ShoppingCart />}
              // icon={<HomeOutlinedIcon />}
              onClick={() => navigate("/orders")}
            />
            {decoded.isVendorOrSupplier ? (
              <BottomNavigationAction
                sx={{
                  minWidth: "52px",
                  color: theme.palette.appBar
                    ? theme.palette.appBar.contrastText
                    : "inherit",
                }}
                color="secondary"
                label="Customers"
                value="customers"
                icon={<Work />}
                onClick={() => navigate("/customers")}
              />
            ) : (
              <BottomNavigationAction
                sx={{
                  minWidth: "52px",
                  color: theme.palette.appBar
                    ? theme.palette.appBar.contrastText
                    : "inherit",
                }}
                color="secondary"
                label="Projects"
                value="projects"
                icon={<Work />}
                onClick={() => navigate("/projects")}
              />
            )}
            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              label="Catalog"
              value="catalog"
              icon={<TrimIcon color="inherit" />}
              onClick={() => navigate("/catalog")}
            />
            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              width={50}
              label="Menu"
              value="menu"
              icon={<MenuOutlinedIcon />}
              onClick={handleDrawerToggle}
            />
          </BottomNavigation>
        </Box>
        {/* </ThemeProvider> */}
      </Box>
      {/* </ThemeProvider> */}
    </>
  );
};
export default BottomNav;
