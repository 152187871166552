import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";

const useSchedule = () => {
  const { ezorder, authToken } = useContext(AuthContext);

  const scheduleEvent = async (
    jobId,
    assignedTo,
    subPhases,
    startDate,
    endDate
  ) => {
    const response = await ezorder.post(
      `/shop/schedule`,
      { jobId, assignedTo, subPhases, startDate, endDate },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response;
  };

  const updateEvent = async (
    eventId,
    assignedTo,
    subPhases,
    startDate,
    endDate
  ) => {
    const response = await ezorder.put(
      `/shop/schedule/events/${eventId}`,
      { assignedTo, subPhases, startDate, endDate },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response;
  };

  const deleteEvent = async (eventId) => {
    const response = await ezorder.delete(`/shop/schedule/events/${eventId}`, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response;
  };

  return {
    scheduleEvent,
    updateEvent,
    deleteEvent,
  };
};
export default useSchedule;
