import React, { useState, createContext, useEffect } from "react";

export const SnackAlertContext = createContext();

export const SnackAlertProvider = (props) => {
  const [openSnackAlert, setOpenSnackAlert] = React.useState(false);
  const [snackAlertSeverity, setSnackAlertSeverity] = React.useState("info");
  const [snackAlertMessage, setSnackAlertMessage] = useState("");
  const [snackAlertTitle, setSnackAlertTitle] = useState("");

  const openSnackMessage = (severity = "info", title = "", message = "") => {
    setSnackAlertTitle(title);
    setSnackAlertMessage(message);
    setSnackAlertSeverity(severity);
    setOpenSnackAlert(true);
  };

  return (
    <SnackAlertContext.Provider
      value={{
        openSnackMessage,
        setOpenSnackAlert,
        openSnackAlert,
        snackAlertSeverity,
        snackAlertMessage,
        snackAlertTitle,
        setSnackAlertTitle,
      }}
    >
      {props.children}
    </SnackAlertContext.Provider>
  );
};
