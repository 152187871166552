import { Add } from "@mui/icons-material";
import { Fab, useMediaQuery, useTheme } from "@mui/material";
import React from "react";

const ScheduleButton = ({ onAdding }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleCreateJob = async () => {
    try {
      onAdding();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Fab
      sx={{
        position: "fixed",
        bottom: isSmallScreen ? "75px" : "15px",
        right: isSmallScreen ? "16px" : "32px",
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          background: theme.palette.primary.dark,
        },
      }}
      variant="extended"
      size="medium"
      color="success"
      onClick={handleCreateJob}
    >
      <Add color="inherit" sx={{ mr: isSmallScreen ? 0 : 1 }} />
      {`${!isSmallScreen ? "Schedule Job" : ""}`}
    </Fab>
  );
};

export default ScheduleButton;
