import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Slider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import FlatSheet from "./FlatSheet";
import Coil from "./Coil";
import Panel from "./Panel";
import TrimAndFlashing from "./TrimAndFlashing";
import BenchWork from "./BenchWork";
import CopingCap from "./CopingCap";
import Cleat from "./Cleat";
import SplicePlate from "./SplicePlate";
import DebouncedSlider from "../../../../../components/DebouncedSlider";
import { Close } from "@mui/icons-material";
import Accessory from "./Accessory";

const OrderItemDialogButton = ({
  productMap,
  productType,
  phase,
  updateSubPhase,
  ...props
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = useState(false);
  return (
    <>
      <Box
        onClick={() => {
          setOpen(true);
        }}
      >
        {props.children}
      </Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="md"
        fullWidth={true}
        fullScreen={isSmallScreen}
      >
        <DialogTitle sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ flex: 1 }}>{productType} Details</Box>
          <Box>
            <IconButton
              onClick={() => {
                setOpen(false);
              }}
              sx={{}}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider sx={{ marginY: "10px" }} />
        </DialogTitle>
        <DialogContent>
          {productType == "FlatSheet" && productMap.get("FlatSheet") && (
            <FlatSheet flatSheet={productMap.get("FlatSheet")} />
          )}

          {productType == "Coil" && productMap.get("Coil") && (
            <Coil coil={productMap.get("Coil")} />
          )}

          {productType == "Panel" && productMap.get("Panel") && (
            <Panel panel={productMap.get("Panel")} />
          )}
          {productType == "TrimAndFlashing" &&
            productMap.get("TrimAndFlashing") && (
              <TrimAndFlashing trim={productMap.get("TrimAndFlashing")} />
            )}
          {productType == "BenchWork" && productMap.get("BenchWork") && (
            <BenchWork benchWork={productMap.get("BenchWork")} />
          )}
          {productType == "CopingCap" && productMap.get("CopingCap") && (
            <CopingCap copingCap={productMap.get("CopingCap")} />
          )}
          {productType == "CopingCapCleat" &&
            productMap.get("CopingCapCleat") && (
              <Cleat cleat={productMap.get("CopingCapCleat")} />
            )}
          {productType == "SplicePlate" && productMap.get("SplicePlate") && (
            <SplicePlate splicePlate={productMap.get("SplicePlate")} />
          )}
          {productType == "Accessory" && productMap.get("Accessory") && (
            <Accessory accessory={productMap.get("Accessory")} />
          )}
        </DialogContent>
        <DialogActions>
          <Divider />
          <Box sx={{ flex: 1, width: "100%", padding: "16px" }}>
            <Box sx={{ width: "100%" }}>
              <Typography variant="h6">Progress</Typography>
              {phase.subPhases &&
                phase.subPhases.map((subPhase) => {
                  return (
                    <Box>
                      <Box
                        key={subPhase.id}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <DialogContentText
                          sx={{ marginRight: "16px", flex: 1 }}
                        >
                          {subPhase.name}
                        </DialogContentText>
                        <Box
                          sx={{
                            paddingX: "16px",
                            width: isSmallScreen ? "150px" : "200px",
                          }}
                        >
                          <DebouncedSlider
                            disabled={subPhase.progress == 100}
                            sx={{
                              color:
                                subPhase.progress == 100 ? "green" : "orange",
                              "& .MuiSlider": {
                                color:
                                  subPhase.progress == 100 ? "green" : "orange",
                              },
                              "& .MuiSlider-thumb": {
                                color:
                                  subPhase.progress == 100 ? "green" : "orange",
                              },
                            }}
                            size="small"
                            onChange={(e) => {
                              if (subPhase.progress != e.target.value) {
                                // Only do api update if the value is different to prevent unnecessary updates
                                // alert(e.target.value);
                                updateSubPhase(subPhase.id, e.target.value);
                              }
                            }}
                            value={subPhase.progress ?? 0}
                            aria-label="Progress"
                            getAriaValueText={(val) => {
                              return `${val}%`;
                            }}
                            alueLabelDisplay="auto"
                            step={25}
                            marks={[
                              {
                                value: 0,
                                label: "0%",
                              },
                              {
                                value: 100,
                                label: "100%",
                              },
                            ]}
                            min={0}
                            max={100}
                          />
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  );
                })}
            </Box>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OrderItemDialogButton;
