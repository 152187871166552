import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDebounce, useDebouncedCallback } from "use-debounce";

// This prevents the the initial load by setting the TextField defaultValue to the value
const TextFieldDebouncedNoInitialLoad = ({ value, onChange, ...props }) => {
  const [text, setText] = useState(value);
  const debounced = useDebouncedCallback((val) => {
    let e = {
      target: {
        value: val,
      },
    };
    if (onChange) onChange(e);
  }, 500);

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <TextField
      {...props}
      value={text}
      // defaultValue={value}
      onChange={(e) => {
        setText(e.target.value);
        debounced(e.target.value);
      }}
    />
  );
};

export default TextFieldDebouncedNoInitialLoad;
