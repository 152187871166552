import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { AuthContext } from "../../../../context/AuthContext";
import WorkOrderButton from "../../../../components/PdfButtons/WorkOrderButton";
import PackingListButton from "../../../../components/PdfButtons/PackingListButton";
import OrderItemDialogButton from "./OrderItems/OrderItemDialogButton";
import DebouncedSlider from "../../../../components/DebouncedSlider";
import { Close, Delete, Edit, UnfoldMore } from "@mui/icons-material";

const JobDialog = ({
  dialogData,
  handleClose,
  handleEdit,
  handleDelete,
  handleSubmit,
  errorMessage,
}) => {
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [calendarEvent, setCalendarEvent] = useState(null);
  const [eventPhases, setEventPhases] = useState([]);
  const [productMap, setProductMap] = useState(new Map());
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const getEventDetails = async (eventId) => {
    try {
      setLoading(true);
      const res = await ezorder.get(`/shop/schedule/events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let event = res.data.calendarEvent;
      setCalendarEvent(event);

      // TODO: parse the event subphases and group into phases
      console.log("SubPhases", event.subPhases);
      let phaseMap = new Map();
      for (let subPhase of event.subPhases) {
        console.log("subPhase", subPhase);
        if (phaseMap.get(subPhase.phase.name)) {
          let phase = phaseMap.get(subPhase.phase.name);
          phase.subPhases = [...phase.subPhases, subPhase];
          phaseMap.set(subPhase.phase.name, phase);
        } else {
          phaseMap.set(subPhase.phase.name, {
            ...subPhase.phase,
            subPhases: [subPhase],
          });
        }
      }
      console.log("phaseMap", phaseMap);
      // Get Array of Phases
      setEventPhases(Array.from(phaseMap.values()));

      const prodMap = new Map();
      for (let i = 0; i < event.order.items.length; i++) {
        let orderItem = event.order.items[i];
        prodMap.set(`${orderItem.objectType}`, orderItem);
      }
      setProductMap(prodMap);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateSubPhase = async (subPhaseId, progress) => {
    try {
      setUpdating(true);
      const res = await ezorder.put(
        `/shop/orders/subPhase/${subPhaseId}`,
        { progress },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getEventDetails(dialogData.calendarEvent.id);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (dialogData.open && dialogData.calendarEvent) {
      // TODO: get jobPhases
      getEventDetails(dialogData.calendarEvent.id);
    } else {
      setEventPhases([]);
    }
  }, [dialogData]);

  if (dialogData)
    return (
      <Dialog
        open={dialogData.open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        fullScreen={isSmallScreen}
      >
        {dialogData.calendarEvent && (
          <>
            <DialogTitle sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                {dialogData.calendarEvent.order &&
                  dialogData.calendarEvent.order.company &&
                  dialogData.calendarEvent.order.project &&
                  dialogData.calendarEvent.order.orderStatus && (
                    <>
                      {!dialogData.calendarEvent.order.orderStatus ||
                      !dialogData.calendarEvent.order.orderStatus.name ||
                      dialogData.calendarEvent.order.orderStatus.name ==
                        "SAVED" ||
                      dialogData.calendarEvent.order.orderStatus.name ==
                        "QUOTE_REQUESTED" ||
                      dialogData.calendarEvent.order.orderStatus.name ==
                        "ESTIMATE_APPROVAL_NEEDED"
                        ? "Quote "
                        : "Order "}
                      #{dialogData.calendarEvent.order.id} -{" "}
                      {dialogData.calendarEvent.order.company.name}
                      <DialogContentText sx={{ display: "block" }}>
                        {dialogData.calendarEvent.order.company
                          .isVendorOrSupplier
                          ? "Vendor/Supplier Customer"
                          : "Project"}
                        : {dialogData.calendarEvent.order.project.name}
                      </DialogContentText>
                      {dialogData.calendarEvent.order.vendorProjectName && (
                        <DialogContentText sx={{ display: "block" }}>
                          Project:{" "}
                          {dialogData.calendarEvent.order.vendorProjectName}
                        </DialogContentText>
                      )}
                      {dialogData.calendarEvent.order.subProject && (
                        <DialogContentText sx={{ display: "block" }}>
                          Sub-Project:{" "}
                          {dialogData.calendarEvent.order.subProject}
                        </DialogContentText>
                      )}
                      <DialogContentText sx={{ display: "block" }}>
                        {dialogData.calendarEvent.order.poNumber
                          ? `PO#: ${dialogData.calendarEvent.order.poNumber}`
                          : ""}
                      </DialogContentText>
                    </>
                  )}
              </Box>
              {decoded.role == "EZORDER_ADMIN" && (
                <Box>
                  <IconButton onClick={handleDelete} sx={{}}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
              {decoded.role == "EZORDER_ADMIN" && (
                <Box>
                  <IconButton onClick={handleEdit} sx={{}}>
                    <Edit />
                  </IconButton>
                </Box>
              )}

              <Box>
                <IconButton onClick={handleClose} sx={{}}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>

            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: "200px",
                }}
              >
                <CircularProgress size="3rem" />
              </Box>
            ) : (
              <>
                {calendarEvent && (
                  <DialogContent>
                    <Divider sx={{ marginBottom: "10px" }} />
                    <DialogContentText>
                      Assigned To: {calendarEvent?.assignedTo?.fullName}
                    </DialogContentText>
                    <DialogContentText>
                      Date:{" "}
                      {moment(calendarEvent.startDate)
                        .tz("America/Phoenix")
                        .format("MM/DD/YYYY")}{" "}
                    </DialogContentText>
                    <DialogContentText>
                      Time:{" "}
                      {moment(calendarEvent.startDate)
                        .tz("America/Phoenix")
                        .format("h:mm a")}
                      {` - `}
                      {moment(calendarEvent.endDate)
                        .tz("America/Phoenix")
                        .format("h:mm a")}
                    </DialogContentText>

                    <Divider sx={{ marginY: "10px" }} />
                    <Box sx={{ width: "100%" }}>
                      <Typography variant="h6">Tasks</Typography>
                      {/* Phases */}
                      {updating ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            minHeight: "200px",
                          }}
                        >
                          <CircularProgress size="3rem" />
                        </Box>
                      ) : (
                        <>
                          <Box>
                            <Box>
                              {productMap &&
                                eventPhases &&
                                eventPhases.map((phase) => {
                                  let progress = 0;
                                  for (let subphase of phase.subPhases) {
                                    progress += subphase.progress;
                                  }

                                  progress = progress / phase.subPhases.length;

                                  return (
                                    <Box>
                                      <OrderItemDialogButton
                                        productMap={productMap}
                                        productType={phase.name}
                                        phase={phase}
                                        updateSubPhase={updateSubPhase}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "16px",
                                            cursor: "pointer",
                                            "&:hover": {
                                              background: "#eeeeee",
                                            },
                                          }}
                                        >
                                          <Box sx={{ flex: 1 }}>
                                            <Typography>
                                              {phase.name}
                                            </Typography>
                                          </Box>
                                          <Typography
                                            sx={{
                                              fontWeight: "bold",
                                              color:
                                                progress == 100
                                                  ? "green"
                                                  : "orange",
                                            }}
                                          >
                                            {progress}%
                                          </Typography>
                                        </Box>
                                      </OrderItemDialogButton>
                                      <Divider />
                                    </Box>
                                  );
                                })}
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                    <Divider sx={{ marginY: "10px" }} />
                    <Typography variant="h6">Documents</Typography>
                    <Box>
                      <WorkOrderButton
                        orderId={calendarEvent.order.id}
                        size="small"
                      />
                    </Box>
                  </DialogContent>
                )}
              </>
            )}
            {/* <DialogActions>
              <Button onClick={handleEdit}>Edit</Button>
            </DialogActions> */}
          </>
        )}
      </Dialog>
    );
};

export default JobDialog;
