import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectCustomerDialog from "./SelectCustomerDialog";

const SelectCustomer = ({ customer, onSelect }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TextField
        fullWidth
        size="small"
        label="Customer"
        inputProps={{ readOnly: true }}
        value={customer ? customer.name : ""}
        onClick={() => {
          setOpen(true);
        }}
      />
      <SelectCustomerDialog
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
        onSelect={onSelect}
      />
    </>
  );
};

export default SelectCustomer;
