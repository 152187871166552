import { Style } from "@mui/icons-material";
import {
  Box,
  Card,
  FormControl,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import CollapsableCard from "../../../../CollapseableCard";
import useQuickBooksCustomer from "../../../useQuickBooksCustomer";
import { useTheme } from "@emotion/react";

const QbCustomers = ({ qbCustomerId, action }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { customers, search, setSearch, category, setCategory, isLoading } =
    useQuickBooksCustomer();
  const [filteredOutSubCustomers, setFilteredOutSubCustomers] = useState([]);
  const [selectedId, setSelectedId] = useState();

  const determineName = (customer) => {
    if (customer?.CompanyName) return customer.CompanyName;
    if (customer?.DisplayName) return customer.DisplayName;
    if (customer?.FullyQualifiedName) return customer.FullyQualifiedName;
  };

  const selectedCustomer = filteredOutSubCustomers.find(
    (cust) => cust.Id === selectedId
  );

  const handleSelect = (Id) => {
    const customer = filteredOutSubCustomers.find((cust) => cust.Id === Id);
    setSelectedId(Id);
    action({
      type: "UPDATE DETAILS: QB CUSTOMER ID",
      payload: {
        customerId: Id,
        salesTermId: customer.SalesTermRef?.value ?? null,
      },
    });
  };

  const componentTitle = !selectedCustomer
    ? "Select a QuickBooks Customer"
    : `QuickBooks Customer: ${determineName(selectedCustomer)}`;

  useEffect(() => {
    setSelectedId(`${qbCustomerId}`);
  }, [qbCustomerId]);

  useEffect(() => {
    if (customers) {
      setFilteredOutSubCustomers(customers.filter((cust) => !cust.Job));
    }
  }, [customers]);
  return (
    <>
      <CollapsableCard title={componentTitle} defaultOpen={!qbCustomerId}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            marginBottom: "16px",
            fontWeight: "bold",
          }}
        >
          <Typography variant="overline" sx={{ fontWeight: "bold" }}>
            Selected:
          </Typography>
          <Typography variant="overline" sx={{ fontWeight: "bold" }}>
            {determineName(selectedCustomer)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "2rem",
            alignItems: "center",
          }}
        >
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Filter</InputLabel>
            <Select
              sx={{ minWidth: "175px" }}
              size="small"
              select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <MenuItem value="DisplayName">Display Name</MenuItem>
              <MenuItem value="CompanyName">Company Name</MenuItem>
              <MenuItem value="FullyQualifiedName">
                Fully Qualified Name
              </MenuItem>
              <MenuItem value="FamilyName">Customer Family Name</MenuItem>
              <MenuItem value="GivenName">Customer Given Name</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Search..."
            size="small"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: ".5rem",
            overflow: "auto",
            maxHeight: "150px",
            marginTop: "1rem",
          }}
        >
          {filteredOutSubCustomers.map((cust, index) => (
            <ListItem
              key={index}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  background: "#eeeeee",
                },
              }}
              onClick={() => handleSelect(cust.Id)}
            >
              <ListItemText
                primary={determineName(cust)}
                secondary={
                  <Typography>
                    {cust.GivenName} {cust.FamilyName}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </Box>
      </CollapsableCard>
    </>
  );
};
export default QbCustomers;
