import {
  Box,
  TextField,
  Button,
  MenuItem,
  Avatar,
  Typography,
  Modal,
  Card,
  Checkbox,
  FormControl,
  FilledInput,
  ListItem,
  ListItemText,
  FormLabel,
  FormGroup,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import { Edit, Upload } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { useTheme } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import ChangeOrderProjectDialog from "../../OrderDialogs/ChangeOrderProjectDialog";
import * as Sentry from "@sentry/react";

const OrderPrimaryInfo = ({ details, action, isCustomMaterial }) => {
  const theme = useTheme();
  const { authToken, ezorder } = useContext(AuthContext);
  const [taxFormModalOpen, setTaxFormModalOpen] = useState(false);
  const [taxFormModalFile, setTaxFormModalFile] = useState(null);
  const [newTaxFormName, setNewTaxFormName] = useState("");
  const [newTaxFormExpirationDate, setNewTaxFormExpirationDate] = useState(
    moment().format("MM/DD/YYYY")
  );
  const [quoteRequesters, setQuoteRequesters] = useState([]);
  const [isEditingRequester, setIsEditingRequester] = useState(false);
  const [quoteApprovers, setQuoteApprovers] = useState([]);
  const [isEditingApprover, setIsEditingApprover] = useState(false);
  const [isChangingProject, setIsChangingProject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmedCutListInfo, setShowConfirmedCutListInfo] =
    useState(false);
  const [showUnConfirmedCutListInfo, setShowUnConfirmedCutListInfo] =
    useState(false);
  const handleDate = (date) => {
    const expirationDate = moment(date).format("MM/DD/YYYY");
    setNewTaxFormExpirationDate(expirationDate);
  };

  const handleChange = (type) => (e) => {
    switch (type) {
      case "vendorProjectName":
        action({
          type: "UPDATE DETAILS: VENDOR PROJECT NAME",
          payload: e.target.value,
        });
        break;
      case "subProject":
        action({
          type: "UPDATE DETAILS: SUB PROJECT NAME",
          payload: e.target.value,
        });
        break;
      case "poNumber":
        action({ type: "UPDATE DETAILS: PO#", payload: e.target.value });
        break;
      case "notes":
        action({ type: "UPDATE DETAILS: NOTES", payload: e.target.value });
        break;
      default:
        break;
    }
  };
  const [submitting, setIsSubmitting] = useState(false);
  const handleSubmitProject = async ({ name }) => {
    try {
      setIsSubmitting(true);
      if (!name) return;
      const result = await ezorder.post(
        `/admin/companies/${details.company.id}/projects`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      action({
        type: "SET ORDER STATE",
        payload: { project: result.data.project },
      });
      setIsChangingProject(false);
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // get presignedUrl
  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource,
    identifier
  ) => {
    console.log("DETAILS", details);
    try {
      let presignedRequest = {
        actionType: actionType,
        fileName: fileName,
        contentType: contentType,
        resource: resource,
        identifier: identifier,
      };

      const response = await ezorder.post(
        `/files/presignedUrl`,
        presignedRequest,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log("Presigned URL", response.data);

      return response.data;
    } catch (error) {
      console.log(error);
      throw Error("Unable to load presigned URL for Trim image", {
        cause: error,
      });
    }
  };

  // uplaod image to S3 bucket
  const uploadTaxForm = async () => {
    setIsLoading(true);
    console.log("DETAILS", details);
    const identifier = details.company.id ? details.company.id : "GUEST";

    let actionType = "WRITE";
    let fileName = taxFormModalFile.name;
    let contentType = taxFormModalFile.type;
    let resource = "tax-forms";

    const presignedResponse = await createWritePresignedUrl(
      actionType,
      fileName,
      contentType,
      resource,
      identifier
    );
    let s3Key = presignedResponse.key;

    await fetch(presignedResponse.uploadUrl, {
      method: "PUT",
      body: taxFormModalFile,
    })
      .then(async (response) => {
        console.log(response);
        if (response.status === 200 || response.status == 201) {
          let newTaxForm = {
            name: newTaxFormName,
            file: s3Key,
            companyId: details.company.id ? details.company.id : null,
            type: "ANNUAL",
            expirationDate: newTaxFormExpirationDate,
            shouldSetAsCompanyDefault: true,
          };

          const saveResponse = await ezorder.post(
            `/admin/orders/${details.id}/tax-form`,
            newTaxForm,
            {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          );

          let taxForm = saveResponse.data.order.taxExemptForm;
          console.log("Tax Form Saved", taxForm);
          action({
            type: "UPDATE DETAILS: TAX EXEMPT FORM",
            payload: taxForm,
          });
          setNewTaxFormName("");
          setTaxFormModalFile(null);
          setTaxFormModalOpen(false);
        } else {
          Sentry.captureException(
            new Error(`Upload Failed. Response Status ${response.status}`)
          );
          // For any other server error
          throw new Error(`Upload Failed ${response.status}`);
        }

        setIsLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        setIsLoading(false);
        throw Error("Unable to upload Trim image.", { cause: error });
      });
  };

  const getCompanyQuoteRequesters = async (companyId) => {
    try {
      const res = await ezorder.get(
        `/admin/companies/${companyId}/estimate-requesters`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setQuoteRequesters(res.data.quoteRequesters);
      return quoteRequesters;
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  const getCompanyQuoteApprovers = async (companyId, requesterId) => {
    try {
      const res = await ezorder.get(
        `/admin/companies/${companyId}/users/${requesterId}/estimate-approvers`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setQuoteApprovers(res.data.quoteApprovers);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (details && details.company && details.company.id) {
      getCompanyQuoteRequesters(details.company.id);
    }
  }, [details.company]);

  useEffect(() => {
    if (details.estimateRequestedBy) {
      getCompanyQuoteApprovers(
        details.company.id,
        details.estimateRequestedBy.id
      );
    }
  }, [details.estimateRequestedBy]);

  return (
    // <CollapsableCard title="Details">
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {
        // Show Mobile Order Fields
        details.type !== "GUEST" && (
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <TextField
              variant="outlined"
              label={
                details.company.isVendorOrSupplier
                  ? "Vendor/Supplier Company"
                  : "Company"
              }
              id="company-name"
              name="Company Name"
              size="small"
              fullWidth
              value={
                details.company && details.company.name
                  ? details.company.name
                  : "GUEST"
              }
            />
            <TextField
              variant="outlined"
              label={
                details.company.isVendorOrSupplier
                  ? "Vendor/Supplier Customer Name"
                  : "Project Name"
              }
              id="project-name"
              name="Project Name"
              size="small"
              fullWidth
              value={details?.project?.name}
              onClick={() => {
                setIsChangingProject(true);
              }}
            />
            <ChangeOrderProjectDialog
              open={isChangingProject}
              setOpen={setIsChangingProject}
              companyId={details.company.id}
              isVendorOrSupplier={details.isVendorOrSupplier}
              handleSubmitProject={handleSubmitProject}
              isSubmitting={submitting}
              handleProjectSelect={(project) => {
                action({
                  type: "SET ORDER STATE",
                  payload: { project: project },
                });
                setIsChangingProject(false);
              }}
            />
            {details.company.isVendorOrSupplier && (
              <TextField
                variant="outlined"
                label="Vendor/Supplier Project Name"
                id="project-name"
                name="Project Name"
                size="small"
                fullWidth
                value={details?.vendorProjectName}
                onChange={handleChange("vendorProjectName")}
              />
            )}
            <TextField
              variant="outlined"
              label="Sub-Project Name (ie Bldg #, etc)"
              id="sub-project"
              name="Sub-Project Name (ie Bldg #, etc)"
              size="small"
              fullWidth
              value={details?.subProject}
              onChange={handleChange("subProject")}
            />
            <TextField
              variant="outlined"
              label="P.O.#"
              id="po-number"
              name="PO Number"
              size="small"
              fullWidth
              value={details.poNumber}
              onChange={handleChange("poNumber")}
            />
          </Box>
        )
      }
      <Box>
        <Box>
          <FormLabel>
            When does the customer need this by? (Optional). Use this when
            taking into account pricing
          </FormLabel>
          <Box sx={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
            <Box sx={{ flex: 1, display: "flex", flexDirection: "row" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  disabled={
                    details &&
                    details.orderStatus &&
                    details.orderStatus.name != "QUOTE_REQUESTED"
                  }
                  // label="Confirm Due Date Requested By the Customer"
                  value={details.customerRequestedCompletionDate}
                  onChange={(newValue) => {
                    action({
                      type: "SET ORDER STATE",
                      payload: {
                        customerRequestedCompletionDate: new Date(newValue),
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField size="small" sx={{}} {...params} />
                  )}
                />
              </LocalizationProvider>
              <Button
                onClick={() => {
                  action({
                    type: "SET ORDER STATE",
                    payload: {
                      customerRequestedCompletionDate: null,
                    },
                  });
                }}
              >
                Clear
              </Button>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: "16px" }}>
          {details && (
            <FormGroup sx={{}}>
              <FormLabel>Is this a final cut-list?</FormLabel>
              <RadioGroup
                value={`${details.isUnconfirmedCutlist}`}
                onChange={(e) => {
                  let val = false;
                  if (e.target.value == "true") {
                    val = true;
                  }
                  console.log("STRING VALUE", e.target.value);
                  console.log("ACTUAL VALUE", val);

                  action({
                    type: "SET ORDER STATE",
                    payload: {
                      isUnconfirmedCutlist: val,
                    },
                  });
                }}
              >
                <Box>
                  <FormControlLabel
                    value={"false"}
                    control={
                      <Radio
                        disabled={
                          details &&
                          details.orderStatus &&
                          details.orderStatus.name != "QUOTE_REQUESTED"
                        }
                      />
                    }
                    // label={`Confirmed Cut-List - For Ordering`}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography id="standard-bare" margin="normal">
                          Confirmed Cut-List - For Ordering
                          <Button
                            style={{ padding: 0 }}
                            sx={{ padding: 0 }}
                            onClick={() => {
                              setShowConfirmedCutListInfo(
                                !showConfirmedCutListInfo
                              );
                            }}
                          >
                            Info
                          </Button>
                        </Typography>
                      </Box>
                    }
                  />
                  {showConfirmedCutListInfo && (
                    <FormHelperText
                      sx={{
                        paddingLeft: "32px",
                        paddingTop: 0,
                        marginTop: "0",
                      }}
                    >
                      Upon Submission, we will issue a Comprehensive / Detailed
                      Quote, that once approved, will be turned into a Work
                      Order for fabrication
                    </FormHelperText>
                  )}
                </Box>
                <Box>
                  <FormControlLabel
                    value={"true"}
                    control={
                      <Radio
                        disabled={
                          details &&
                          details.orderStatus &&
                          details.orderStatus.name != "QUOTE_REQUESTED"
                        }
                      />
                    }
                    // label={`Preliminary Cut-List - For Budgeting and Estimating Only`}
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography id="standard-bare" margin="normal">
                          Preliminary Cut-List - For Budgeting and Estimating
                          Only
                          <Button
                            onClick={() => {
                              setShowUnConfirmedCutListInfo(
                                !showUnConfirmedCutListInfo
                              );
                            }}
                          >
                            Info
                          </Button>
                        </Typography>
                      </Box>
                    }
                  />
                  {showUnConfirmedCutListInfo && (
                    <FormHelperText
                      sx={{
                        paddingLeft: "32px",
                        paddingTop: 0,
                        marginTop: "0",
                      }}
                    >
                      This is only used to provide a comprehensive / detailed
                      Estimate to be used for your internal pricing / estimating
                      purposes only. We recommend this if you are estimating a
                      job and don't have an exact cut-list, but can get close.
                      It cannot be "Approved", nor can it be turned into a work
                      order. A later date, it can be changed to a "Confirmed"
                      Cut-List if desired.{" "}
                    </FormHelperText>
                  )}
                </Box>
              </RadioGroup>
            </FormGroup>
          )}
        </Box>
      </Box>
      {
        // Select Estimate Requester User if NOT a Guest Order
        details.type !== "GUEST" && (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              {details.estimateRequestedBy && !isEditingRequester ? (
                <Card
                  sx={{
                    padding: "16px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: "8xp", fontSize: 18 }}>
                      Quote Requester (Primary Contact)
                    </Typography>
                    {(!details.orderStatus ||
                      !details.orderStatus.name ||
                      // details.status == "" ||
                      details.orderStatus.name == "QUOTE_REQUESTED") && (
                      <Edit
                        onClick={() => {
                          setIsEditingRequester(true);
                        }}
                      />
                    )}
                  </Box>
                  <ListItem>
                    <ListItemText
                      primary={details.estimateRequestedBy.fullName}
                      secondary={
                        <>
                          <Typography
                            style={{
                              fontSize: 12,
                            }}
                          >
                            Email: {details.estimateRequestedBy.email}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                </Card>
              ) : (
                <Box sx={{ flex: 1, height: "100%" }}>
                  <Card
                    style={{
                      padding: "16px",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: "8xp", fontSize: 18 }}>
                      Select Quote Requester (Primary Contact)
                    </Typography>
                    <Typography sx={{ color: theme.palette.success.main }}>
                      Make sure to save the order and re-generate the quote
                      after selecting a new requester or approver. This affects
                      finalizing the quote and who is able to sign the quote
                    </Typography>
                    <div
                      style={{
                        marginTop: 16,
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <div
                        style={{
                          display: "block",
                          maxHeight: 200,
                          maxWidth: "100%",
                          overflow: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {quoteRequesters &&
                          quoteRequesters.map((quoteRequester, index) => (
                            <ListItem
                              onClick={() => {
                                action({
                                  type: "UPDATE DETAILS: ESTIMATE REQUESTED BY",
                                  payload: quoteRequester,
                                });
                                setIsEditingRequester(false);
                              }}
                              sx={{
                                cursor: "pointer",
                                "&:hover": {
                                  background: "#eeeeee",
                                },
                              }}
                            >
                              <ListItemText
                                primary={quoteRequester.fullName}
                                secondary={
                                  <>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      Email: {quoteRequester.email}
                                    </Typography>
                                    <Typography
                                      style={{
                                        fontSize: 12,
                                      }}
                                    >
                                      Phone: {quoteRequester.phone}
                                    </Typography>
                                  </>
                                }
                              />
                            </ListItem>
                          ))}
                      </div>
                    </div>
                  </Card>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                paddingLeft: "16x",
                flex: 1,
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              {details.estimateRequestedBy && quoteApprovers && (
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {details.estimateAssignedApprover && !isEditingApprover ? (
                    <Card
                      sx={{
                        width: "100%",
                        height: "100%",
                        padding: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ mb: "8xp", fontSize: 18 }}
                        >
                          Assigned Approver
                        </Typography>
                        {(!details.orderStatus ||
                          !details.orderStatus.name ||
                          // details.status == "" ||
                          details.orderStatus.name == "QUOTE_REQUESTED") && (
                          <Edit
                            onClick={() => {
                              // action({
                              //   type: "UPDATE DETAILS: ESTIMATE ASSIGNED APPROVER",
                              //   payload: null,
                              // });
                              setIsEditingApprover(true);
                            }}
                          />
                        )}
                      </Box>
                      <ListItem>
                        <ListItemText
                          primary={details.estimateAssignedApprover.fullName}
                          secondary={
                            <>
                              <Typography
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                Email: {details.estimateAssignedApprover.email}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    </Card>
                  ) : (
                    <Box>
                      <Card
                        style={{
                          padding: "16px",
                        }}
                      >
                        <Typography
                          variant="h6"
                          sx={{ mb: "8xp", fontSize: 18 }}
                        >
                          Select Approver for this Quote
                        </Typography>
                        <Typography sx={{ color: theme.palette.success.main }}>
                          Make sure to save the order after selecting a new
                          requester or approver. This affects finalizing the
                          quote and who is able to sign the quote
                        </Typography>
                        <div
                          style={{
                            marginTop: 16,
                            paddingLeft: 16,
                            paddingRight: 16,
                          }}
                        >
                          <div
                            style={{
                              display: "block",
                              maxHeight: 200,
                              maxWidth: "100%",
                              overflow: "scroll",
                              overflowX: "hidden",
                            }}
                          >
                            {quoteApprovers.map((quoteApprover, index) => (
                              <ListItem
                                onClick={() => {
                                  action({
                                    type: "UPDATE DETAILS: ESTIMATE ASSIGNED APPROVER",
                                    payload: quoteApprover,
                                  });
                                  setIsEditingApprover(false);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    background: "#eeeeee",
                                  },
                                }}
                              >
                                <ListItemText
                                  primary={quoteApprover.fullName}
                                  secondary={
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: 12,
                                        }}
                                      >
                                        Email: {quoteApprover.email}
                                      </Typography>
                                      <Typography
                                        style={{
                                          fontSize: 12,
                                        }}
                                      >
                                        Phone: {quoteApprover.phone}
                                      </Typography>
                                    </>
                                  }
                                />
                              </ListItem>
                            ))}
                          </div>
                        </div>
                      </Card>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        )
      }
    </Box>
    // </CollapsableCard>
  );
};
export default OrderPrimaryInfo;
