import {
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import {
  Close,
  KeyboardArrowDown,
  KeyboardArrowDownOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
  KeyboardArrowUp,
  KeyboardArrowUpOutlined,
  Percent,
  Settings,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import SortableCut from "../../../../../pages/admin/FsOptimizer/SortableCut";
import { toTwoDecimals } from "../../../../../components/NewDetail/calculations/utils";

const OptimizedFlats = ({ flatSheetSize, pieces, onOptimize }) => {
  const [flatSheetsTotal, setFlatSheetsTotal] = useState(0);
  const [open, setOpen] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: { delay: 120 }, // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [activeCut, setActiveCut] = useState(null);

  function handleDragStart(event) {
    const { active } = event;
    const currentActive = orderedCuts.find((cut) => cut.tempId == active.id);
    setActiveCut(currentActive);
  }

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setOrderedCuts((items) => {
        const oldIndex = items.findIndex((cut) => cut.tempId == active.id);
        const newIndex = items.findIndex((cut) => cut.tempId == over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }

    setActiveCut(null);
  }

  const [hidePriority, setHidePriority] = useState(false);
  const [loading, setLoading] = useState(false);

  //   const [pieces, setPieces] = useState([]);

  const [orderedPieces, setOrderedPieces] = useState([]);
  const [orderedCuts, setOrderedCuts] = useState([]);

  const [flatSheets, setFlatSheets] = useState([]);
  const [flatSheetLowerBound, setFlatSheetLowerBound] = useState(0);
  const [totalDrop, setTotalDrop] = useState(0);
  const [fsUtilizationRate, setFsUtilizationRate] = useState(0);

  const [flatSheetsForShearing, setFlatSheetsForShearing] = useState([]);

  const [shearInstructions, setShearInstructions] = useState([]);

  const parseOrderedPieces = () => {
    // Copy And Sort Pieces
    let piecesCopy = [...pieces];
    for (let piece of piecesCopy) {
      piecesCopy.tempId = uuidv4();
    }
    piecesCopy.sort((a, b) => {
      if (b.stretchOut - a.stretchOut != 0) {
        return b.stretchOut - a.stretchOut;
      } else {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    });
    setOrderedPieces(piecesCopy);
  };

  //   Deprecated
  const parseSheetsByPieces = (pieces) => {
    let cuts = [];
    let totalStretchOut = 0;
    for (let piece of pieces) {
      for (let i = 0; i < piece.quantity; i++) {
        cuts.push({
          name: piece.name,
          stretchOut: piece.stretchOut,
          tempId: uuidv4(),
        });
        totalStretchOut += Number(piece.stretchOut);
      }
    }
    setFlatSheetLowerBound(Math.ceil(totalStretchOut / flatSheetSize));
    setOrderedCuts(cuts);
  };

  const parseSheetsByCuts = (cuts) => {
    let sheets = [{ pieces: [] }];
    // For Each Group
    for (let cut of cuts) {
      // Cuts are already in descending stretchout order
      let fsIndex = 0;

      // For Each Cut
      // Find which FS to add the Cut into
      let added = false;
      while (!added) {
        let currentFS = sheets[fsIndex];
        let currentFSUsedWidth = 0;
        for (let fsPiece of currentFS.pieces) {
          currentFSUsedWidth += Number(fsPiece.stretchOut);
        }

        let leftOverWidth = flatSheetSize - currentFSUsedWidth;
        if (leftOverWidth >= cut.stretchOut) {
          sheets[fsIndex].pieces = [
            ...currentFS.pieces,
            {
              name: cut.name,
              stretchOut: cut.stretchOut,
              tempId: uuidv4(),
            },
          ];
          added = true;
        } else {
          if (fsIndex >= sheets.length - 1) {
            sheets.push({
              pieces: [
                {
                  name: cut.name,
                  stretchOut: cut.stretchOut,
                  tempId: uuidv4(),
                },
              ],
            });
            added = true;
          }
          fsIndex++;
        }
      }
    }

    setFlatSheets(sheets);
    setFlatSheetsTotal(sheets.length);

    let totalInchesDrop = 0;
    let totalInchesUsed = 0;
    for (let i = 0; i < sheets.length; i++) {
      let flatSheet = sheets[i];
      let currentFSUsedWidth = 0;
      for (let fsPiece of flatSheet.pieces) {
        currentFSUsedWidth += Number(fsPiece.stretchOut);
      }
      let drop = flatSheetSize - currentFSUsedWidth;
      totalInchesDrop += drop;
      totalInchesUsed += currentFSUsedWidth;
    }
    setTotalDrop(totalInchesDrop);
    let utilization = parseInt(
      (100 * totalInchesUsed) / (totalInchesUsed + totalInchesDrop)
    );
    setFsUtilizationRate(utilization);
  };

  const reorganizeCutsAndFlatsForShearing = () => {
    // Copy Flat Sheets
    let sheetsCopy = [];
    for (let sheet of flatSheets) {
      let pieces = sheet.pieces;
      sheetsCopy.push({
        ...sheet,
        size: flatSheetSize,
        pieces: pieces.map((object) => ({ ...object })),
      });
    }

    // Generate Shear Instructions
    let instructions = [];

    let orderedPiecesCopy = [...orderedPieces];

    orderedPiecesCopy.sort((a, b) => {
      if (b.stretchOut - a.stretchOut != 0) {
        return b.stretchOut - a.stretchOut;
      } else {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }
    });

    // Sort Pieces in each flat sheet into descending size
    for (let sheet of sheetsCopy) {
      sheet.pieces.sort((a, b) => {
        if (b.stretchOut - a.stretchOut != 0) {
          return b.stretchOut - a.stretchOut;
        } else {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }
      });
    }

    // Sort Flat Sheets By Cutting Order
    sheetsCopy.sort((a, b) => {
      let aPieces = a.pieces;
      let bPieces = b.pieces;

      let aIndex = 0;
      let bIndex = 0;
      while (aPieces[aIndex].stretchOut == bPieces[bIndex].stretchOut) {
        if (aIndex + 1 < aPieces.length) {
          aIndex++;
        } else {
          return -1;
        }
        if (bIndex + 1 < bPieces.length) {
          bIndex++;
        } else {
          return 1;
        }
      }

      return bPieces[bIndex].stretchOut - aPieces[aIndex].stretchOut;
    });
    setFlatSheetsForShearing(sheetsCopy);

    // Copy the sorted sheets (deep copy without references)

    let flatSheetsCopy = [];
    for (let sheet of sheetsCopy) {
      let pieces = sheet.pieces;
      flatSheetsCopy.push({
        ...sheet,
        size: flatSheetSize,
        pieces: pieces.map((object) => ({ ...object })),
      });
    }

    // Iterate Through Trim Types
    for (let orderedPiece of orderedPiecesCopy) {
      let currentPieceName = orderedPiece.name;
      let currentPieceStretchOut = orderedPiece.stretchOut;

      // Iterate Through Flat Sheets
      for (
        let sheetIndex = 0;
        sheetIndex < flatSheetsCopy.length;
        sheetIndex++
      ) {
        let currentSheet = flatSheetsCopy[sheetIndex];
        let currentSheetSizeBeforeCutting = currentSheet.size;

        let numberOfPiecesRemoved = 0;

        // Iterate Through Flat Sheet Cuts
        let allPiecesOfCurrentSizeRemovedFromSheet = false;
        let cutIndex = 0;
        while (
          cutIndex < currentSheet.pieces.length &&
          !allPiecesOfCurrentSizeRemovedFromSheet
        ) {
          let currentCut = currentSheet.pieces[cutIndex];
          if (
            currentCut.name == currentPieceName &&
            currentCut.stretchOut == currentPieceStretchOut
          ) {
            if (currentSheetSizeBeforeCutting < flatSheetSize) {
              // + Add Instruction for Current Piece
              instructions.push(
                `[${currentSheetSizeBeforeCutting}" DROP - FS #${
                  sheetIndex + 1
                }] - SHEAR ${currentCut.stretchOut}" - ${currentCut.name}`
              );
            } else {
              // + Add Instruction for Current Piece
              instructions.push(
                `[FS #${sheetIndex + 1}] - SHEAR ${currentCut.stretchOut}" - ${
                  currentCut.name
                }`
              );
            }

            // Pop First Element
            numberOfPiecesRemoved++;

            // Reduce FlatSheet Size
            currentSheet.size =
              Number(currentSheet.size) - Number(currentCut.stretchOut);

            // Check If Next Piece Exists
            if (cutIndex + 1 < currentSheet.pieces.length) {
              let nextPiece = currentSheet.pieces[cutIndex + 1];
              // If next piece is the last piece and is equal to the current sheet size then it no longer needs cutting
              if (nextPiece.stretchOut == currentSheet.size) {
                instructions.push(
                  `[FS #${sheetIndex + 1}] - LAST PIECE ${
                    currentCut.stretchOut
                  }" - ${currentCut.name}`
                );
                // Pop This Element as well
                numberOfPiecesRemoved++;
                // Reduce FlatSheet Size
                currentSheet.size =
                  Number(currentSheet.size) - Number(nextPiece.stretchOut);
                cutIndex++;
              } else {
                // If there is last piece but is not equal to the current sheet size and not equal to the current cut size
                // then save the rest as drop for later
                if (nextPiece.stretchOut != currentCut.stretchOut) {
                  if (currentSheetSizeBeforeCutting < flatSheetSize) {
                    instructions.push(
                      `[${currentSheetSizeBeforeCutting}" DROP - FS #${
                        sheetIndex + 1
                      }] - SET ASIDE [${currentSheet.size}" DROP]`
                    );
                  } else {
                    instructions.push(
                      `[FS #${sheetIndex + 1}] - SET ASIDE [${
                        currentSheet.size
                      }" DROP]`
                    );
                  }
                }
              }
            } else {
              // If there are no more pieces but the sheet size > 0
              // Then the last piece is a drop
              if (currentSheet.size > 0) {
                {
                  if (currentSheetSizeBeforeCutting < flatSheetSize) {
                    instructions.push(
                      `[${currentSheetSizeBeforeCutting}" DROP - FS #${
                        sheetIndex + 1
                      }] - DROP ${currentSheet.size}" - put away for future use`
                    );
                  } else {
                    instructions.push(
                      `[FS #${sheetIndex + 1}] - DROP ${
                        currentSheet.size
                      }" - put away for future use`
                    );
                  }
                }
              }
            }
          } else {
            allPiecesOfCurrentSizeRemovedFromSheet = true;
          }
          cutIndex++;
        }
        // Remove Cuts that were removed from the array
        for (let i = 0; i < numberOfPiecesRemoved; i++) {
          currentSheet.pieces.shift();
        }
      }
    }
    setShearInstructions(instructions);
  };

  useEffect(() => {
    if (pieces && pieces.length > 0) {
      parseOrderedPieces();
    } else {
      setOrderedPieces([]);
      setOrderedCuts([]);
      setFlatSheets([]);
    }
  }, [pieces]);

  useEffect(() => {
    if (orderedPieces && orderedPieces.length > 0) {
      parseSheetsByPieces(orderedPieces);
    } else {
      setFlatSheets([]);
      setOrderedCuts([]);
    }
    setShearInstructions([]);
    setFlatSheetsForShearing([]);
  }, [flatSheetSize, orderedPieces]);

  useEffect(() => {
    if (orderedCuts && orderedCuts.length > 0) {
      parseSheetsByCuts(orderedCuts);
    } else {
      setFlatSheets([]);
      setFlatSheetsTotal(0);
      setTotalDrop(0);
      setFsUtilizationRate(0);
    }
    setShearInstructions([]);
    setFlatSheetsForShearing([]);
  }, [orderedCuts]);

  useEffect(() => {
    onOptimize(flatSheets.length, totalDrop);
  }, [flatSheets, totalDrop]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          FS Optimizer
        </Button>
        <TextField
          sx={{ width: "150px", marginLeft: "8px" }}
          size="small"
          label="Optimized Flats"
          type="number"
          value={flatSheetsTotal}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment>
                <Settings /> Optimize
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ width: "150px", marginLeft: "8px" }}
          size="small"
          label="FS Utilization Rate"
          type="number"
          value={fsUtilizationRate}
          InputProps={{
            readOnly: true,
            disableUnderline: true,
            endAdornment: "%",
          }}
        />
      </Box>
      <Dialog
        fullScreen={true}
        fullWidth={true}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>FS Optimizer</DialogTitle>
        <DialogContent>
          <Box sx={{ height: "100%", margin: 0 }}>
            <Typography variant="h6" component="div" sx={{ marginTop: "32px" }}>
              Flat Sheet Size
            </Typography>
            <TextField
              sx={{ marginTop: "16px" }}
              label="Flat Sheet Size"
              type="number"
              size="small"
              value={flatSheetSize}
              // onChange={(e) => {
              //   setFlatSheetSize(e.target.value);
              // }}
            />

            <Typography variant="h6" component="div" sx={{ marginTop: "32px" }}>
              Pieces
            </Typography>

            {pieces.map((piece, index) => {
              return (
                <Box
                  key={piece.tempId}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "16px",
                  }}
                >
                  <TextField
                    sx={{ flex: 1 }}
                    label="Name"
                    fullWidth
                    size="small"
                    value={piece.name}
                    // onChange={(e) => {
                    //   let copy = [...pieces];
                    //   copy[index].name = e.target.value;
                    //   setPieces(copy);
                    // }}
                  />
                  <TextField
                    label="Quantity"
                    type="number"
                    size="small"
                    value={piece.quantity}
                    // onChange={(e) => {
                    //   let copy = [...pieces];
                    //   copy[index].quantity = e.target.value;
                    //   setPieces(copy);
                    // }}
                  />
                  <TextField
                    label="Stretchout"
                    type="number"
                    size="small"
                    value={piece.stretchOut}
                    // onChange={(e) => {
                    //   let copy = [...pieces];
                    //   copy[index].stretchOut = e.target.value;
                    //   setPieces(copy);
                    // }}
                  />
                  <IconButton
                  // onClick={() => {
                  //   let copy = [...pieces];
                  //   copy.splice(index, 1);
                  //   setPieces(copy);
                  // }}
                  >
                    <Close />
                  </IconButton>
                </Box>
              );
            })}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
              // onClick={() => {
              //   let copy = [...pieces, { tempId: uuidv4() }];
              //   setPieces(copy);
              // }}
              >
                Add Piece
              </Button>
            </Box>

            {!hidePriority && (
              <>
                <>
                  <Box
                    sx={{
                      marginTop: "32px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Typography variant="h6" component="div">
                      Priority
                    </Typography>
                    <Button
                      onClick={() => {
                        parseOrderedPieces();
                      }}
                    >
                      Reset
                    </Button>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography variant="overline">
                      First-Fit Decreasing (Default) Bin Packing Algorithm
                    </Typography>
                    <Typography variant="caption">
                      The pieces are sorted in decreasing order. Each piece is
                      added into the first flatsheet that they fit in. If none
                      of the flatsheets can take the current piece, then it is
                      added into a new flatsheet
                    </Typography>
                    <Typography variant="caption">
                      This is an approximation algorithm that aims to minimize
                      the number of bins, or in this case flatsheets, used for
                      all the pieces of trim.
                    </Typography>
                    <Typography variant="caption">
                      An approximation gives a "good" solution in most cases but
                      does not guarantee the best or correct solution
                    </Typography>
                    <Typography variant="caption">
                      The lowerbound is the minimum number of flatsheets
                      required to fit the combined stretchouts assuming there
                      are no drops. Lowerbound = CEILING(total stretchouts /
                      flat sheet size)
                    </Typography>
                    <Typography variant="caption">
                      There's no guarantee that the best solution will equal the
                      lowerbound but it is a good way to see if a good solution
                      is given. If the total FS is equal to the lowerbound, then
                      we know that the total flatsheets used cannot be reduced
                      further
                    </Typography>
                  </Box>
                  {/* <>
                    <Box
                      sx={{
                        marginTop: "32px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Typography variant="h6" component="div">
                        Change Piece Priority
                      </Typography>
                    </Box>
                    {orderedPieces.map((orderedPiece, index) => {
                      return (
                        <Box
                          key={orderedPiece.tempId}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                          }}
                        >
                          <IconButton
                            disabled={!(index < orderedPieces.length - 1)}
                            color="primary"
                            onClick={() => {
                              let orderedPiecesCopy = [...orderedPieces];
                              let current = orderedPiecesCopy[index];
                              let next = orderedPiecesCopy[index + 1];
                              orderedPiecesCopy[index + 1] = current;
                              orderedPiecesCopy[index] = next;
                              setOrderedPieces(orderedPiecesCopy);
                            }}
                          >
                            <KeyboardArrowDownOutlined />
                          </IconButton>
                          <IconButton
                            disabled={!(index > 0)}
                            color="primary"
                            onClick={() => {
                              let orderedPiecesCopy = [...orderedPieces];
                              let prev = orderedPiecesCopy[index - 1];
                              let current = orderedPiecesCopy[index];
                              orderedPiecesCopy[index - 1] = current;
                              orderedPiecesCopy[index] = prev;
                              setOrderedPieces(orderedPiecesCopy);
                            }}
                          >
                            <KeyboardArrowUpOutlined />
                          </IconButton>
                          <Typography>
                            {orderedPiece.name} - {orderedPiece.stretchOut}" x{" "}
                            {orderedPiece.quantity}
                          </Typography>
                        </Box>
                      );
                    })}
                  </> */}
                  <>
                    <Box
                      sx={{
                        marginTop: "32px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Typography variant="h6" component="div">
                        Change Cut Priority
                      </Typography>
                    </Box>
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                    >
                      <SortableContext
                        items={orderedCuts}
                        strategy={rectSortingStrategy}
                      >
                        <Grid container sx={{ marginTop: "16px" }}>
                          {orderedCuts.map((orderedCut, index) => {
                            return (
                              <SortableCut
                                key={orderedCut.tempId}
                                orderedCut={orderedCut}
                                showLeftAndRightButtons={true}
                                disableLeft={!(index > 0)}
                                onClickLeft={() => {
                                  let orderedCutsCopy = [...orderedCuts];
                                  let prev = orderedCutsCopy[index - 1];
                                  let current = orderedCutsCopy[index];
                                  orderedCutsCopy[index - 1] = current;
                                  orderedCutsCopy[index] = prev;
                                  setOrderedCuts(orderedCutsCopy);
                                }}
                                disableRight={!(index < orderedCuts.length - 1)}
                                onClickRight={() => {
                                  let orderedCutsCopy = [...orderedCuts];
                                  let current = orderedCutsCopy[index];
                                  let next = orderedCutsCopy[index + 1];
                                  orderedCutsCopy[index + 1] = current;
                                  orderedCutsCopy[index] = next;
                                  setOrderedCuts(orderedCutsCopy);
                                }}
                              />
                            );
                          })}
                        </Grid>
                      </SortableContext>
                      <DragOverlay>
                        {activeCut ? (
                          <SortableCut orderedCut={activeCut} />
                        ) : null}
                      </DragOverlay>
                    </DndContext>
                  </>
                </>
              </>
            )}

            <Typography variant="h6" component="div" sx={{ marginTop: "32px" }}>
              Flat Sheets: {flatSheets.length} (Lowerbound ={" "}
              {flatSheetLowerBound} FS). Total In. Drop = {totalDrop}"
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption">
                The lowerbound is the minimum number of flatsheets required to
                fit the combined stretchouts assuming there are no drops.
                Lowerbound = CEILING(total stretchouts / flat sheet size)
              </Typography>
              <Typography variant="caption">
                There's no guarantee that the best solution will equal the
                lowerbound but it is a good way to see if a good solution is
                given. If the total FS is equal to the lowerbound, then we know
                that the total flatsheets used cannot be reduced further
              </Typography>
            </Box>
            <Grid container spacing={2} sx={{}}>
              {flatSheets.map((flatSheet, index) => {
                let currentFSUsedWidth = 0;
                for (let fsPiece of flatSheet.pieces) {
                  currentFSUsedWidth += Number(fsPiece.stretchOut);
                }
                let drop = flatSheetSize - currentFSUsedWidth;
                return (
                  <Grid
                    key={flatSheet.tempId}
                    item
                    sx={{
                      marginTop: "16px",
                      border: null,
                      background: "inherit",
                      width: "120px",
                    }}
                  >
                    <Typography>FS #{index + 1}</Typography>
                    <Box sx={{ width: "100%" }}>
                      {flatSheet.pieces.map((piece, index) => {
                        return (
                          <Box
                            key={piece.tempId}
                            sx={{
                              borderTop: 1,
                              borderBottom: 1,
                              // borderLeft: 1,
                              // borderLeftStyle: "dotted",
                              // borderRight: 1,
                              // borderRightStyle: "dotted",
                              // The times 6.25 is so that the box will have a height of approx 300px (usually the mc width is 48 inches)
                              // 300px / 48 inches = 6.25px/in
                              height: `${piece.stretchOut * 6.25}px`,
                              minHeight: "25px",
                              width: `100%`,
                              background: "green",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                paddingLeft: "4px",
                                paddingRight: "4px",
                                width: "100%",
                                textAlign: "center",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "1",
                                WebkitBoxOrient: "vertical",
                              }}
                              inline
                            >
                              {piece.stretchOut}" - {piece.name}
                            </Typography>
                          </Box>
                        );
                      })}
                      {drop > 0 && (
                        <Box
                          sx={{
                            borderTop: 1,
                            borderBottom: 1,
                            // borderLeft: 1,
                            // borderLeftStyle: "dotted",
                            // borderRight: 1,
                            // borderRightStyle: "dotted",
                            // The times 6.25 is so that the box will have a height of approx 300px (usually the mc width is 48 inches)
                            // 300px / 48 inches = 6.25px/in
                            height: `${drop * 6.25}px`,
                            minHeight: "25px",
                            width: `100%`,
                            background: "gray",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ fontSize: "12px" }}>
                            {drop}" Drop
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Grid>

            <Button
              sx={{ marginTop: "16px" }}
              onClick={() => {
                reorganizeCutsAndFlatsForShearing();
              }}
            >
              Reorganize Cuts and Generate Shearing Instructions
            </Button>

            {shearInstructions && shearInstructions.length > 0 && (
              <Box>
                <Typography
                  variant="h6"
                  component="div"
                  sx={{ marginTop: "32px" }}
                >
                  Shearing Instructions
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Box>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      {shearInstructions.map((instruction, index) => {
                        return (
                          <Typography key={index} variant="caption">
                            {index + 1}: {instruction}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                  <Box sx={{ flex: 1, marginLeft: "16px" }}>
                    <Grid container spacing={2} sx={{}}>
                      {flatSheetsForShearing.map((flatSheet, index) => {
                        let currentFSUsedWidth = 0;
                        for (let fsPiece of flatSheet.pieces) {
                          currentFSUsedWidth += Number(fsPiece.stretchOut);
                        }
                        let drop = flatSheetSize - currentFSUsedWidth;
                        return (
                          <Grid
                            key={flatSheet.tempId}
                            item
                            sx={{
                              marginTop: "16px",
                              border: null,
                              background: "inherit",
                              // width: "120px",
                            }}
                            xs={6}
                            sm={6}
                            md={6}
                          >
                            <Typography>FS #{index + 1}</Typography>
                            <Box sx={{ width: "100%" }}>
                              {flatSheet.pieces.map((piece, index) => {
                                return (
                                  <Box
                                    key={piece.tempId}
                                    sx={{
                                      borderTop: 1,
                                      borderBottom: 1,
                                      // borderLeft: 1,
                                      // borderLeftStyle: "dotted",
                                      // borderRight: 1,
                                      // borderRightStyle: "dotted",
                                      // The times 6.25 is so that the box will have a height of approx 300px (usually the mc width is 48 inches)
                                      // 300px / 48 inches = 6.25px/in
                                      height: `${piece.stretchOut * 6.25}px`,
                                      minHeight: "25px",
                                      width: "100%",
                                      background: "green",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        paddingLeft: "4px",
                                        paddingRight: "4px",
                                        width: "100%",
                                        textAlign: "center",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        display: "-webkit-box",
                                        WebkitLineClamp: "1",
                                        WebkitBoxOrient: "vertical",
                                      }}
                                      inline
                                    >
                                      {piece.stretchOut}" - {piece.name}
                                    </Typography>
                                  </Box>
                                );
                              })}
                              {drop > 0 && (
                                <Box
                                  sx={{
                                    borderTop: 1,
                                    borderBottom: 1,
                                    // borderLeft: 1,
                                    // borderLeftStyle: "dotted",
                                    // borderRight: 1,
                                    // borderRightStyle: "dotted",
                                    // The times 6.25 is so that the box will have a height of approx 300px (usually the mc width is 48 inches)
                                    // 300px / 48 inches = 6.25px/in
                                    height: `${drop * 6.25}px`,
                                    minHeight: "25px",
                                    width: `100%`,
                                    background: "gray",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Typography sx={{ fontSize: "12px" }}>
                                    {drop}" Drop
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default OptimizedFlats;
