import {
  Box,
  Card,
  Checkbox,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectMaterial from "./SelectMaterial";
import { useTheme } from "@emotion/react";

const MaterialSection = ({ quote, onUpdateQuote }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const onUpdateIsCustomMaterial = (isCustomMaterial) => {
    if (isCustomMaterial) {
      let update = {
        isCustomMaterial: isCustomMaterial,
        manufacturer: null,
        color: null,
        gauge: null,
        flatSheetCost: 0,
        flatSheetCostOriginal: 0,
        coilCostPerSqFt: 0,
        coilCostPerSqFtOriginal: 0,
        flatSheetSize: 48,
      };
      onUpdateQuote(update);
    } else {
      let update = {
        isCustomMaterial: isCustomMaterial,
        customColor: null,
        customGauge: null,
        customManufacturer: null,
        flatSheetCost: 0,
        flatSheetCostOriginal: 0,
        coilCostPerSqFt: 0,
        coilCostPerSqFtOriginal: 0,
        flatSheetSize: 48,
      };
      onUpdateQuote(update);
    }
  };

  const onUpdateIsCustomerSupplied = (isCustomerSupplied) => {
    if (isCustomerSupplied) {
      // If Customer supplied, set all material cost to zero
      let update = {
        isCustomerSupplied: isCustomerSupplied,
        flatSheetCost: 0,
        flatSheetCostOriginal: 0,
        coilCostPerSqFt: 0,
        coilCostPerSqFtOriginal: 0,
      };
      onUpdateQuote(update);
    } else {
      let update = {
        isCustomerSupplied: isCustomerSupplied,
      };
      onUpdateQuote(update);
    }
  };

  if (quote)
    return (
      <Box>
        <Typography variant="h6">Materials</Typography>
        <Box sx={{}}>
          <Box>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
              }}
            >
              <Checkbox
                style={{
                  magin: 0,
                  padding: 0,
                }}
                checked={quote.isCustomMaterial}
                onChange={(e) => {
                  onUpdateIsCustomMaterial(e.target.checked);
                }}
              />
              <Typography>Custom Material</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                magin: 0,
                padding: 0,
                marginRight: "16px",
              }}
            >
              <Checkbox
                style={{
                  magin: 0,
                  padding: 0,
                }}
                checked={quote.isCustomerSupplied}
                onChange={(e) => {
                  onUpdateIsCustomerSupplied(e.target.checked);
                }}
              />
              <Typography>FS and Coil Supplied by Customer</Typography>
            </div>
          </Box>
          {quote.isCustomMaterial ? (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <TextField
                  variant="outlined"
                  label="Custom Color"
                  name="Custom Color"
                  size="small"
                  fullWidth
                  value={quote.customColor ? quote.customColor : ""}
                  onChange={(e) => {
                    let update = {
                      customColor: e.target.value,
                    };
                    onUpdateQuote(update);
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Custom Manufacturer"
                  name="Custom Manufacturer"
                  size="small"
                  fullWidth
                  value={
                    quote.customManufacturer ? quote.customManufacturer : ""
                  }
                  onChange={(e) => {
                    let update = {
                      customManufacturer: e.target.value,
                    };
                    onUpdateQuote(update);
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Custom Gauge"
                  name="Custom Gauge"
                  size="small"
                  fullWidth
                  value={quote.customGauge ? quote.customGauge : ""}
                  onChange={(e) => {
                    let update = {
                      customGauge: e.target.value,
                    };
                    onUpdateQuote(update);
                  }}
                />
                <TextField
                  variant="outlined"
                  label="Custom Material Width"
                  name="Custom Material Width"
                  size="small"
                  fullWidth
                  value={quote.flatSheetSize ? quote.flatSheetSize : ""}
                  onChange={(e) => {
                    let update = {
                      flatSheetSize: e.target.value,
                    };
                    onUpdateQuote(update);
                  }}
                />
              </Box>
            </Box>
          ) : (
            <SelectMaterial quote={quote} onUpdateQuote={onUpdateQuote} />
          )}
          <Box>
            <TextField
              size="small"
              sx={{ marginTop: "16px" }}
              onFocus={(e) => e.target.select()}
              label="FS Price"
              type="number"
              value={`${quote?.flatSheetCost}`}
              onChange={(e) => {
                let update = {
                  flatSheetCost: parseFloat(e.target.value),
                };
                onUpdateQuote(update);
              }}
              symbol="$"
              position="start"
            />

            <TextField
              size="small"
              sx={{ marginTop: "16px" }}
              onFocus={(e) => e.target.select()}
              label="Coil Price Per Sq Ft"
              type="number"
              value={`${quote?.coilCostPerSqFt}`}
              onChange={(e) => {
                let update = {
                  coilCostPerSqFt: parseFloat(e.target.value),
                };
                onUpdateQuote(update);
              }}
              symbol="$"
              position="start"
            />
          </Box>
        </Box>
      </Box>
    );
};

export default MaterialSection;
