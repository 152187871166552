import {
  Box,
  Button,
  Card,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import OptimizedFlats from "../OptimizedFlats";
import AddNewTrimDialog from "./AddNewTrimDialog";
import { v4 as uuidv4 } from "uuid";
import CopingCapCleatPiece from "./CopingCapCleatPiece";
import useGalvanizedFlatOptions from "../../../../../../hooks/useGalvanizedFlatOptions";

const CopingCapCleat = ({
  companyId,
  flatSheetSize,
  defaultVariables,
  copingCapCleat,
  onUpdateCopingCapCleat,
  onUpdateQuote,
  quote,
}) => {
  const { galvanizedFlatOptions } = useGalvanizedFlatOptions();
  const [copingCapCleatorderItem, setCopingCapCleatOrderItem] = useState(null);

  const [addTrimDialogOpen, setAddTrimDialogOpen] = useState(false);

  // TODO: capture quantity and stretchout updates here. Need to reoptimize flats before state update

  const onAddTrim = (newTrim) => {
    let cleatPiecesCopy = [...copingCapCleatorderItem.cleatPieces];
    cleatPiecesCopy.push({
      quantity: 1,
      stretchOut: 1,
      cleat: newTrim,
      hits: 1,
      variables: { ...defaultVariables },
    });

    setCopingCapCleatOrderItem((prev) => ({
      ...prev,
      cleatPieces: cleatPiecesCopy,
    }));
  };

  const onRemovePiece = (index) => {
    let cleatPiecesCopy = [...copingCapCleatorderItem.cleatPieces];
    cleatPiecesCopy.splice(index, 1);

    setCopingCapCleatOrderItem((prev) => ({
      ...prev,
      cleatPieces: cleatPiecesCopy,
    }));
  };

  const onUpdateTrimPiece = (trimPieceIndex, updateObject) => {
    let cleatPiecesCopy = [...copingCapCleatorderItem.cleatPieces];
    cleatPiecesCopy[trimPieceIndex] = {
      ...cleatPiecesCopy[trimPieceIndex],
      ...updateObject,
    };

    setCopingCapCleatOrderItem((prev) => ({
      ...prev,
      cleatPieces: cleatPiecesCopy,
    }));
  };

  const handleGaugeSelect = (gauge, gaugeUnitOfMeasure, galvFlatSheetCost) => {
    // action({
    //   type: "UPDATE CLEAT: GAUGE",
    //   payload: { gauge, gaugeUnitOfMeasure, galvFlatSheetCost },
    // });

    //  Update Quote galvFlatSheetCost
    // Update CopingCapCleat gauge and unit of measure
    let quoteItemsCopy = [...quote.items];
    for (let i = 0; i < quoteItemsCopy.length; i++) {
      let item = quoteItemsCopy[i];
      if (item.objectType == "CopingCapCleat") {
        quoteItemsCopy[i] = {
          ...copingCapCleatorderItem,
          gauge,
          gaugeUnitOfMeasure,
        };
      }
    }
    onUpdateQuote({
      galvFlatSheetCost,
      items: quoteItemsCopy,
    });
  };

  useEffect(() => {
    if (copingCapCleat) {
      setCopingCapCleatOrderItem(copingCapCleat);
    } else {
      setCopingCapCleatOrderItem(null);
    }
  }, [copingCapCleat]);

  useEffect(() => {
    // Initialize price of galv flat sheet
    if (
      copingCapCleat.cleatPieces.length > 0 &&
      galvanizedFlatOptions &&
      galvanizedFlatOptions.length > 0 &&
      copingCapCleat &&
      // materialDetails.galvFlatSheetCost == null // The default value is actually "0.00" and not null
      (copingCapCleat.galvFlatSheetCost == null ||
        parseFloat(copingCapCleat.galvFlatSheetCost) == 0)
    ) {
      let selectedGalvCleatGauge = galvanizedFlatOptions.find(
        (galvFlat) =>
          "" + galvFlat.gauge + galvFlat.unitOfMeasure ==
          "" + copingCapCleat.gauge + copingCapCleat.gaugeUnitOfMeasure
      );
      if (selectedGalvCleatGauge) {
        handleGaugeSelect(
          selectedGalvCleatGauge.gauge,
          selectedGalvCleatGauge.unitOfMeasure,
          selectedGalvCleatGauge.price
        );
      }
    }
  }, [
    copingCapCleat.galvFlatSheetCost,
    galvanizedFlatOptions,
    copingCapCleat.gauge + copingCapCleat.gaugeUnitOfMeasure,
  ]);

  if (!galvanizedFlatOptions || galvanizedFlatOptions.length == 0) return null;

  if (copingCapCleatorderItem)
    return (
      <Box>
        <Typography sx={{ flex: 1 }} variant="h6">
          Coping Cap Cleat
        </Typography>
        {/* TODO: Need to transfer control out of OptimizedFlats unto copingCapCleat */}
        <Box sx={{ marginTop: "8px", display: "flex", flexDirection: "row" }}>
          <TextField
            sx={{ width: 300 }}
            size="small"
            label="Galvanized Flat Sheet"
            select
            value={copingCapCleat.gauge + copingCapCleat.gaugeUnitOfMeasure}
            onChange={(e) => {
              let selectedGalvCleatGauge = galvanizedFlatOptions.find(
                (galvFlat) =>
                  galvFlat.gauge + galvFlat.unitOfMeasure == e.target.value
              );
              handleGaugeSelect(
                selectedGalvCleatGauge.gauge,
                selectedGalvCleatGauge.unitOfMeasure,
                selectedGalvCleatGauge.price
              );
            }}
          >
            {galvanizedFlatOptions?.map((galvFlat, index) => (
              <MenuItem
                key={index}
                value={galvFlat.gauge + galvFlat.unitOfMeasure}
              >
                {galvFlat.gauge} {galvFlat.unitOfMeasure} - ${galvFlat.price}
              </MenuItem>
            ))}
          </TextField>
          <OptimizedFlats
            flatSheetSize={flatSheetSize}
            pieces={copingCapCleatorderItem.cleatPieces}
            onOptimize={(totalFlats, totalInchesDrop) => {
              console.log("OnOptimize", totalFlats, totalInchesDrop);
              onUpdateCopingCapCleat({
                ...copingCapCleatorderItem,
                totalFlats,
                totalInchesDrop,
              });
            }}
          />
        </Box>

        <Box>
          {copingCapCleatorderItem &&
            copingCapCleatorderItem.cleatPieces &&
            copingCapCleatorderItem.cleatPieces.map((piece, index) => {
              return (
                <CopingCapCleatPiece
                  key={piece.id ?? uuidv4()}
                  piece={piece}
                  onUpdatePiece={(updateObject) => {
                    onUpdateTrimPiece(index, updateObject);
                  }}
                  onRemovePiece={() => {
                    onRemovePiece(index);
                  }}
                />
              );
            })}
        </Box>
        <Box>
          <Button
            onClick={() => {
              setAddTrimDialogOpen(true);
            }}
          >
            + Add New
          </Button>
        </Box>
        <AddNewTrimDialog
          companyId={companyId}
          trimType="CopingCapCleat"
          open={addTrimDialogOpen}
          handleClose={() => {
            setAddTrimDialogOpen(false);
          }}
          handleSubmit={(newTrim) => {
            onAddTrim(newTrim);
          }}
        />
      </Box>
    );
};

export default CopingCapCleat;
