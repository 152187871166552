import { Box, Divider, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import TopSection from "./TopSection";
import MaterialSection from "./MaterialSection";
import SelectProducts from "./SelectProducts";
import Items from "./Items/Items";
import useVariables from "../../../../../hooks/useVariables";
import {
  toFourDecimals,
  toThreeDecimals,
  toTwoDecimals,
} from "../../../../../components/NewDetail/calculations/utils";
import { useTheme } from "@emotion/react";

const EMPTY_QUOTE = {};

// quoteId: null | number
// onSave: function
const QuoteForm = ({ quoteId, onSave }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { ezorder, authToken } = useContext(AuthContext);
  const [quote, setQuote] = useState(null);
  const { variables, getDefaultVariables } = useVariables();
  const [loading, setLoading] = useState();

  const onUpdateQuote = (update) => {
    let quoteCopy = { ...quote, ...update };
    console.log("QUOTE UPDATE PRE-CALCULATION", quoteCopy);
    let calculatedItems = addCalculations(quoteCopy);
    setQuote({ ...quoteCopy, items: calculatedItems });
    // setQuote((prev) => ({ ...prev, ...update }));
  };

  const addCalculations = (quoteCopy) => {
    let quoteItems = quoteCopy.items ?? [];
    let itemsCopy = [...quoteItems];
    for (let i = 0; i < itemsCopy.length; i++) {
      let item = itemsCopy[i];
      switch (item.objectType) {
        case "FlatSheet":
          itemsCopy[i] = calculateFlatSheet(quoteCopy, item);
          break;
        case "Coil":
          itemsCopy[i] = calculateCoil(quoteCopy, item);
          break;
        case "Panel":
          itemsCopy[i] = calculatePanel(quoteCopy, item);
          break;
        case "TrimAndFlashing":
          itemsCopy[i] = calculateTrim(quoteCopy, item);
          break;
        case "CopingCap":
          itemsCopy[i] = calculateCopingCap(quoteCopy, item);
          break;
        case "CopingCapCleat":
          itemsCopy[i] = calculateCopingCapCleat(quoteCopy, item);
          break;
        case "SplicePlate":
          break;
        case "BenchWork":
          break;
        case "Accessory":
          break;
      }
    }
    return itemsCopy;
  };

  // The flat sheet item sheet pricing is separate from the order flat sheet pricing
  const calculateFlatSheet = (quoteCopy, flatSheet) => {
    let flatSheetCopy = { ...flatSheet };
    const GROSS_PROFIT_MARGIN = flatSheetCopy.variables.gross_profit_margin;
    console.log("calculateFlatSheet", flatSheetCopy);
    let FLAT_SHEET_COST = 0;
    if (
      flatSheetCopy.flatSheetCalculations.costPerFlatSheet != undefined &&
      flatSheetCopy.flatSheetCalculations.costPerFlatSheet != null &&
      !isNaN(flatSheetCopy.flatSheetCalculations.costPerFlatSheet)
    ) {
      FLAT_SHEET_COST = toTwoDecimals(
        flatSheetCopy.flatSheetCalculations.costPerFlatSheet
      );
    } else {
      FLAT_SHEET_COST = 0;
    }

    const MATERIAL_COST = toTwoDecimals(
      parseFloat(flatSheetCopy.quantity) * FLAT_SHEET_COST
    );
    const GROSS_PROFIT_DOLLARS =
      MATERIAL_COST / (1 - GROSS_PROFIT_MARGIN) - MATERIAL_COST;
    const TOTAL_SELL_PRICE = MATERIAL_COST + GROSS_PROFIT_DOLLARS;
    const PRICE_PER_FLAT_SHEET = toTwoDecimals(
      TOTAL_SELL_PRICE / flatSheetCopy.quantity
    );

    let flatSheetCalculations = {
      costPerFlatSheet: FLAT_SHEET_COST,
      materialCost: MATERIAL_COST,
      grossProfitMargin: toFourDecimals(GROSS_PROFIT_MARGIN),
      grossProfitDollars: toTwoDecimals(GROSS_PROFIT_DOLLARS),
      totalSellPrice: toTwoDecimals(TOTAL_SELL_PRICE),
      pricePerFlatSheet: PRICE_PER_FLAT_SHEET,
    };
    flatSheetCopy.flatSheetCalculations = flatSheetCalculations;
    return { ...flatSheetCopy, flatSheetCalculations };
  };

  const calculateCoil = (quoteCopy, coil) => {
    let coilCopy = { ...coil };
    const GROSS_PROFIT_MARGIN = coilCopy.variables.gross_profit_margin;
    console.log("calculateCoil", coilCopy);

    // Coil Cost Based On Color
    let COIL_COST_PER_SQFT =
      quoteCopy.coilCostPerSqFt != quoteCopy.coilCostPerSqFtOriginal
        ? parseFloat(quoteCopy.coilCostPerSqFt)
        : parseFloat(quoteCopy.coilCostPerSqFtOriginal);
    if (!COIL_COST_PER_SQFT) COIL_COST_PER_SQFT = 0;

    const COST_PER_SQUARE_FEET_OF_COIL = toTwoDecimals(COIL_COST_PER_SQFT); // non-override value

    // (width (in.) / 12) * lineal feet
    const SQUARE_FEET_OF_COIL_NEEDED = toThreeDecimals(
      (parseFloat(coilCopy.width) / 12) * parseFloat(coilCopy.linealFeet)
    );

    // Cost = Square Feet of Coil * Price Per Square Feet of Coil
    const MATERIAL_COST = toTwoDecimals(
      parseFloat(COST_PER_SQUARE_FEET_OF_COIL) *
        parseFloat(SQUARE_FEET_OF_COIL_NEEDED)
    );

    const GROSS_PROFIT_DOLLARS =
      MATERIAL_COST / (1 - GROSS_PROFIT_MARGIN) - MATERIAL_COST;
    const TOTAL_SELL_PRICE = MATERIAL_COST + GROSS_PROFIT_DOLLARS;

    const PRICE_PER_SQUARE_FEET_OF_COIL = toTwoDecimals(
      TOTAL_SELL_PRICE / SQUARE_FEET_OF_COIL_NEEDED
    );

    const PRICE_PER_LINEAL_FOOT_OF_COIL = toTwoDecimals(
      TOTAL_SELL_PRICE / parseFloat(coilCopy.linealFeet)
    );

    let coilCalculations = {
      costPerSquareFeetOfCoil: COST_PER_SQUARE_FEET_OF_COIL,
      squareFeetOfCoil: SQUARE_FEET_OF_COIL_NEEDED,
      materialCost: MATERIAL_COST,
      grossProfitMargin: toFourDecimals(GROSS_PROFIT_MARGIN),
      grossProfitDollars: toTwoDecimals(GROSS_PROFIT_DOLLARS),
      totalSellPrice: toTwoDecimals(TOTAL_SELL_PRICE),
      pricePerSquareFeetOfCoil: PRICE_PER_SQUARE_FEET_OF_COIL,
      pricePerLinealFootOfCoil: PRICE_PER_LINEAL_FOOT_OF_COIL,
    };
    coilCopy.coilCalculations = coilCalculations;
    return { ...coilCopy, coilCalculations };
  };

  const calculatePanel = (quoteCopy, panel) => {
    let panelCopy = { ...panel };
    console.log("calculatePanel", panelCopy);

    const WASTE = panelCopy.variables.waste;
    const PANEL_FAB_RATE_LF_PER_HOUR = panelCopy.variables.panel_run_rate;
    const HOURLY_LABOR_RATE = panelCopy.variables.hourly_labor_cost;
    const LUMBER_RATE = panelCopy.variables.lumber_and_crating_rate;
    const GROSS_PROFIT_MARGIN = panelCopy.variables.gross_profit_margin;

    let COIL_COST_PER_SQFT =
      quoteCopy.coilCostPerSqFt != quoteCopy.coilCostPerSqFtOriginal
        ? parseFloat(quoteCopy.coilCostPerSqFt)
        : parseFloat(quoteCopy.coilCostPerSqFtOriginal);
    if (!COIL_COST_PER_SQFT) COIL_COST_PER_SQFT = 0;

    const COIL_SIZE_NEEDED =
      Number(panel.panelWidth ?? 0) + Number(panel.panelTakeUp ?? 0); //inches

    //this is auto-calc'd BUT if it's 40" USER CAN OVERIDE TO 48 IN CASE 40" IS NOT IN-STOCK, BUT IF IT'S 48 YOU CANNOT OVERRIDE
    let masterCoilSizeNeededTemp = 40;
    if (COIL_SIZE_NEEDED <= 16 || COIL_SIZE_NEEDED > 20)
      masterCoilSizeNeededTemp = 48;
    const MASTER_COIL_SIZE_NEEDED = masterCoilSizeNeededTemp;

    const COILS_CUT_FROM_MASTER_COIL = Math.floor(
      MASTER_COIL_SIZE_NEEDED / COIL_SIZE_NEEDED
    );

    const COIL_DROP =
      MASTER_COIL_SIZE_NEEDED - COIL_SIZE_NEEDED * COILS_CUT_FROM_MASTER_COIL;

    const getLF = (qty, feet, inches) =>
      parseFloat(qty) * (parseFloat(feet) + parseFloat(inches) / 12);

    const LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE = panel.cuts.reduce(
      (acc, cut) => (acc += getLF(cut.quantity, cut.feet, cut.inches)),
      0
    );

    const LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE =
      LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE +
      LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE * WASTE;

    const SQFT_OF_COIL_NEEDED_INCLUDING_WASTE =
      LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE * (COIL_SIZE_NEEDED / 12);

    const FINISHED_PANEL_SQFT =
      LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE *
      (parseFloat(panel.panelWidth) / 12);

    const MATERIAL_COST =
      COIL_COST_PER_SQFT * SQFT_OF_COIL_NEEDED_INCLUDING_WASTE;

    // Wrong the panel run rate is LF per hour not per minute
    // const TOTAL_ESTIMATED_LABOR_HOURS = $(
    //   "total_estimated_labor_hours",
    //   Math.ceil(
    //     LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE /
    //       PANEL_FAB_RATE_LF_PER_MINUTE /
    //       60
    //   )
    // );

    const TOTAL_ESTIMATED_LABOR_HOURS = Math.ceil(
      LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE / PANEL_FAB_RATE_LF_PER_HOUR
    );

    const ESTIMATED_LABOR_COST =
      TOTAL_ESTIMATED_LABOR_HOURS * HOURLY_LABOR_RATE;

    const ESTIMATED_LUMBER_COST =
      (MATERIAL_COST + ESTIMATED_LABOR_COST) * LUMBER_RATE;

    const TOTAL_HARD_COST =
      MATERIAL_COST + ESTIMATED_LABOR_COST + ESTIMATED_LUMBER_COST;

    const GROSS_PROFIT_DOLLARS =
      TOTAL_HARD_COST / (1 - GROSS_PROFIT_MARGIN) - TOTAL_HARD_COST;

    const TOTAL_SELL_PRICE = TOTAL_HARD_COST + GROSS_PROFIT_DOLLARS;

    const PANEL_PRICE_PER_SQFT = TOTAL_SELL_PRICE / FINISHED_PANEL_SQFT;

    let calculations = {
      waste: toThreeDecimals(WASTE),
      coilSizeNeeded: COIL_SIZE_NEEDED,
      masterCoilSizeNeeded: MASTER_COIL_SIZE_NEEDED,
      coilsCutFromMaster: COILS_CUT_FROM_MASTER_COIL,
      coilDrop: COIL_DROP,
      linealFeetOfCoilNoWaste: toThreeDecimals(
        LINEAL_FEET_OF_COIL_NEEDED_NOT_INCLUDING_WASTE
      ),
      linealFeetOfCoilWithWaste: toThreeDecimals(
        LINEAL_FEET_OF_COIL_NEEDED_INCLUDING_WASTE
      ),
      squareFeetOfCoilNeededWithWaste: toThreeDecimals(
        SQFT_OF_COIL_NEEDED_INCLUDING_WASTE
      ),
      finishedPanelSquareFeet: toThreeDecimals(FINISHED_PANEL_SQFT),
      materialCost: toTwoDecimals(MATERIAL_COST),
      estimatedLaborHours: toTwoDecimals(TOTAL_ESTIMATED_LABOR_HOURS),
      estimatedLaborCost: toTwoDecimals(ESTIMATED_LABOR_COST),
      estimatedLumberCost: toTwoDecimals(ESTIMATED_LUMBER_COST),
      totalHardCost: toTwoDecimals(TOTAL_HARD_COST),
      grossProfitMargin: toFourDecimals(GROSS_PROFIT_MARGIN),
      grossProfitDollars: toTwoDecimals(GROSS_PROFIT_DOLLARS),
      totalSellPrice: toTwoDecimals(TOTAL_SELL_PRICE),
      panelPricePerSquareFoot: toTwoDecimals(PANEL_PRICE_PER_SQFT),
    };

    return { ...panelCopy, calculations };
  };

  const calculateTrim = (quoteCopy, t) => {
    let trim = { ...t };
    if (trim.totalFlats) {
      // trim.totalFlats (from optimizer)
      // TODO: trim.totalInchesDrop (from optimizer)
      // Calculate Total FlatSheet Length Used Including Drop
      let flatSheetCost = Number(quoteCopy.flatSheetCost);
      let flatSheetSize = Number(quoteCopy.flatSheetSize);
      let totalInchesFlatSheets = Number(trim.totalFlats) * flatSheetSize;
      let totalFsLengthUsedExcludingDrop =
        totalInchesFlatSheets - Number(trim.totalInchesDrop);

      for (let i = 0; i < trim.trimPieces.length; i++) {
        let piece = trim.trimPieces[i];

        // Instead of override, check if calculatons object variable is undefined.
        // Use default variable value if does not exist, use calc value if exists
        let grossProfitMargin =
          piece.calculations && piece.calculations.grossProfitMargin
            ? piece.calculations.grossProfitMargin
            : piece.variables.gross_profit_margin;

        // TODO: Calculate total FS length used for trim piece
        let totalPieceLength =
          Number(piece.quantity) * Number(piece.stretchOut);
        let usageRatio = totalPieceLength / totalFsLengthUsedExcludingDrop;
        let usageAdjustedForDrops = totalInchesFlatSheets * usageRatio;
        let usedFlats = usageAdjustedForDrops / flatSheetSize;
        let materialCost = usedFlats * flatSheetCost;
        // TODO: Calculate Material Cost
        // TODO: Calculate Est. Labor Hrs. (hits and rate)
        let estLaborHours = parseFloat(
          Math.ceil(
            (Number(piece.quantity) * Number(piece.hits)) /
              Number(piece.variables.hits_per_minute) /
              60
          )
        );
        let estLaborCost = parseFloat(
          estLaborHours * Number(piece.variables.hourly_labor_cost)
        );
        // TODO: Calculate Labor Cost
        // TODO: Calculate Labor & Material
        let materialAndLabor = materialCost + estLaborCost;

        const totalSellPrice = parseFloat(
          materialAndLabor / (1 - grossProfitMargin)
        );

        const grossProfitDollars = parseFloat(
          totalSellPrice - materialAndLabor
        );

        const pricePerPiece = parseFloat(
          totalSellPrice / (piece.quantity ?? 1)
        );

        let calculations = {
          flatSheetSize: toThreeDecimals(flatSheetSize),
          flatSheetCost: toTwoDecimals(flatSheetCost),
          // piecesYieldedPerFlatSheet: toThreeDecimals(PIECES_YIELDED_PER_FLAT_SHEET),
          flatSheetsNeeded: toThreeDecimals(usedFlats),
          // inchesOfDropPerFlatSheet: toThreeDecimals(INCHES_OF_DROP_PER_FLAT),
          // numberOfDrops: toThreeDecimals(NUMBER_OF_DROPS),
          estimatedLaborHours: toTwoDecimals(estLaborHours),
          estimatedLaborCost: toTwoDecimals(estLaborCost),
          materialCost: toTwoDecimals(materialCost),
          materialAndLabor: toTwoDecimals(materialAndLabor),
          grossProfitMargin: toFourDecimals(grossProfitMargin),
          grossProfitDollars: toTwoDecimals(grossProfitDollars),
          totalSellPrice: toTwoDecimals(totalSellPrice),
          pricePerPiece: toTwoDecimals(pricePerPiece),
        };
        trim.trimPieces[i].calculations = calculations;
      }
    }
    return trim;
  };

  const calculateCopingCap = (quoteCopy, c) => {
    let copingCap = { ...c };
    if (copingCap.totalFlats) {
      // copingCap.totalFlats (from optimizer)
      // TODO: copingCap.totalInchesDrop (from optimizer)
      // Calculate Total FlatSheet Length Used Including Drop
      let flatSheetCost = Number(quoteCopy.flatSheetCost);
      let flatSheetSize = Number(quoteCopy.flatSheetSize);
      let totalInchesFlatSheets = Number(copingCap.totalFlats) * flatSheetSize;
      let totalFsLengthUsedExcludingDrop =
        totalInchesFlatSheets - Number(copingCap.totalInchesDrop);

      for (let i = 0; i < copingCap.capPieces.length; i++) {
        let piece = copingCap.capPieces[i];
        // Instead of override, check if calculatons object variable is undefined.
        // Use default variable value if does not exist, use calc value if exists
        let grossProfitMargin =
          piece.calculations && piece.calculations.grossProfitMargin
            ? piece.calculations.grossProfitMargin
            : piece.variables.gross_profit_margin;
        // TODO: Calculate total FS length used for copingCap piece
        let totalPieceLength =
          Number(piece.quantity) * Number(piece.stretchOut);
        let usageRatio = totalPieceLength / totalFsLengthUsedExcludingDrop;
        let usageAdjustedForDrops = totalInchesFlatSheets * usageRatio;
        let usedFlats = usageAdjustedForDrops / flatSheetSize;
        let materialCost = usedFlats * flatSheetCost;
        // TODO: Calculate Material Cost
        // TODO: Calculate Est. Labor Hrs. (hits and rate)
        let estLaborHours = parseFloat(
          Math.ceil(
            (Number(piece.quantity) * Number(piece.hits)) /
              Number(piece.variables.hits_per_minute) /
              60
          )
        );
        let estLaborCost = parseFloat(
          estLaborHours * Number(piece.variables.hourly_labor_cost)
        );
        // TODO: Calculate Labor Cost
        // TODO: Calculate Labor & Material
        let materialAndLabor = materialCost + estLaborCost;

        const totalSellPrice = parseFloat(
          materialAndLabor / (1 - grossProfitMargin)
        );

        const grossProfitDollars = parseFloat(
          totalSellPrice - materialAndLabor
        );

        const pricePerPiece = parseFloat(
          totalSellPrice / (piece.quantity ?? 1)
        );

        let calculations = {
          flatSheetSize: toThreeDecimals(flatSheetSize),
          flatSheetCost: toTwoDecimals(flatSheetCost),
          // piecesYieldedPerFlatSheet: toThreeDecimals(PIECES_YIELDED_PER_FLAT_SHEET),
          flatSheetsNeeded: toThreeDecimals(usedFlats),
          // inchesOfDropPerFlatSheet: toThreeDecimals(INCHES_OF_DROP_PER_FLAT),
          // numberOfDrops: toThreeDecimals(NUMBER_OF_DROPS),
          estimatedLaborHours: toTwoDecimals(estLaborHours),
          estimatedLaborCost: toTwoDecimals(estLaborCost),
          materialCost: toTwoDecimals(materialCost),
          materialAndLabor: toTwoDecimals(materialAndLabor),
          grossProfitMargin: toFourDecimals(grossProfitMargin),
          grossProfitDollars: toTwoDecimals(grossProfitDollars),
          totalSellPrice: toTwoDecimals(totalSellPrice),
          pricePerPiece: toTwoDecimals(pricePerPiece),
        };
        copingCap.capPieces[i].calculations = calculations;
      }
    }
    return copingCap;
  };

  const calculateCopingCapCleat = (quoteCopy, c) => {
    let copingCapCleat = { ...c };
    if (copingCapCleat.totalFlats) {
      // copingCapCleat.totalFlats (from optimizer)
      // TODO: copingCapCleat.totalInchesDrop (from optimizer)
      // Calculate Total FlatSheet Length Used Including Drop
      let flatSheetCost = Number(quoteCopy.galvFlatSheetCost);
      let flatSheetSize = Number(quoteCopy.flatSheetSize);
      let totalInchesFlatSheets =
        Number(copingCapCleat.totalFlats) * flatSheetSize;
      let totalFsLengthUsedExcludingDrop =
        totalInchesFlatSheets - Number(copingCapCleat.totalInchesDrop);

      for (let i = 0; i < copingCapCleat.cleatPieces.length; i++) {
        let piece = copingCapCleat.cleatPieces[i];

        // Instead of override, check if calculatons object variable is undefined.
        // Use default variable value if does not exist, use calc value if exists
        let grossProfitMargin =
          piece.calculations && piece.calculations.grossProfitMargin
            ? piece.calculations.grossProfitMargin
            : piece.variables.gross_profit_margin;
        // TODO: Calculate total FS length used for copingCapCleat piece
        let totalPieceLength =
          Number(piece.quantity) * Number(piece.stretchOut);
        let usageRatio = totalPieceLength / totalFsLengthUsedExcludingDrop;
        let usageAdjustedForDrops = totalInchesFlatSheets * usageRatio;
        let usedFlats = usageAdjustedForDrops / flatSheetSize;
        let materialCost = usedFlats * flatSheetCost;
        // TODO: Calculate Material Cost
        // TODO: Calculate Est. Labor Hrs. (hits and rate)
        let estLaborHours = parseFloat(
          Math.ceil(
            (Number(piece.quantity) * Number(piece.hits)) /
              Number(piece.variables.hits_per_minute) /
              60
          )
        );
        let estLaborCost = parseFloat(
          estLaborHours * Number(piece.variables.hourly_labor_cost)
        );
        // TODO: Calculate Labor Cost
        // TODO: Calculate Labor & Material
        let materialAndLabor = materialCost + estLaborCost;

        const totalSellPrice = parseFloat(
          materialAndLabor / (1 - grossProfitMargin)
        );

        const grossProfitDollars = parseFloat(
          totalSellPrice - materialAndLabor
        );

        const pricePerPiece = parseFloat(
          totalSellPrice / (piece.quantity ?? 1)
        );

        let calculations = {
          flatSheetSize: toThreeDecimals(flatSheetSize),
          flatSheetCost: toTwoDecimals(flatSheetCost),
          // piecesYieldedPerFlatSheet: toThreeDecimals(PIECES_YIELDED_PER_FLAT_SHEET),
          flatSheetsNeeded: toThreeDecimals(usedFlats),
          // inchesOfDropPerFlatSheet: toThreeDecimals(INCHES_OF_DROP_PER_FLAT),
          // numberOfDrops: toThreeDecimals(NUMBER_OF_DROPS),
          estimatedLaborHours: toTwoDecimals(estLaborHours),
          estimatedLaborCost: toTwoDecimals(estLaborCost),
          materialCost: toTwoDecimals(materialCost),
          materialAndLabor: toTwoDecimals(materialAndLabor),
          grossProfitMargin: toFourDecimals(grossProfitMargin),
          grossProfitDollars: toTwoDecimals(grossProfitDollars),
          totalSellPrice: toTwoDecimals(totalSellPrice),
          pricePerPiece: toTwoDecimals(pricePerPiece),
        };
        copingCapCleat.cleatPieces[i].calculations = calculations;
      }
    }
    return copingCapCleat;
  };

  const authHeader = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };

  const getQuoteById = async (id) => {
    try {
      setLoading(true);
      const response = await ezorder.get(`/admin/orders/${id}`, authHeader);
      if (response.status === 200 || response.status == 201) {
        setQuote(response.data.order);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log("Quote Updated", quote);
  }, [quote]);

  useEffect(() => {
    if (quoteId) {
      // quoteId is not null. Load the order and set it as quote detail
      getQuoteById(quoteId);
    } else {
      // no quoteId passed in. Set quote as empty object
      setQuote(EMPTY_QUOTE);
    }
  }, [quoteId]);

  return (
    <Box>
      {/* 
        Top Section (Project needs to be selected before everything else)
        - Customer, Project, SubProject, PO Number 
        - Sales Rep, Primary Contact (requester and approver? I think these two are always the same)
        - When does the customer need this by...
        - Is this a final cut-list? I think we were getting rid of this
      */}
      <TopSection quote={quote} onUpdateQuote={onUpdateQuote} />
      <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
      {quote &&
        quote.project &&
        quote.company &&
        quote.estimateRequestedBy &&
        variables && (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMediumScreen ? "column" : "row",
              }}
            >
              <Box sx={{ flex: 1, maxWidth: "200px" }}>
                <SelectProducts
                  quote={quote}
                  onUpdateQuote={onUpdateQuote}
                  defaultVariables={variables}
                />
              </Box>
              {isMediumScreen ? (
                <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
              ) : (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ marginLeft: "16px", marginRight: "32px" }}
                />
              )}

              <Box sx={{ flex: 1 }}>
                <MaterialSection quote={quote} onUpdateQuote={onUpdateQuote} />
              </Box>
            </Box>

            <Items
              quote={quote}
              onUpdateQuote={onUpdateQuote}
              defaultVariables={variables}
            />
          </Box>
        )}
    </Box>
  );
};

export default QuoteForm;
