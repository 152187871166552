import {
  Box,
  Card,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React, { useCallback, useContext, useEffect, useState } from "react";
import CalendarToolBar from "./CalendarToolBar";
import { useTheme } from "@emotion/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext } from "../../../../context/AuthContext";
import QueryString from "query-string";
import { Add } from "@mui/icons-material";
import ShopCalendarEvent from "./ShopCalendarEvent";
import ScheduleButton from "./ScheduleButton";
import ScheduleJobDialog from "./ScheduleJobDialog";
import "./ShopCalendar.css";
import CustomWeekView from "./CustomWeekView";
import CustomMonthView from "./CustomMonthView";
import EditJobDialog from "./EditJobDialog";
import JobDialog from "./JobDialog";
import useSchedule from "../../../../hooks/useSchedule";
import DeleteJobDialog from "./DeleteJobDialog";

moment.tz.setDefault("America/Phoenix");
const DEFAULT_SCHEDULE_JOB_DIALOG_DATA = {
  open: false,
  date: null,
  calendarEvent: null,
};

const DEFAULT_EDIT_JOB_DIALOG_DATA = {
  open: false,
  date: null,
  calendarEvent: null,
};

const DEFAULT_DELETE_JOB_DIALOG_DATA = {
  open: false,
  date: null,
  calendarEvent: null,
};

const DEFAULT_JOB_DIALOG_DATA = {
  open: false,
  date: null,
  calendarEvent: null,
};

const ShopCalendar = (props) => {
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { scheduleEvent, updateEvent, deleteEvent } = useSchedule();
  const dateParam = searchParams.get("date");
  const viewParam = searchParams.get("view");
  const includeAllparam = searchParams.get("includeAll");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [localizer, setLocalizer] = useState(null);

  const [date, setDate] = useState();
  const [view, setView] = useState();

  //   const { calendarEvents, getJobSchedule, loading } = useJobSchedule();
  const [calendarEvents, setCalendarEvents] = useState([]);

  const [scheduleJobDialogData, setScheduleJobDialogData] = useState(
    DEFAULT_SCHEDULE_JOB_DIALOG_DATA
  );

  const [editJobDialogData, setEditJobDialogData] = useState(
    DEFAULT_EDIT_JOB_DIALOG_DATA
  );

  const [deleteJobDialogData, setDeleteJobDialogData] = useState(
    DEFAULT_DELETE_JOB_DIALOG_DATA
  );

  const [jobDialogData, setJobDialogData] = useState(DEFAULT_JOB_DIALOG_DATA);

  const [maxCellHeight, setMaxCellHeight] = useState();

  const handleOnChangeView = (view) => {
    navigate(
      `/shop-schedule?date=${
        dateParam
          ? dateParam
          : moment().tz("America/Phoenix").startOf("d").format("YYYY-MM-DD")
      }&view=${view}&includeAll=${includeAllparam == "true"}`
    );
  };
  const handleAssignedToMe = (assignedToMe) => {
    navigate(
      `/shop-schedule?date=${
        dateParam
          ? dateParam
          : moment().tz("America/Phoenix").startOf("d").format("YYYY-MM-DD")
      }&view=${view}&includeAll=${!assignedToMe}`
    );
  };
  const onNavigate = useCallback(
    (newDate) => {
      let dateString = moment(newDate)
        .tz("America/Phoenix")
        .startOf("d")
        .format("YYYY-MM-DD");
      console.log("ON NAVIGATE", dateString);
      navigate(
        `/shop-schedule?date=${dateString}&view=${
          viewParam ? viewParam : isSmallScreen ? Views.DAY : Views.WEEK
        }&includeAll=${includeAllparam == "true"}`
      );
    },
    [viewParam, includeAllparam, navigate]
  );

  const getMonth = (month) => {
    // Day Of month is 1-30?
    const firstDateOfTheMonth = moment(month)
      .tz("America/Phoenix")
      .startOf("month");
    const dayOfWeek = moment(month)
      .tz("America/Phoenix")
      .startOf("month")
      .day();

    // Subtract day of week (1-7) set the first calendar day as the sunday before the first
    let firstCalendarDate = moment(firstDateOfTheMonth)
      .tz("America/Phoenix")
      .subtract(dayOfWeek, "day");

    let dayCount = 0;
    const daysMatrix = new Array(6).fill([]).map(() => {
      return new Array(7).fill(null).map(() => {
        let day = moment(firstCalendarDate)
          .tz("America/Phoenix")
          .add(dayCount, "day");
        dayCount++;
        return day;
      });
    });

    let firstDayOnCalendar = moment(daysMatrix[0][0])
      .tz("America/Phoenix")
      .subtract(15, "d")
      .format("YYYY-MM-DD");
    let lastDayOnCalendar = moment(daysMatrix[5][6])
      .tz("America/Phoenix")
      .add(15, "d")
      .format("YYYY-MM-DD");

    // TODO: get jobs
    let includeAllOrders =
      decoded.role == "EZORDER_ADMIN"
        ? true
        : // SHOP EMPLOYEE
        includeAllparam == "true"
        ? true
        : false;
    getJobs(firstDayOnCalendar, lastDayOnCalendar, includeAllOrders);
    return daysMatrix;
  };

  const getJobs = async (firstDay, lastDay, includeAll) => {
    try {
      let queryParams = {
        firstDay,
        lastDay,
        includeAll,
      };
      // console.log(queryParams);
      const response = await ezorder.get(
        `/shop/schedule?${QueryString.stringify(queryParams, {
          arrayFormat: "comma",
        })}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (response.status == 200) {
        setCalendarEvents(response.data.calendarEvents);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const handleScheduleEvent = async (
    jobId,
    assignedTo,
    subPhases,
    startDate,
    endDate
  ) => {
    try {
      const response = await scheduleEvent(
        jobId,
        assignedTo,
        subPhases,
        startDate,
        endDate
      );
      setScheduleJobDialogData(DEFAULT_SCHEDULE_JOB_DIALOG_DATA);
      // setDate(startDate);
      getMonth(date);
    } catch (error) {
    } finally {
    }
  };

  const handleUpdateEvent = async (
    eventId,
    assignedTo,
    subPhases,
    startDate,
    endDate
  ) => {
    try {
      const response = await updateEvent(
        eventId,
        assignedTo,
        subPhases,
        startDate,
        endDate
      );
      setEditJobDialogData(DEFAULT_EDIT_JOB_DIALOG_DATA);
      getMonth(date);
    } catch (error) {
    } finally {
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await deleteEvent(eventId);
      setDeleteJobDialogData(DEFAULT_DELETE_JOB_DIALOG_DATA);
      getMonth(date);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    if (view == Views.WEEK) {
      moment.locale("en", {
        week: {
          // dow: 0, //Sunday is the first day of the week.
          dow: 1, //Monday is the first day of the week.
        },
      });
      setLocalizer(momentLocalizer(moment));
    } else {
      moment.locale("en", {
        week: {
          dow: 0, //Sunday is the first day of the week.
          // dow: 1, //Monday is the first day of the week.
        },
      });
      setLocalizer(momentLocalizer(moment));
    }
  }, [view]);

  useEffect(() => {
    if (date) {
      getMonth(date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, includeAllparam]);

  useEffect(() => {
    if (dateParam)
      setDate(moment(dateParam).tz("America/Phoenix").startOf("d").toDate());
    else setDate(new Date());
  }, [dateParam]);

  useEffect(() => {
    if (viewParam) setView(viewParam);
    else {
      setView(
        isSmallScreen
          ? Views.DAY
          : decoded.role === "EZORDER_ADMIN"
          ? Views.MONTH
          : Views.WEEK
      );
    }
  }, [viewParam]);

  if (date && view && calendarEvents)
    return (
      <Box sx={{ flex: 1 }}>
        <Card style={{ border: "none", boxShadow: "none" }}>
          <CalendarToolBar
            view={view}
            date={date}
            assignedToMe={includeAllparam != "true"}
            isAdmin={decoded.role == "EZORDER_ADMIN" ? true : false}
            onNavigate={onNavigate}
            handleOnChangeView={handleOnChangeView}
            handleAssignedToMe={handleAssignedToMe}
          />
          {localizer && (
            <Calendar
              events={calendarEvents}
              toolbar={false}
              // <DnDCalendar
              date={date}
              selectable
              onSelectSlot={(slotInfo) => {}}
              // longPressThreshold={0} // React Calendar does not support touch events for mobile currently.
              // So make this longpress as short as possible to trigger onSelectSlot on mobile
              onSelectEvent={(calendarEvent) => {}}
              onNavigate={onNavigate}
              // toolbar={false}
              view={view}
              defaultView={Views.MONTH}
              // views={["month", "week", "day"]}
              views={{
                day: true,
                // week: isSmallScreen ? CustomWeekView : true,
                week: CustomWeekView,
                month: true,
                linear_month: CustomMonthView,
                two_weeks: CustomWeekView,
              }}
              onView={handleOnChangeView}
              startAccessor={(event) => {
                // https://stackoverflow.com/questions/57287782/typeerror-dateget-method-is-not-a-function-in-react-big-calendar
                return new Date(event.startDate);
              }}
              endAccessor={(event) => {
                // https://stackoverflow.com/questions/57287782/typeerror-dateget-method-is-not-a-function-in-react-big-calendar
                return new Date(event.endDate);
              }}
              allDayAccessor={(event) => {
                return true;
              }}
              // resizableAccessor={(event) => !isSmallScreen}
              // draggableAccessor={(event) => !isSmallScreen}

              localizer={localizer}
              style={{
                minHeight: view == Views.MONTH ? "100vh" : "inherit", // IMPORTANT: this needs to be equal to .rbc-time-header height in global.css
              }}
              // eventPropGetter={(event) => {
              //   // Style Event
              //   const backgroundColor = getEventColor(event.isHardScheduled);
              //   return { style: { backgroundColor } };
              // }}
              // popup={true}
              showAllEvents={true}
              components={{
                eventWrapper: (props) => {
                  return (
                    <div
                      style={{
                        marginBottom: "8px",
                      }}
                    >
                      {props.children}
                    </div>
                  );
                },
                month: {
                  dateHeader: ({ date, label }) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          {/* <IconButton
                            onClick={() => {
                              // setScheduleJobDialogData({
                              //   open: true,
                              //   startDate: date,
                              // });
                            }}
                            color="primary"
                          >
                            <Add
                              sx={{
                                fontSize: isSmallScreen ? "16px" : "20px",
                              }}
                            />
                          </IconButton> */}
                        </Box>
                        <Typography
                          sx={{
                            flex: 1,
                            fontSize: isSmallScreen ? "12px" : "16px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            "&:hover": {
                              color: `${theme.palette.primary.main} !important`,
                            },
                          }}
                          onClick={() => {
                            setDate(new Date(date));
                            handleOnChangeView("day");
                          }}
                        >
                          {label}
                        </Typography>
                      </Box>
                    );
                  },
                  event: (props) => {
                    let {
                      slotStart,
                      slotEnd,
                      continuesAfter,
                      continuesPrior,
                      event,
                    } = props;
                    return (
                      <ShopCalendarEvent
                        calendarEvent={event}
                        // onClick={() => {
                        //   setEditJobDialogData({
                        //     open: true,
                        //     calendarEvent: event,
                        //   });
                        // }}
                        onClick={() => {
                          setJobDialogData({
                            open: true,
                            calendarEvent: event,
                          });
                        }}
                      />
                    );
                  },
                },
                linear_month: {
                  dateHeader: ({ date, label }) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              // setScheduleJobDialogData({
                              //   open: true,
                              //   startDate: date,
                              // });
                            }}
                            color="primary"
                          >
                            <Add
                              sx={{
                                fontSize: isSmallScreen ? "16px" : "20px",
                              }}
                            />
                          </IconButton>
                        </Box>
                        <Typography
                          sx={{
                            flex: 1,
                            fontSize: isSmallScreen ? "12px" : "16px",
                            fontWeight: "bold",
                            cursor: "pointer",
                            "&:hover": {
                              color: `${theme.palette.primary.main} !important`,
                            },
                          }}
                          onClick={() => {
                            setDate(new Date(date));
                            handleOnChangeView("day");
                          }}
                        >
                          {label}
                        </Typography>
                      </Box>
                    );
                  },
                  event: (props) => {
                    let {
                      slotStart,
                      slotEnd,
                      continuesAfter,
                      continuesPrior,
                      event,
                    } = props;
                    return (
                      <ShopCalendarEvent
                        calendarEvent={event}
                        // onClick={() => {
                        //   setEditJobDialogData({
                        //     open: true,
                        //     calendarEvent: event,
                        //   });
                        // }}
                        onClick={() => {
                          setJobDialogData({
                            open: true,
                            calendarEvent: event,
                          });
                        }}
                      />
                    );
                  },
                },
                week: {
                  event: (props) => {
                    let {
                      slotStart,
                      slotEnd,
                      continuesAfter,
                      continuesPrior,
                      event,
                    } = props;
                    return (
                      <ShopCalendarEvent
                        calendarEvent={event}
                        // onClick={() => {
                        //   setEditJobDialogData({
                        //     open: true,
                        //     calendarEvent: event,
                        //   });
                        // }}
                        onClick={() => {
                          setJobDialogData({
                            open: true,
                            calendarEvent: event,
                          });
                        }}
                      />
                    );
                  },
                },
                day: {
                  event: (props) => {
                    let {
                      slotStart,
                      slotEnd,
                      continuesAfter,
                      continuesPrior,
                      event,
                    } = props;
                    return (
                      <ShopCalendarEvent
                        calendarEvent={event}
                        // onClick={() => {
                        //   setEditJobDialogData({
                        //     open: true,
                        //     calendarEvent: event,
                        //   });
                        // }}
                        onClick={() => {
                          setJobDialogData({
                            open: true,
                            calendarEvent: event,
                          });
                        }}
                      />
                    );
                  },
                },
              }}
            />
          )}
          <ScheduleJobDialog
            dialogData={scheduleJobDialogData}
            view={view}
            handleClose={() => {
              setScheduleJobDialogData(DEFAULT_SCHEDULE_JOB_DIALOG_DATA);
            }}
            handleSubmit={(
              jobId,
              assignedTo,
              scheduledSubPhases,
              startDate,
              endDate
            ) => {
              handleScheduleEvent(
                jobId,
                assignedTo,
                scheduledSubPhases,
                startDate,
                endDate
              );
            }}
          />
          {decoded.role == "EZORDER_ADMIN" && (
            <EditJobDialog
              dialogData={editJobDialogData}
              view={view}
              handleClose={() => {
                setEditJobDialogData(DEFAULT_EDIT_JOB_DIALOG_DATA);
              }}
              handleSubmit={(
                eventId,
                assignedTo,
                scheduledSubPhases,
                startDate,
                endDate
              ) => {
                handleUpdateEvent(
                  eventId,
                  assignedTo,
                  scheduledSubPhases,
                  startDate,
                  endDate
                );
              }}
            />
          )}

          {decoded.role == "EZORDER_ADMIN" && (
            <DeleteJobDialog
              dialogData={deleteJobDialogData}
              view={view}
              handleClose={() => {
                setDeleteJobDialogData(DEFAULT_DELETE_JOB_DIALOG_DATA);
              }}
              handleSubmit={(eventId) => {
                handleDeleteEvent(eventId);
              }}
            />
          )}

          <JobDialog
            dialogData={jobDialogData}
            view={view}
            handleClose={() => {
              setJobDialogData(DEFAULT_JOB_DIALOG_DATA);
            }}
            handleEdit={() => {
              setEditJobDialogData({
                open: true,
                calendarEvent: jobDialogData.calendarEvent,
              });
              setJobDialogData(DEFAULT_JOB_DIALOG_DATA);
            }}
            handleDelete={() => {
              setDeleteJobDialogData({
                open: true,
                calendarEvent: jobDialogData.calendarEvent,
              });
              setJobDialogData(DEFAULT_JOB_DIALOG_DATA);
            }}
          />
          {decoded.role == "EZORDER_ADMIN" && (
            <ScheduleButton
              onAdding={() => {
                setScheduleJobDialogData({
                  open: true,
                  date: date,
                });
              }}
            />
          )}
        </Card>
      </Box>
    );
  else return <Typography>Loading Calendar</Typography>;
};

export default ShopCalendar;
