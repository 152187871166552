import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, Close, NotificationAdd } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const SendPickupAvailableNotificationDialog = ({
  orderId,
  open,
  handleClose,
}) => {
  const [order, setOrder] = useState(null);
  const { authToken, ezorder } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [finalizeQuoteEmailReceivers, setFinalizeQuoteEmailReceivers] =
    useState([]);
  const [sendQuoteCopyTo, setSendQuoteCopyTo] = useState([]);
  const [isAddingOtherApproversToCopy, setIsAddingOtherApproversToCopy] =
    useState(false);

  const getFinalizedQuoteEmailList = async () => {
    try {
      const res = await ezorder.get(
        `/admin/companies/${order.company.id}/users/${order.estimateRequestedBy.id}/estimate-approvers`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      let requester = order.estimateRequestedBy;
      let approvers = res.data.quoteApprovers;
      let emails = [];
      // Add Requester to email list if not an approver
      const requesterIndexInApprovers = approvers.findIndex(
        (item) => item.email == requester.email
      );
      if (requesterIndexInApprovers == -1) {
        emails = [requester, ...approvers];
      } else {
        approvers.splice(requesterIndexInApprovers, 1);
        emails = [requester, ...approvers];
      }
      setFinalizeQuoteEmailReceivers(emails);
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  // This is admin only
  const getOrder = async (orderId) => {
    try {
      setLoading(true);
      const response = await ezorder.get(`/admin/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrder(response.data.order);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  const sendNotification = async (orderId, sendQuoteCopyTo) => {
    try {
      setLoading(true);
      const response = await ezorder.post(
        `/shop/jobs/${orderId}/pickup-history/send-notification`,
        { sendTo: sendQuoteCopyTo },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      // setOrder(response.data.order);
      handleClose();
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(order);
    if (order && order.estimateRequestedBy && order.estimateAssignedApprover) {
      if (
        order.estimateRequestedBy.email != order.estimateAssignedApprover.email
      ) {
        setSendQuoteCopyTo([
          order.estimateRequestedBy,
          order.estimateAssignedApprover,
        ]);
      } else {
        setSendQuoteCopyTo([order.estimateRequestedBy]);
      }
    }
    if (order && order.company && order.company.id) {
      getFinalizedQuoteEmailList(order.id);
    }
  }, [order]);

  useEffect(() => {
    getOrder(orderId);
  }, [orderId]);

  if (order)
    return (
      <Dialog
        fullWidth
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>Send Pickup Available Notification</DialogTitle>
        <DialogContent>
          <DialogContentText>Send To</DialogContentText>

          <Box>
            {sendQuoteCopyTo.map((sendTo, index) => {
              return (
                <Button
                  size="small"
                  variant="outlined"
                  color={
                    (order.estimateRequestedBy &&
                      sendTo.email == order.estimateRequestedBy.email) ||
                    (order.estimateAssignedApprover &&
                      sendTo.email == order.estimateAssignedApprover.email)
                      ? "success"
                      : "neutral"
                  }
                  sx={{
                    borderRadius: "120px",
                    textTransform: "none",
                    marginRight: "8px",
                  }}
                >
                  {order.estimateRequestedBy &&
                  sendTo.email == order.estimateRequestedBy.email
                    ? "[Requester] "
                    : ""}
                  {order.estimateAssignedApprover &&
                  sendTo.email == order.estimateAssignedApprover.email
                    ? "[Approver] "
                    : ""}
                  {sendTo.fullName}({sendTo.email})
                  {/* {sendTo.email == order.estimateRequestedBy.email
            ? " [Quote Requester]"
            : ""} */}
                  <Close
                    sx={{ marginLeft: "8px" }}
                    onClick={() => {
                      console.log("Remove From");
                      let copy = [...sendQuoteCopyTo];
                      copy.splice(index, 1);
                      setSendQuoteCopyTo(copy);
                    }}
                  />
                </Button>
              );
            })}
            <Box>
              <Button
                onClick={() => {
                  if (
                    sendQuoteCopyTo.length < finalizeQuoteEmailReceivers.length
                  )
                    setIsAddingOtherApproversToCopy(true);
                }}
              >
                + Copy Requester And Other Approvers
              </Button>
            </Box>
            {isAddingOtherApproversToCopy && (
              <Box>
                <DialogContentText>Add Other Approvers</DialogContentText>
                <Card style={{}}>
                  <div style={{}}>
                    <div
                      style={{
                        display: "block",
                        maxHeight: 200,
                        maxWidth: "100%",
                        overflow: "scroll",
                        overflowX: "hidden",
                      }}
                    >
                      {finalizeQuoteEmailReceivers.map(
                        (finalizeQuoteEmailReceiver, index) => {
                          const result = sendQuoteCopyTo.findIndex(
                            (item) =>
                              item.email == finalizeQuoteEmailReceiver.email
                          );

                          if (result < 0)
                            return (
                              <ListItem
                                onClick={() => {
                                  let copy = [...sendQuoteCopyTo];
                                  copy.push(finalizeQuoteEmailReceiver);
                                  setSendQuoteCopyTo(copy);
                                  setIsAddingOtherApproversToCopy(false);
                                }}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover": {
                                    background: "#eeeeee",
                                  },
                                }}
                              >
                                <ListItemText
                                  primary={`${
                                    finalizeQuoteEmailReceiver.email ==
                                    order.estimateRequestedBy.email
                                      ? "[Requester] "
                                      : ""
                                  } ${finalizeQuoteEmailReceiver.fullName}`}
                                  secondary={
                                    <>
                                      <Typography
                                        style={{
                                          fontSize: 12,
                                        }}
                                      >
                                        Email:{" "}
                                        {finalizeQuoteEmailReceiver.email}
                                      </Typography>
                                      {/* <Typography
                          style={{
                            fontSize: 12,
                          }}
                        >
                          Phone: {finalizeQuoteEmailReceiver.phone}
                        </Typography> */}
                                    </>
                                  }
                                />
                              </ListItem>
                            );
                        }
                      )}
                    </div>
                  </div>
                </Card>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              sendNotification(orderId, sendQuoteCopyTo);
            }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    );
};

export default SendPickupAvailableNotificationDialog;
