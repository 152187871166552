import {
  Box,
  CircularProgress,
  Modal,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { toTwoDecimals } from "../../../components/NewDetail/calculations/utils";
import { AuthContext } from "../../../context/AuthContext";

const QuoteRow = ({ onSelect, order, isPendingQuote = false }) => {
  const theme = useTheme();
  const [loadingInvoice, setIsLoadingInvoice] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const [invoice, setInvoice] = useState(null);

  // TODO: query backend to query QB for invoice details
  const getInvoiceDetails = async (controller) => {
    try {
      setIsLoadingInvoice(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(
        `/admin/orders/${order.id}/invoice`,
        data
      );

      setInvoice(response.data.invoice);
    } catch (error) {
      console.log("Failed To Load Invoice for Order", order.id);
    } finally {
      setIsLoadingInvoice(false);
    }
  };

  useEffect(() => {
    // console.log("OrderTableRow useEffect()");
    const controller = new AbortController();
    if (
      (order !== null || order !== undefined) &&
      order.invoiceId &&
      !isPendingQuote
    ) {
      // console.log("Invoice ID found for Order: ", order.name);

      getInvoiceDetails(controller);
    }

    return () => {
      controller.abort(); // abort api call on unmount for cleanup
    };
  }, [order]);

  return (
    <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell
        onClick={() => {
          onSelect(order);
        }}
      >
        {/* {order.salesRep ? order.salesRep.fullName : ""} */}
        {order.salesRep
          ? `${order.salesRep.fullName}${
              order.salesRep.deletedAt ? " [DEACTIVATED]" : ""
            }`
          : "UNASSIGNED"}
      </TableCell>
      <TableCell
        onClick={() => {
          onSelect(order);
        }}
      >
        {order.id}
      </TableCell>
      <TableCell
        component="th"
        scope="row"
        onClick={() => {
          onSelect(order);
        }}
      >
        {order.company && order.company.name ? order.company.name : "GUEST"}
      </TableCell>
      <TableCell
        align="right"
        onClick={() => {
          onSelect(order);
        }}
      >
        {order.project && order.project.name ? order.project.name : "N/A"}
      </TableCell>
      <TableCell
        onClick={() => {
          onSelect(order);
        }}
      >
        {order.poNumber}
      </TableCell>
      <TableCell
        align="right"
        onClick={() => {
          onSelect(order);
        }}
      >
        {moment(order.createdAt).format("MM/DD/YY")}
      </TableCell>
      {isPendingQuote && (
        <TableCell
          align="right"
          onClick={() => {
            onSelect(order);
          }}
        >
          {order.estimateLastSentForApproval
            ? moment(order.estimateLastSentForApproval).format("MM/DD/YY")
            : "N/A"}
        </TableCell>
      )}
      {!isPendingQuote && (
        <TableCell
          allign="right"
          onClick={() => {
            onSelect(order);
          }}
        >
          {loadingInvoice ? (
            <CircularProgress sx={{ margin: "4px" }} />
          ) : (
            <Typography sx={{ textAlign: "right" }}>
              {invoice ? `$${invoice.Balance}` : "N/A"}
            </Typography>
          )}
        </TableCell>
      )}
      {!isPendingQuote && (
        <TableCell
          allign="right"
          onClick={() => {
            onSelect(order);
          }}
        >
          {loadingInvoice ? (
            <CircularProgress sx={{ margin: "4px" }} />
          ) : (
            <Typography sx={{ textAlign: "right" }}>
              {invoice
                ? `${moment(invoice.DueDate).format("MM/DD/YY")}`
                : "N/A"}
            </Typography>
          )}
        </TableCell>
      )}

      <TableCell align="right">
        ${toTwoDecimals(order.outTheDoorCost)}
      </TableCell>
    </TableRow>
  );
};

export default QuoteRow;
