import { Box } from "@mui/material";
import React from "react";
import QuoteForm from "../../OrdersV2/Order/QuoteForm/QuoteForm";

const NewQuotePage = () => {
  return (
    <Box>
      <QuoteForm
        orderId={null}
        onSave={() => {
          // TODO: handle save
        }}
      />
    </Box>
  );
};

export default NewQuotePage;
