import { useTheme } from "@emotion/react";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const SelectProducts = ({ quote, onUpdateQuote, defaultVariables }) => {
  const theme = useTheme();

  const [productMap, setProductMap] = useState(new Map());

  const getProductMap = (quote) => {
    const prodMap = new Map();
    let quoteItems = quote.items ?? [];
    for (let i = 0; i < quoteItems.length; i++) {
      let orderItem = quoteItems[i];
      prodMap.set(`${orderItem.objectType}`, orderItem);
    }
    setProductMap(prodMap);
  };

  const onAddProduct = (defaultProductObject) => {
    let quoteItems = quote.items ?? [];
    let quoteItemsCopy = [...quoteItems, defaultProductObject];
    onUpdateQuote({ items: quoteItemsCopy });
  };

  const onRemoveProduct = (objectType) => {
    let quoteItems = quote.items ?? [];
    let quoteItemsCopy = [...quoteItems];
    let indexToDelete = quoteItemsCopy.findIndex(
      (item) => item.objectType == objectType
    );
    quoteItemsCopy.splice(indexToDelete, 1);
    onUpdateQuote({ items: quoteItemsCopy });
  };

  useEffect(() => {
    if (quote && quote.items) {
      getProductMap(quote);
    } else {
      setProductMap(new Map());
    }
  }, [quote]);

  return (
    <Box>
      <Typography variant="h6">Select Products</Typography>
      <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("FlatSheet")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add
                onAddProduct({
                  objectType: "FlatSheet",
                  variables: defaultVariables,
                  flatSheetCalculations: {},
                });
              } else {
                //  Remove
                onRemoveProduct("FlatSheet");
              }
            }}
          />
          <Typography>Flat Sheet</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("Coil")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add
                onAddProduct({
                  objectType: "Coil",
                  variables: defaultVariables,
                });
              } else {
                //  Remove
                onRemoveProduct("Coil");
              }
            }}
          />
          <Typography>Coil</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("Panel")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add
                onAddProduct({
                  objectType: "Panel",
                  cuts: [],
                  variables: defaultVariables,
                });
              } else {
                //  Remove
                onRemoveProduct("Panel");
              }
            }}
          />
          <Typography>Panel</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("TrimAndFlashing")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add
                onAddProduct({
                  objectType: "TrimAndFlashing",
                  trimPieces: [],
                });
              } else {
                //  Remove
                onRemoveProduct("TrimAndFlashing");
              }
            }}
          />
          <Typography>Trim & Flashing</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("BenchWork")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add

                onAddProduct({
                  objectType: "BenchWork",
                  benchWorkPieces: [],
                });
              } else {
                //  Remove
                onRemoveProduct("BenchWork");
              }
            }}
          />
          <Typography>BenchWork</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("CopingCap")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add

                onAddProduct({ objectType: "CopingCap", capPieces: [] });
              } else {
                //  Remove
                onRemoveProduct("CopingCap");
              }
            }}
          />
          <Typography>Coping Cap</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("CopingCapCleat")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add
                onAddProduct({
                  objectType: "CopingCapCleat",
                  cleatPieces: [],
                });
              } else {
                //  Remove
                onRemoveProduct("CopingCapCleat");
              }
            }}
          />
          <Typography>Coping Cap Cleat</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("SplicePlate")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add
                onAddProduct({ objectType: "SplicePlate", plates: [] });
              } else {
                //  Remove
                onRemoveProduct("SplicePlate");
              }
            }}
          />
          <Typography>Splice Plate</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            magin: 0,
            padding: 0,
          }}
        >
          <Checkbox
            style={{
              magin: 0,
              padding: 0,
            }}
            checked={productMap.has("Accessory")}
            onChange={(e) => {
              let checked = e.target.checked;
              if (checked) {
                //  Add
                onAddProduct({ objectType: "Accessory", items: [] });
              } else {
                //  Remove
                onRemoveProduct("Accessory");
              }
            }}
          />
          <Typography>Extra Accessories</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SelectProducts;
