import Compressor from "compressorjs";

const compressImage = async (
  file, //: Blob,
  type, //: string,
  avatar // : boolean = false
) => {
  const bmp = await createImageBitmap(file);
  const { width, height } = bmp;
  bmp.close();
  return await new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: avatar ? 0.2 : 0.3, // Adjust the desired image quality (0.0 - 1.0)
      // width: avatar ? 400 : width,
      // height: avatar ? 400 : height,
      mimeType: type, // Specify the output image format
      success(result) {
        resolve(result);
      },
      error(error) {
        reject(error);
      },
    });
  });
};

export default compressImage;
