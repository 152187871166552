import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";
import { HeadingContext } from "../../../context/HeadingContext";
import { useNavigate } from "react-router-dom";
import MenuDrawer from "./MenuDrawer";
import Container from "@mui/material/Container";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";

import Badge from "@mui/material/Badge";
import { NotificationsNone } from "@mui/icons-material";
import ErrorBoundary from "../../../pages/error/ErrorBoundary";
import { useTheme } from "@emotion/react";
import { NotificationContext } from "../../../context/NotificationContext";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

const drawerWidth = 240;
const navItems = [
  { title: "Quotes", path: "/quotes" },
  { title: "Orders", path: "/orders" },
  { title: "Projects", path: "/projects" },
  { title: "Customers", path: "/customers" },
  // { title: "Alerts", path: "/alerts" },
  { title: "Catalog", path: "/catalog" },
];

// const mdTheme = createTheme({
//   palette: {
//     secondary: {
//       main: "#72eded",
//     },
//     primary: {
//       main: "#141414",
//     },
//   },
// });

const TopNav = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { decoded } = useContext(AuthContext);
  const { unreadCount } = React.useContext(NotificationContext);
  const { window } = props;
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { headingText, captionText } = React.useContext(HeadingContext);
  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleNav = (navItem) => {
    switch (navItem) {
      case "Quotes":
        navigate("/quotes");
        break;
      case "Orders":
        navigate("/orders");
        break;
      case "Projects":
        navigate("/projects");
        break;
      case "Customers":
        navigate("/customers");
        break;
        // case "Alerts":
        //   navigate("/alerts");
        break;
      case "Catalog":
        navigate("/catalog");
        break;
      case "Menu":
        handleDrawerToggle();
        break;
      default:
        break;
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  let activePath = "";
  let activePaths = [];
  for (const item of navItems) {
    if (location.pathname.includes(item.path)) {
      activePaths.push(item.path);
    }
  }
  activePath = activePaths[0]; // Return Order path if both order and projects are in current location

  return (
    <>
      {/* <ThemeProvider theme={mdTheme}> */}
      <MenuDrawer open={drawerOpen} toggleDrawer={handleDrawerToggle} />
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{
            background: theme.palette.appBar
              ? theme.palette.appBar.background
              : "inherit",
            color: theme.palette.appBar
              ? theme.palette.appBar.contrastText
              : "inherit",
          }}
        >
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
              <img
                style={{ marginTop: "8px", marginBottom: "8px" }}
                src="/industry-metal-co-long-logo-light-outlined-icon.png"
                height={50}
                onClick={() => {
                  if (decoded.isVendorOrSupplier) {
                    navigate("/customers");
                  } else {
                    navigate("/orders");
                  }
                }}
              />
            </Box>

            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => {
                if (decoded.isVendorOrSupplier && item.path == "/projects")
                  return null;
                if (!decoded.isVendorOrSupplier && item.path == "/customers")
                  return null;
                return (
                  <Button
                    sx={{
                      color:
                        activePath == item.path
                          ? theme.palette.primary.main
                          : "inherit",
                    }}
                    key={item.title}
                    onClick={() => handleNav(item.title)}
                  >
                    {item.title.toLowerCase() === "alerts" ? (
                      <Badge badgeContent={unreadCount} color="primary">
                        {item.title}
                      </Badge>
                    ) : (
                      item.title
                    )}
                  </Button>
                );
              })}
              <Button>
                <MenuOutlinedIcon
                  sx={{ color: "white" }}
                  onClick={handleDrawerToggle}
                />
              </Button>
            </Box>
          </Toolbar>
        </AppBar>

        <Box
          component="main"
          sx={{
            p: 3,
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "1rem",
            }}
          >
            <Box
              sx={{
                marginTop: "16px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {headingText && (
                <Typography color variant="h4">
                  {headingText}
                </Typography>
              )}
              {captionText && (
                <Typography color variant="subtitle2">
                  {captionText}
                </Typography>
              )}
            </Box>
          </Box>
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </Container>
        </Box>
      </Box>
      {/* </ThemeProvider> */}
    </>
  );
};

export default TopNav;
