import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogContentText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import moment from "moment-timezone";
import JobProgressDialog from "./JobProgressDialog";
import ScheduleJobDialog from "../ShopSchedule/ShopCalendar/ScheduleJobDialog";
import useSchedule from "../../../hooks/useSchedule";
import * as Sentry from "@sentry/react";

const DEFAULT_SCHEDULE_JOB_DIALOG_DATA = {
  open: false,
  date: null,
  jobId: null,
};

const Jobs = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { scheduleEvent, updateEvent } = useSchedule();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [scheduleJobDialogData, setScheduleJobDialogData] = useState(
    DEFAULT_SCHEDULE_JOB_DIALOG_DATA
  );
  const [loading, setLoading] = useState(false);

  const { authToken, ezorder } = useContext(AuthContext);

  const getAvailableJobs = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(`/shop/jobs`, data);

      if (response.status == 200) {
        setJobs(response.data.jobs);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleScheduleEvent = async (
    jobId,
    assignedTo,
    subPhases,
    startDate,
    endDate
  ) => {
    try {
      const response = await scheduleEvent(
        jobId,
        assignedTo,
        subPhases,
        startDate,
        endDate
      );
      //TODO: navigate
      let start = moment(startDate).tz("America/Phoenix").format("YYYY-MM-DD");
      navigate(`/shop-schedule?date=${start}&view=day`);
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getAvailableJobs();
  }, []);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Button
        onClick={() => {
          try {
            throw new Error("MY CUSTOM ERROR");
          } catch (error) {
            Sentry.captureException(error);
          }
        }}
      >
        Test Sentry
      </Button>
      <Typography variant="h4" component="div">
        Jobs
      </Typography>
      {/* <SearchBar
        size="large"
        label="Search Orders"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      /> */}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>Est. Start</TableCell>
              <TableCell>Est. Finish</TableCell> */}
              {/* <TableCell>Sales Rep</TableCell> */}
              <TableCell>ID</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Customer</TableCell>
              {/* <TableCell>PO #</TableCell> */}
              <TableCell>Credit Type</TableCell>
              <TableCell>Date Approved</TableCell>
              {/* <TableCell>$</TableCell> */}
              <TableCell>Scheduled Tasks</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  <CircularProgress size="3rem" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {jobs.map((job, index) => {
                  // let lastScheduled = "----";
                  // if (
                  //   job.shopCalendarDayEvents &&
                  //   job.shopCalendarDayEvents.length > 0
                  // ) {
                  //   lastScheduled = moment(
                  //     job.shopCalendarDayEvents[0].startDate
                  //   )
                  //     .tz("America/Phoenix")
                  //     .format("MM/DD/YY");
                  // }

                  // let estStart = "TBD";
                  // let estFinish = "TBD";
                  // let earliest = null;
                  // let latest = null;

                  let scheduledSubPhases = 0;
                  let totalSubPhases = 0;
                  for (let phase of job.phases) {
                    for (let subPhase of phase.subPhases) {
                      totalSubPhases++;
                      if (
                        subPhase.calendarEvents &&
                        subPhase.calendarEvents.length > 0
                      ) {
                        scheduledSubPhases++;

                        // for (let event of subPhase.calendarEvents) {
                        //   if (earliest == null) {
                        //     earliest = new Date(event.startDate);
                        //   } else {
                        //     earliest =
                        //       earliest < new Date(event.startDate)
                        //         ? earliest
                        //         : new Date(event.startDate);
                        //   }

                        //   latest =
                        //     latest > new Date(event.endDate)
                        //       ? latest
                        //       : new Date(event.endDate);
                        // }
                      }
                    }
                  }
                  // if (earliest) {
                  //   estStart = moment(earliest)
                  //     .tz("America/Phoenix")
                  //     .format("MM/DD/YY h:mm a");
                  // }

                  // if (scheduledSubPhases == totalSubPhases) {
                  //   // If all subPhases are scheduled then we can have an estimated finish date
                  //   estFinish = moment(latest)
                  //     .tz("America/Phoenix")
                  //     .format("MM/DD/YY h:mm a");
                  // }

                  return (
                    <TableRow
                      key={job.id}
                      hover
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedJob(job);
                      }}
                    >
                      {/* <TableCell>{estStart}</TableCell>
                        <TableCell>{estFinish}</TableCell> */}
                      {/* <TableCell>
                          {job.salesRep
                            ? `${job.salesRep.fullName}${
                                job.salesRep.deletedAt ? " [DEACTIVATED]" : ""
                              }`
                            : "UNASSIGNED"}
                        </TableCell> */}
                      <TableCell>{job.id}</TableCell>
                      <TableCell>{job.project.name}</TableCell>
                      <TableCell>
                        {job.company && job.company.name
                          ? job.company.name
                          : "GUEST"}
                      </TableCell>
                      {/* <TableCell>{job.poNumber}</TableCell> */}

                      <TableCell>{job.company.creditType}</TableCell>

                      <TableCell>
                        {moment(job.estimateApprovalDate)
                          .tz("America/Phoenix")
                          .format("MM/DD/YY")}
                      </TableCell>
                      {/* <TableCell>
                          ${toTwoDecimals(job.outTheDoorCost)}
                        </TableCell> */}
                      <TableCell>
                        <Button
                          color={
                            scheduledSubPhases == totalSubPhases
                              ? "success"
                              : "warning"
                          }
                        >
                          {scheduledSubPhases} / {totalSubPhases}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <JobProgressDialog
        open={selectedJob != null}
        jobDetails={selectedJob}
        handleSchedule={(jobId) => {
          setScheduleJobDialogData({ open: true, jobId: jobId });
          setSelectedJob(null);
        }}
        handleClose={() => {
          setSelectedJob(null);
        }}
      />
      <ScheduleJobDialog
        dialogData={scheduleJobDialogData}
        handleClose={() => {
          setScheduleJobDialogData(DEFAULT_SCHEDULE_JOB_DIALOG_DATA);
        }}
        handleSubmit={(
          jobId,
          assignedTo,
          scheduledSubPhases,
          startDate,
          endDate
        ) => {
          handleScheduleEvent(
            jobId,
            assignedTo,
            scheduledSubPhases,
            startDate,
            endDate
          );
        }}
      />
    </Box>
  );
};
export default Jobs;
