import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import ImageCropper from "../../../../ImageCropper/ImageCropper";
import { findProperty } from "./utils";

const NewTrimModal = ({
  open,
  toggleOpen,
  trim,
  handleChange,
  handleSubmit,
  handleImage,
  trimType,
}) => {
  const [localImage, setLocalImage] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const findInTrim = findProperty(trim);

  const [imageBlob, setImageBlob] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageBlobCropped, setImageBlobCropped] = useState(null);
  const [imageUrlCropped, setImageUrlCropped] = useState(null);

  useEffect(() => {
    setLocalImage(null);
    setImageUrl(null);
    setImageBlob(null);
    setImageUrlCropped(null);
    setImageBlobCropped(null);
  }, [open]);

  return (
    <Dialog
      // disableEnforceFocus
      keepMounted
      scroll={"paper"}
      maxWidth="md"
      fullWidth={true}
      fullScreen={fullScreen}
      // PaperProps={{
      //   style: {
      //     background: theme.palette.secondary.main,
      //   },
      // }}
      open={open}
      onClose={() => {
        setImageUrl(null);
        setImageBlob(null);
        setImageUrlCropped(null);
        setImageBlobCropped(null);
        toggleOpen();
      }}
    >
      <DialogTitle>Add New</DialogTitle>
      <DialogContent sx={{ gap: "1rem" }}>
        <Box sx={{ display: " flex", flexDirection: "column", gap: "1rem" }}>
          <Box>
            {trimType !== "SplicePlate" && (
              <TextField
                label="Name"
                fullWidth
                value={findInTrim("name")}
                onChange={handleChange("name")}
              />
            )}
            {trimType === "BenchWork" && (
              <TextField
                sx={{ marginTop: "1rem" }}
                multiline
                rows={2}
                label="Notes"
                fullWidth
                value={findInTrim("notes")}
                onChange={handleChange("notes")}
              />
            )}
          </Box>
          <Box sx={{ display: " flex", flexDirection: "row", gap: "1rem" }}>
            <TextField
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              label="Quantity"
              value={findInTrim("quantity")}
              onChange={handleChange("quantity")}
            />
            {trimType !== "BenchWork" && (
              <TextField
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                label="Stretch Out (in)"
                value={findInTrim("stretchOut")}
                onChange={handleChange("stretchOut")}
              />
            )}
            {trimType !== "BenchWork" && (
              <TextField
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                label="Hits"
                value={findInTrim("hits")}
                onChange={handleChange("hits")}
              />
            )}

            {trimType === "SplicePlate" && (
              <TextField
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                label="Width (in)"
                value={findInTrim("length")}
                onChange={handleChange("length")}
              />
            )}

            {trimType === "BenchWork" && (
              <>
                <TextField
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  label="Flat Sheets"
                  value={findInTrim("flatSheets")}
                  onChange={handleChange("flatSheets")}
                />
              </>
            )}
          </Box>
          <Box
            sx={{
              display: " flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: " flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              {trimType !== "SplicePlate" && (
                <Box sx={{ width: "100%" }}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ marginTop: "12px" }}
                  >
                    Image
                  </Typography>

                  <Box sx={{ maxWidth: "400px" }}>
                    {imageUrl ? (
                      <Box sx={{ width: "100%" }}>
                        <ImageCropper
                          image={imageUrl}
                          onSave={async (fileBlob, url) => {
                            console.log("CROPPED", fileBlob, url);
                            setImageUrl(null);
                            setImageBlob(null);

                            await handleImage(fileBlob);
                            setImageUrlCropped(url);
                            setImageBlobCropped(fileBlob);
                          }}
                          onClear={() => {
                            setImageUrl(null);
                            setImageBlob(null);
                            setImageUrlCropped(null);
                            setImageBlobCropped(null);
                          }}
                        />
                      </Box>
                    ) : (
                      <Box>
                        <Box>
                          {imageUrlCropped && (
                            <Box style={{ position: "relative" }}>
                              <img
                                src={imageUrlCropped}
                                style={{
                                  marginTop: 16,
                                  // maxWidth: "100%",
                                  // maxHeight: 200,
                                  width: "100%",
                                }}
                              />
                              <Box
                                onClick={() => {
                                  setImageUrlCropped(null);
                                  setImageBlobCropped(null);
                                }}
                              >
                                <Close
                                  style={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                  }}
                                />
                              </Box>
                            </Box>
                          )}
                        </Box>
                        {imageBlob === null &&
                          imageUrl === null &&
                          imageBlobCropped === null &&
                          imageUrlCropped === null && (
                            <Box
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <label>
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  accept="image/*"
                                  // capture="environment"
                                  onChange={(event) => {
                                    console.log(event.target.files[0]);
                                    console.log(
                                      "Image",
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                    setImageBlob(event.target.files[0]);
                                    setImageUrl(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                    setImageBlobCropped(null);
                                    setImageUrlCropped(null);
                                  }}
                                />
                                <Box
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    padding: 12,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      borderStyle: "solid",
                                      borderWidth: 1,
                                      borderColor: "#d2d2d2",
                                      color: "#d2d2d2",
                                      width: 100,
                                      height: 100,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Upload sx={{ fontSize: 40 }} />
                                  </Box>
                                </Box>
                              </label>
                              <label>
                                <input
                                  style={{ display: "none" }}
                                  type="file"
                                  accept="image/*"
                                  capture="environment"
                                  onChange={(event) => {
                                    console.log(event.target.files[0]);
                                    console.log(
                                      "Image",
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                    setImageBlob(event.target.files[0]);
                                    setImageUrl(
                                      URL.createObjectURL(event.target.files[0])
                                    );
                                    setImageBlobCropped(null);
                                    setImageUrlCropped(null);
                                  }}
                                />
                                <Box
                                  style={{
                                    cursor: "pointer",
                                    width: "100%",
                                    padding: 12,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    style={{
                                      borderStyle: "solid",
                                      borderWidth: 1,
                                      borderColor: "#d2d2d2",
                                      color: "#d2d2d2",
                                      width: 100,
                                      height: 100,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CameraAlt sx={{ fontSize: 40 }} />
                                  </Box>
                                </Box>
                              </label>
                            </Box>
                          )}
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          <Button
            color="success"
            disabled={trimType !== "SplicePlate" && imageBlobCropped === null}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Box>
        <Button
          onClick={() => {
            setImageUrl(null);
            setImageBlob(null);
            setImageUrlCropped(null);
            setImageBlobCropped(null);
            toggleOpen();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default NewTrimModal;
