import { CalendarMonth, ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Views } from "react-big-calendar";
import DatePickerDialog from "../DatePickerDialog";
import { useTheme } from "@emotion/react";

const CalendarToolBar = ({
  view,
  date,
  assignedToMe,
  onNavigate,
  handleOnChangeView,
  handleAssignedToMe,
  isAdmin,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [isDatePicking, setDatePicking] = useState(false);

  return (
    <Card sx={{ position: "relative", width: "100%", padding: "8px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={() => {
            let day = moment(date).tz("America/Phoenix").startOf("d");
            switch (view) {
              case Views.MONTH:
              case "linear_month":
                day = day.subtract(1, "month");
                day = day.startOf("month");
                break;
              case Views.WEEK:
                day = day.subtract(1, "week");
                day = day.startOf("week");
                break;
              case Views.DAY:
                day = day.subtract(1, "day");
                break;
            }
            onNavigate(day);
          }}
        >
          <ChevronLeft />
        </IconButton>
        <DatePickerDialog
          // view={view}
          open={isDatePicking}
          value={date ? moment(date).tz("America/Phoenix").startOf("d") : null}
          onChange={(newValue) => {
            onNavigate(moment(newValue).tz("America/Phoenix").startOf("d"));
            setDatePicking(false);
          }}
          handleClose={() => {
            setDatePicking(false);
          }}
        />
        <TextField
          sx={{ cursor: "pointer" }}
          onClick={() => {
            setDatePicking(true);
          }}
          value={moment(date)
            .tz("America/Phoenix")
            .startOf("d")
            .format("dddd MMM Do")}
          InputProps={{ readOnly: true, endAdornment: <CalendarMonth /> }}
        />

        <IconButton
          onClick={() => {
            let day = moment(date).tz("America/Phoenix").startOf("d");
            switch (view) {
              case Views.MONTH:
              case "linear_month":
                day = day.add(1, "month");
                day = day.startOf("month");
                break;
              case Views.WEEK:
                day = day.add(1, "week");
                day = day.startOf("week");
                break;
              case Views.DAY:
                day = day.add(1, "day");
                break;
            }
            onNavigate(day);
          }}
        >
          <ChevronRight />
        </IconButton>
      </Box>

      <Box
        sx={
          // isMediumScreen
          //   ? {
          {
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "8px",
          }
          // }
          // : {
          //     position: "absolute",
          //     right: 0,
          //     top: "12px",
          //   }
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
          }}
        >
          {!isAdmin && (
            <Box>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  magin: 0,
                  padding: 0,
                  marginRight: "16px",
                }}
              >
                <Checkbox
                  style={{
                    magin: 0,
                    padding: 0,
                  }}
                  checked={assignedToMe}
                  onChange={(e) => {
                    handleAssignedToMe(e.target.checked);
                  }}
                />
                <Typography>Assigned To Me</Typography>
              </div>
            </Box>
          )}

          <ButtonGroup>
            {isAdmin && (
              <Button
                variant={view == Views.MONTH ? "contained" : "outlined"}
                onClick={() => {
                  handleOnChangeView(Views.MONTH);
                }}
              >
                Calendar
              </Button>
            )}
            {isAdmin && (
              <Button
                variant={view == "linear_month" ? "contained" : "outlined"}
                onClick={() => {
                  handleOnChangeView("linear_month");
                }}
              >
                Month
              </Button>
            )}
            <Button
              variant={view == Views.WEEK ? "contained" : "outlined"}
              onClick={() => {
                handleOnChangeView(Views.WEEK);
              }}
            >
              Week
            </Button>
            <Button
              variant={view == Views.DAY ? "contained" : "outlined"}
              onClick={() => {
                handleOnChangeView(Views.DAY);
              }}
            >
              Day
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
    </Card>
  );
};

export default CalendarToolBar;
