import React from "react";
import ReadyForPickup from "../../admin/ReadyForPickup/ReadyForPickup";
import usePageName from "../../../hocs/usePageName";

const ReadyForPickupPage = () => {
  usePageName({ heading: "Ready For Pickup" });
  return <ReadyForPickup />;
};

export default ReadyForPickupPage;
