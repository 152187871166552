import { useEffect, useMemo, useRef, useState } from "react";
import { Navigate } from "react-big-calendar";
import PropTypes from "prop-types";
import TimeGrid from "react-big-calendar/lib/TimeGrid";
import moment from "moment-timezone";
import { Box, Button, Card, IconButton, useMediaQuery } from "@mui/material";
import { useTheme } from "@emotion/react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

const MIN_WIDTH = 7000;

// const SMALL_SCREEN_WIDTH = 5000;
// const LARGE_SCREEN_WIDTH = 5000;

const CustomMonthView = ({
  date,
  localizer,
  //   max = localizer.endOf(new Date(), "day"),
  //   min = localizer.startOf(new Date(), "day"),
  scrollToTime = localizer.startOf(new Date(date), "day"),
  ...props
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const scrollerRef = useRef(null);
  const [arrowDisable, setArrowDisable] = useState(true);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setArrowDisable(true);
      } else {
        setArrowDisable(false);
      }
    }, speed);
  };

  const currRange = useMemo(
    () => CustomMonthView.range(date, { localizer }),
    [date, localizer]
  );

  return (
    <Box sx={{ position: "relative" }}>
      <Card
        sx={{
          position: "absolute",
          left: 0,
          height: "100%",
          width: "40px",
          background: `rgba(0, 0, 0, 0.15)`,
          "&:hover": {
            background: `rgba(0, 0, 0, 0.3)`,
          },
          zIndex: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onMouseDown={() => {
          handleHorizantalScroll(
            scrollerRef.current,
            25,
            100,
            -(isSmallScreen ? 200 : MIN_WIDTH / 14)
          );
        }}
        // disabled={arrowDisable}
      >
        <IconButton>
          <ArrowBackIos sx={{ color: "#fff" }} />
        </IconButton>
      </Card>

      <Card
        sx={{
          position: "absolute",
          right: 0,
          height: "100%",
          width: "40px",
          background: `rgba(0, 0, 0, 0.15)`,
          "&:hover": {
            background: `rgba(0, 0, 0, 0.3)`,
          },
          zIndex: 30,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onMouseDown={() => {
          handleHorizantalScroll(
            scrollerRef.current,
            25,
            100,
            isSmallScreen ? 200 : MIN_WIDTH / 14
          );
        }}
      >
        <IconButton>
          <ArrowForwardIos sx={{ color: "#fff" }} />
        </IconButton>
      </Card>

      <Box
        sx={{
          flex: 1,
          overflowX: "scroll",
          width: "100%",
        }}
        ref={scrollerRef}
      >
        <Box
          sx={{
            dispaly: "flex",
            width: `${MIN_WIDTH}px`,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ flex: 1 }}>
              <TimeGrid
                date={date}
                localizer={localizer}
                //   max={max}
                //   min={min}
                min={moment().tz("America/Phoenix").startOf("d").toDate()}
                max={moment().tz("America/Phoenix").endOf("d").toDate()}
                range={currRange}
                scrollToTime={scrollToTime}
                {...props}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

CustomMonthView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
};

CustomMonthView.range = (date, { localizer }) => {
  //   const start = localizer.add(localizer.startOf(date, "week"), 1, "day");
  //   const end = localizer.add(start, 4, "day");

  // Month
  let start = moment(date)
    .tz("America/Phoenix")
    .startOf("month")
    // .startOf("week") // extend into the previous month if needed
    .startOf("day")
    .toDate();
  let end = moment(start)
    .tz("America/Phoenix")
    .endOf("month")
    // .endOf("week") // extend into the next month if needed
    .endOf("day")
    .toDate();

  let current = start;
  const range = [];

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day");
  }

  return range;
};

CustomMonthView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, "month");

    case Navigate.NEXT:
      return localizer.add(date, 1, "month");

    default:
      return date;
  }
};

CustomMonthView.title = (date, { localizer }) => {
  const [start, ...rest] = CustomMonthView.range(date, { localizer });
  return localizer.format({ start, end: rest.pop() }, "dayRangeHeaderFormat");
};

export default CustomMonthView;
