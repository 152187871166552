import React from "react";
import OrderPickupHistory from "./OrderPickupHistory";
import { Box } from "@mui/material";

const OrderPickupHistoryPage = () => {
  return (
    <Box>
      <OrderPickupHistory />
    </Box>
  );
};

export default OrderPickupHistoryPage;
