import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { AuthContext } from "../../../../../../context/AuthContext";
import AccessoryCard from "./AccessoryCard";
import { toTwoDecimals } from "../../../../../../components/NewDetail/calculations/utils";

const Accessory = ({ accessory, onUpdateAccessory }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { authToken, ezorder } = useContext(AuthContext);
  const [catalog, setCatalog] = useState([]);
  const getAccessoriesCatalog = async () => {
    try {
      const response = await ezorder.get(`/app/accessories`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const items = response.data.accessories;
      setCatalog([...items]);
      return [...items];
    } catch (error) {
      console.log(error);
    }
  };

  const calculateMaterialCost = (quantity, costPerItem) => {
    let materialCost = toTwoDecimals(quantity * costPerItem);
    return materialCost;
  };
  const calculateMarkUp = (quantity, price, markupPercentage) => {
    let markUp = toTwoDecimals(quantity * price * (markupPercentage / 100));
    return markUp;
  };
  const calculateTotalSellPrice = (quantity, costPerItem, markupPercentage) => {
    let totalSellPrice = toTwoDecimals(
      quantity * costPerItem * (1 + markupPercentage / 100)
    );
    return totalSellPrice;
  };
  // After Getting Catalog, Add default price and calculation values into the order item
  const addDefaultAccessoryCalculationValues = async () => {
    for (let orderedAccessory of accessory.items) {
      // Update Each Item w/ Reducer if the calculation object doesnt exist
      if (!orderedAccessory.calculations) {
        console.log(
          "ADD DEFAULT ACCESSORY CALC OBJECT BECAUSE MISSING",
          orderedAccessory
        );
        // Find the Matching Catalog Item and Extract default values
        const matchingCatalogItem = catalog.find(
          (catalogItem) => catalogItem.name === orderedAccessory.name
        );
        console.log("MATCHING CATALOG ITEM", matchingCatalogItem);
        let materialCost = calculateMaterialCost(
          orderedAccessory.quantity,
          matchingCatalogItem.cost
        );

        let markUp = calculateMarkUp(
          orderedAccessory.quantity,
          matchingCatalogItem.cost,
          matchingCatalogItem.markupPercentage
        );

        let totalSellPrice = calculateTotalSellPrice(
          orderedAccessory.quantity,
          matchingCatalogItem.cost,
          matchingCatalogItem.markupPercentage
        );

        let orderedAccessoryUpdate = {
          ...orderedAccessory,
          price: matchingCatalogItem.cost,
          calculations: {
            markUpPercentage: matchingCatalogItem.markupPercentage,
            markUp: markUp,
            materialCost: materialCost,
            totalSellPrice: totalSellPrice,
          },
        };
        updateAccessoryWithTheSameName(orderedAccessoryUpdate);
      }
    }
  };

  const handleCheckBox = (e, catalogItem, orderedItem) => {
    let checked = e.target.checked;

    if (checked) {
      // Add New Item
      let name = catalogItem.name;
      let unitOfMeasure = catalogItem.unitOfMeasure;
      let price = catalogItem.cost;
      let quantity = 1;
      let markupPercentage = catalogItem.markupPercentage;

      // Calculated
      let materialCost = calculateMaterialCost(quantity, price);
      let markUp = calculateMarkUp(quantity, price, markupPercentage);
      let totalSellPrice = calculateTotalSellPrice(
        quantity,
        price,
        markupPercentage
      );

      // Add Item
      let newAccessoryItem = {
        name: name,
        unitOfMeasure: unitOfMeasure,
        price: price,
        quantity: quantity,
        calculations: {
          markUpPercentage: markupPercentage,
          markUp: markUp,
          materialCost: materialCost,
          totalSellPrice: totalSellPrice,
        },
      };

      let itemsCopy = [...accessory.items];
      itemsCopy.push(newAccessoryItem);
      onUpdateAccessory({
        ...accessory,
        items: itemsCopy,
      });
    } else {
      // Delete Item By Name
      let itemsCopy = [...accessory.items];
      const itemIndex = itemsCopy.findIndex(
        (item) => item.name === orderedItem.name
      );
      itemsCopy.splice(itemIndex, 1);
      onUpdateAccessory({
        ...accessory,
        items: itemsCopy,
      });
    }
  };

  const handleItemUpdate = (key, value, orderedItem) => {
    let price = key == "price" ? value : orderedItem.price;
    let quantity = key == "quantity" ? value : orderedItem.quantity;
    let markupPercentage =
      key == "markUpPercentage"
        ? value
        : orderedItem.calculations.markUpPercentage;

    // Calculated
    let materialCost = calculateMaterialCost(quantity, price);
    let markUp = calculateMarkUp(quantity, price, markupPercentage);
    let totalSellPrice = calculateTotalSellPrice(
      quantity,
      price,
      markupPercentage
    );

    let orderedAccessoryUpdate = {
      ...orderedItem,
      price: price,
      quantity: quantity,
      calculations: {
        markUpPercentage: markupPercentage,
        markUp: markUp,
        materialCost: materialCost,
        totalSellPrice: totalSellPrice,
      },
    };

    // Update Item by Name
    let itemsCopy = [...accessory.items];
    const itemIndex = itemsCopy.findIndex(
      (item) => item.name === orderedItem.name
    );
    itemsCopy[itemIndex] = orderedAccessoryUpdate;
    onUpdateAccessory({
      ...accessory,
      items: itemsCopy,
    });
  };
  const updateAccessoryWithTheSameName = (updatedAccessoryOrderItem) => {
    // action({
    //   type: "UPDATE ACCESSORIES: ITEM",
    //   payload: updatedAccessoryOrderItem,
    // });
  };

  useEffect(() => {
    getAccessoriesCatalog();
  }, []);

  //   useEffect(() => {
  //     if (catalog.length > 0) addDefaultAccessoryCalculationValues();
  //   }, [catalog, accessory.items]);

  if (!accessory || !accessory.items) return null;

  let accessoriesTotalSellPrice = 0;
  for (const accessoryItem of accessory.items) {
    if (accessoryItem.calculations && accessoryItem.calculations.totalSellPrice)
      accessoriesTotalSellPrice += parseFloat(
        accessoryItem.calculations.totalSellPrice
      );
  }

  return (
    <Box>
      <Typography sx={{ flex: 1 }} variant="h6">
        Accessories
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {catalog.map((catalogItem, index) => {
          const orderedItem = accessory.items.find(
            (ordered) => ordered.name === catalogItem.name
          );
          let checked = orderedItem && orderedItem.quantity > 0;
          if (checked) {
            return (
              <AccessoryCard
                key={index}
                checked={checked}
                name={orderedItem.name}
                unitOfMeasure={catalogItem.unitOfMeasure}
                cost={orderedItem.price}
                markUpPercentage={orderedItem.calculations?.markUpPercentage}
                markUp={orderedItem.calculations?.markUp}
                quantity={orderedItem.quantity}
                materialCost={orderedItem.calculations?.materialCost}
                totalSellPrice={orderedItem.calculations?.totalSellPrice}
                inStock={catalogItem.inStock}
                handleChange={(key, value) => {
                  handleItemUpdate(key, value, orderedItem);
                }}
                handleCheckBox={(e) => {
                  handleCheckBox(e, catalogItem, orderedItem);
                }}
              />
            );
          } else
            return (
              <AccessoryCard
                key={index}
                checked={checked}
                name={catalogItem.name}
                unitOfMeasure={catalogItem.unitOfMeasure}
                cost={catalogItem.cost}
                markUpPercentage={catalogItem.markupPercentage}
                markUp={0}
                quantity={0}
                materialCost={0}
                totalSellPrice={0}
                inStock={catalogItem.inStock}
                handleChange={null}
                handleCheckBox={(e) => {
                  handleCheckBox(e, catalogItem, orderedItem);
                }}
              />
            );
        })}
      </Box>
    </Box>
  );
};

export default Accessory;
