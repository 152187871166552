import {
  Box,
  Card,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ShopS3PresignedCardImage from "../../../../../components/ShopS3PresignedCardImage";
import ShopS3PresignedImageLink from "../../../../../components/ShopS3PresignedImageLink";

const BenchWork = (props) => {
  const { accessToken, benchWork } = props;
  if (!benchWork) return null;

  let trimTotalSellPrice = 0;
  for (const piece of benchWork.benchWorkPieces) {
    if (piece.calculations && piece.calculations.totalSellPrice)
      trimTotalSellPrice += parseFloat(piece.calculations.totalSellPrice);
  }

  return (
    <TableContainer component={Box} sx={{ marginTop: "8px" }}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Piece</TableCell>
            <TableCell align="right">Qty</TableCell>
            <TableCell align="right">Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {benchWork.benchWorkPieces.map((piece, index) => (
            <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              key={piece.id}
            >
              {/* <TableCell>
                <ShopS3PresignedCardImage
                  width={100}
                  s3Key={piece.image}
                  accessToken={accessToken}
                />
              </TableCell> */}
              <TableCell component="th" scope="row">
                {/* {piece.name} */}
                <ShopS3PresignedImageLink
                  linkText={piece.name}
                  s3Key={piece.image}
                  accessToken={accessToken}
                  notes={piece.notes}
                />
              </TableCell>
              <TableCell align="right">{piece.quantity}</TableCell>
              <TableCell align="right">{piece.notes}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BenchWork;
