import { Autocomplete, TextField, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import { useTheme } from "@emotion/react";

const SelectPrimaryContact = ({
  selectedCompany,
  primaryContact,
  onSelect,
}) => {
  console.log("LOAD PRIMARY CONTACT", primaryContact?.fullName);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { authToken, ezorder } = useContext(AuthContext);
  const [companyContacts, setCompanyContacts] = useState([]);
  const [search, setSearch] = useState(primaryContact?.fullName);
  const autoCompleteRef = useRef();

  const getCompanyQuoteRequesters = async (companyId) => {
    try {
      const res = await ezorder.get(
        `/admin/companies/${companyId}/estimate-requesters`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let contacts = [];
      for (const contact of res.data.quoteRequesters) {
        contacts.push({
          label: contact.fullName,
          id: contact.id,
        });
      }
      setCompanyContacts(contacts);
    } catch (error) {
      console.log(error);
    }
    return null;
  };

  useEffect(() => {
    if (selectedCompany) getCompanyQuoteRequesters(selectedCompany.id);
    else {
      setCompanyContacts([]);
    }
  }, [selectedCompany]);

  return (
    <>
      <Autocomplete
        ref={autoCompleteRef}
        disabled={!selectedCompany}
        size="small"
        fullWidth={isSmallScreen}
        disablePortal
        // inputValue={search}
        defaultValue={primaryContact?.fullName}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        onChange={(Event, newValue, reason, details) => {
          //   if (reason == "selectOption") setValue(newValue);
          onSelect(newValue);
        }}
        options={companyContacts}
        renderInput={(params) => (
          <TextField fullWidth {...params} label="Order Primary Contact" />
        )}
      />
    </>
  );
};

export default SelectPrimaryContact;
