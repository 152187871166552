import { useState, useContext, useEffect } from "react";
import usePageName from "../../../../hocs/usePageName";
import { AuthContext } from "../../../../context/AuthContext";
import AddEmployeeModal from "../AddEmployee/AddEmployeeModal";
import FabButton from "../../../../components/CustomerComponents/FabButton";
import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const EmployeeList = () => {
  usePageName({ heading: "Users" });
  const navigate = useNavigate();
  const { authToken, ezorder, decoded } = useContext(AuthContext);
  const headers = {
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [employees, setEmployees] = useState([]);

  const [openAddEmployeeModal, setOpenEmployeeModal] = useState(false);

  const getEmployees = async () => {
    try {
      setLoading(true);
      const response = await ezorder.get(
        `/company/${decoded.companyId}/users`,
        headers
      );

      console.log("EMPLOYEES", response.data.employees);
      setEmployees(response.data.employees);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };
  const handleOpen = () => {
    setOpenEmployeeModal(true);
  };
  const handleClose = () => {
    getEmployees();
    setOpenEmployeeModal(false);
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <>
      <FabButton color="success" onClick={handleOpen}>
        <Add />
        User
      </FabButton>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {employees.map((employee, index) => (
          <ListItem
            onClick={() => {
              navigate(`/users/${employee.id}`);
            }}
            key={index}
            sx={{
              cursor: "pointer",
              "&:hover": {
                background: "#eeeeee",
              },
            }}
          >
            <ListItemText
              primary={employee.fullName}
              secondary={
                <>
                  <Typography>Email: {employee.email}</Typography>
                  <Typography>Phone: {employee.phone}</Typography>
                  {employee.role == "OWNER" && (
                    <>
                      <Typography>Permissions</Typography>
                      <Typography
                        style={{
                          fontSize: 12,
                          paddingLeft: "16px",
                        }}
                      >
                        - Owner
                      </Typography>
                    </>
                  )}

                  {employee.role == "USER" && (
                    <>
                      <Typography>Permissions:</Typography>
                      {employee.permissions.map((permission, index) => {
                        return (
                          <Typography
                            style={{
                              fontSize: 12,
                              paddingLeft: "16px",
                            }}
                          >
                            -{" "}
                            {decoded.isVendorOrSupplier
                              ? permission.vendorDescription
                              : permission.description}
                          </Typography>
                        );
                      })}
                    </>
                  )}
                  {employee.deletedAt && (
                    <Typography
                      sx={{
                        color: "red",
                      }}
                    >
                      Deactivated
                    </Typography>
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </Box>

      <AddEmployeeModal
        isVendorAccount={decoded.isVendorOrSupplier}
        open={openAddEmployeeModal}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </>
  );
};
export default EmployeeList;
