import {
  Box,
  Card,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ShopS3PresignedCardImage from "../../../../../components/ShopS3PresignedCardImage";
import ShopS3PresignedImageLink from "../../../../../components/ShopS3PresignedImageLink";

const Cleat = (props) => {
  const { accessToken, cleat } = props;
  if (!cleat) return null;

  return (
    <Box {...props}>
      <TableContainer component={Card} sx={{ marginTop: "8px" }}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: "150px" }}>Piece</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Stretch Out</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {cleat.cleatPieces.map((piece, index) => (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell>
                <ShopS3PresignedCardImage
                  width={100}
                  s3Key={piece.image}
                  accessToken={accessToken}
                />
              </TableCell> */}
                <TableCell component="th" scope="row">
                  {/* {piece.name} */}
                  <ShopS3PresignedImageLink
                    linkText={piece.name}
                    s3Key={piece.image}
                    accessToken={accessToken}
                    notes={piece.notes}
                  />
                </TableCell>
                <TableCell align="right">{piece.quantity}</TableCell>
                <TableCell align="right">{piece.stretchOut}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Cleat;
