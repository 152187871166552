import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { StaticDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";

const DatePickerDialog = ({
  // view,
  open,
  value,
  onChange,
  handleClose,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      //   fullWidth={true}
      fullScreen={isSmallScreen}
    >
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <StaticDatePicker
            value={value ? value : null}
            onChange={onChange}
            timezone="America/Phoenix"
            slotProps={{
              actionBar: {
                actions: [],
              },
            }}
          />
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onChange(moment().tz("America/Phoenix").startOf("d"));
          }}
        >
          Today
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DatePickerDialog;
