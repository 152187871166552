import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { LoadingButton } from "@mui/lab";
import ReactSignatureCanvas from "react-signature-canvas";
import ShopS3PresignedCardImage from "../../../components/ShopS3PresignedCardImage";
import { CameraAlt, Close, Upload } from "@mui/icons-material";
import FileAttachment from "../../../components/FileUpload/FileAttachment";
import ShopS3PresignedImage from "../../../components/ShopS3PresignedImage";
import DownloadIcon from "@mui/icons-material/Download";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import getPickupPackingListDocDefinition from "../../../pdf-helpers/PickupPackingList";
import QRCode from "qrcode";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PickupDetails = () => {
  const { orderId, pickupId } = useParams();
  const { ezorder, authToken } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [pickupDetails, setPickupDetails] = useState(null);
  const [pickupTransactions, setPickupTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const signatureRef = useRef();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [receivedBy, setReceivedBy] = useState("");
  const [disclaimerChecked, setDisclaimerChecked] = useState(false);
  const [imageUploads, setImageUploads] = useState([]);
  const uploadRef = useRef();
  const cameraRef = useRef();
  const [uploadedFileS3Keys, setUploadedFileS3Keys] = useState([]);
  const [pickupImages, setPickupImages] = useState([]);

  const getPickupDetails = async (orderId, pickupId) => {
    try {
      setLoading(true);
      const res = await ezorder.get(
        `/shop/jobs/${orderId}/pickup-history/pickups/${pickupId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setPickupTransactions(res.data.pickup.transactions);
      setPickupDetails(res.data.pickup);
      let imgs = res.data.pickup.images;
      setPickupImages(imgs);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  const getOrderWithPickupHistory = async (orderId) => {
    try {
      setLoading(true);
      const res = await ezorder.get(`/shop/jobs/${orderId}/pickup-history`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrderDetails(res.data.order);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async () => {
    let base64Signature = signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    console.log("Signature base64Image", base64Signature);

    //setSignature(signature);
    const s3SignaturePath = await uploadSignature(base64Signature);
    console.log("Saved Signature S3 Path", s3SignaturePath);
    await signAndApprove(s3SignaturePath);
  };

  const createWritePresignedUrl = async (
    actionType,
    fileName,
    contentType,
    resource
  ) => {
    const identifier =
      orderDetails.company && orderDetails.company.id
        ? orderDetails.company.id
        : "GUEST";

    const response = await ezorder.post(
      "/files/presignedUrl",
      { identifier, actionType, fileName, contentType, resource },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.data;
  };

  function base64toBlob(dataURI) {
    var byteString = atob(dataURI.split(",")[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: "image/jpeg" });
  }

  // Returns S3 Key generated during presigning
  const uploadSignature = async (base64Signature) => {
    try {
      setIsRefreshing(true);
      const presignedUrlResponse = await createWritePresignedUrl(
        "WRITE",
        "pickup-signature.png", // this name doesn't matter
        "image/png",
        "pickup-signatures"
      );
      const presignedUploadUrl = presignedUrlResponse.uploadUrl;
      const s3Key = presignedUrlResponse.key;

      //const fileBlob = await getBlob(signatureFile.uri);
      let fileBlob = base64toBlob(base64Signature);
      await fetch(presignedUploadUrl, {
        method: "PUT",
        body: fileBlob,
        headers: {
          "Content-Type": "image/png", // DO NOT PASS IN A BEARER TOKEN
        },
      });

      // TODO If you get a weird error or non-error, AMAZON might be returning XML which fetch doesn't support...
      // SO IT WILL NOT THROW AN ERROR EVEN WITH STATUS 400
      // console.log("fetchResult " + (await fetchResult.text()).toString());
      // console.log("fetchResult " + fetchResult.toString());
      console.log("S3 Key of Uploaded Signature", s3Key);
      return s3Key;
    } catch (error) {
      console.log(error);
      return null;
    } finally {
      setIsRefreshing(false);
    }
  };

  const signAndApprove = async (signatureS3Path) => {
    try {
      setIsRefreshing(true);
      const response = await ezorder.put(
        `/shop/jobs/${orderId}/pickup-history/pickups/${pickupId}/sign`,
        {
          signature: signatureS3Path,
          receivedBy,
          disclaimerChecked,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      getOrderWithPickupHistory(orderId);
      getPickupDetails(orderId, pickupId);
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  const updatePickupImages = async () => {
    try {
      setLoading(true);
      const res = await ezorder.put(
        `/shop/jobs/${orderId}/pickup-history/pickups/${pickupId}/images`,
        {
          images: [...pickupImages, ...uploadedFileS3Keys],
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      let imgs = res.data.pickup.images;
      setPickupImages(imgs);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
      setUploadedFileS3Keys([]);
      setImageUploads([]);
    }
  };

  const getPackingList = async () => {
    try {
      // TODO: QR CODE Image
      let qrDataURL = await QRCode.toDataURL(
        `${window.location.host}/ready-for-pickup/${orderDetails.id}/pickups/${pickupId}`
      )
        .then((url) => {
          return url;
        })
        .catch((err) => {
          console.error(err);
        });

      console.log("QR CODE URL", qrDataURL);

      const docDef = getPickupPackingListDocDefinition(
        orderDetails,
        pickupDetails,
        qrDataURL
      );

      // pdfMake.createPdf(docDef).open();
      pdfMake.createPdf(docDef).download(
        // `PACKING-LIST-${order.id}-${order.company.name}-${order.project.name}.pdf`
        `PACKING-LIST.pdf`
      );
    } catch (error) {
      console.log("ERROR", error);
      throw Error("Unable to load PDF", { cause: error });
    }
  };

  const deletePickup = async () => {
    console.log("deletePickup");
    try {
      setIsRefreshing(true);
      let response = await ezorder.delete(
        `/shop/jobs/${orderId}/pickup-history/pickups/${pickupId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.status != 200) {
        alert(`Error: ${response.response.data.error}`);
      } else {
        navigate(`/ready-for-pickup/${orderId}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    console.log("uploadedFileS3Keys", uploadedFileS3Keys);
  }, [uploadedFileS3Keys]);

  useEffect(() => {
    if ((orderId, pickupId)) {
      getOrderWithPickupHistory(orderId);
      getPickupDetails(orderId, pickupId);
    }
  }, [orderId, pickupId]);

  useEffect(() => {
    console.log("PICKUP IMAGES", pickupImages);
  }, [pickupImages]);

  return (
    <Box>
      {orderDetails && (
        <>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                {orderDetails &&
                  orderDetails.company &&
                  orderDetails.project &&
                  orderDetails.orderStatus && (
                    <>
                      {!orderDetails.orderStatus ||
                      !orderDetails.orderStatus.name ||
                      orderDetails.orderStatus.name == "SAVED" ||
                      orderDetails.orderStatus.name == "QUOTE_REQUESTED" ||
                      orderDetails.orderStatus.name ==
                        "ESTIMATE_APPROVAL_NEEDED"
                        ? "Quote "
                        : "Order "}
                      #{orderDetails.id} - {orderDetails.company.name}
                      <DialogContentText sx={{ display: "block" }}>
                        {orderDetails.company.isVendorOrSupplier
                          ? "Vendor/Supplier Customer"
                          : "Project"}
                        : {orderDetails.project.name}
                      </DialogContentText>
                      {orderDetails.vendorProjectName && (
                        <DialogContentText sx={{ display: "block" }}>
                          Project: {orderDetails.vendorProjectName}
                        </DialogContentText>
                      )}
                      {orderDetails.subProject && (
                        <DialogContentText sx={{ display: "block" }}>
                          Sub-Project: {orderDetails.subProject}
                        </DialogContentText>
                      )}
                      <DialogContentText sx={{ display: "block" }}>
                        {orderDetails.poNumber
                          ? `PO#: ${orderDetails.poNumber}`
                          : ""}
                      </DialogContentText>
                    </>
                  )}
                <Button
                  sx={{ padding: 0, margin: 0 }}
                  onClick={() => {
                    navigate(`/orders/${orderDetails.id}`);
                  }}
                >
                  View Order
                </Button>
              </Box>
            </Box>
          </Box>
          {loading || !orderDetails ? (
            <Box
              sx={{
                width: "100%",
                minHeight: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size="3rem" />
            </Box>
          ) : (
            <Box sx={{ marginTop: "16px" }}>
              {/* <Typography variant="h5" sx={{ textAlign: "center" }}>
                {pickupDetails && pickupDetails.pickupType == "DELIVERY"
                  ? "Delivery"
                  : "Pickup"}{" "}
                Details
              </Typography> */}
              <Box
                sx={
                  isMediumScreen
                    ? {
                        marginTop: "32px",
                        display: "flex",
                        flexDirection: "column",
                      }
                    : {
                        marginTop: "32px",
                        display: "flex",
                        flexDirection: "row",
                      }
                }
              >
                <Box
                  sx={{
                    margin: 0,
                    padding: 0,
                    minWidth: isMediumScreen ? "100%" : "50%",
                    flex: 1,
                  }}
                >
                  <TableContainer component={Card}>
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          padding: "16px",
                        }}
                      >
                        {pickupDetails && pickupDetails.pickupType == "DELIVERY"
                          ? "Delivery"
                          : "Pickup"}{" "}
                        Items
                      </Typography>
                      <Divider />
                    </Box>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">Description</TableCell>
                          <TableCell align="left" sx={{ width: "70px" }}>
                            Type
                          </TableCell>
                          <TableCell align="left" sx={{ width: "70px" }}>
                            Quantity
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {pickupTransactions &&
                          pickupTransactions.map((transaction, index) => {
                            return (
                              <>
                                <TableRow key={transaction.id}>
                                  <TableCell align="left">
                                    {transaction.pickupItem.itemName}
                                    {/* <Typography variant="caption">
                                  {transaction.pickupItem.objectType}
                                </Typography> */}
                                  </TableCell>
                                  <TableCell align="left">
                                    {transaction.pickupItem.objectType}
                                  </TableCell>
                                  <TableCell align="left">
                                    <Typography variant="caption">
                                      {transaction.quantity}{" "}
                                      {transaction.pickupItem.uom}
                                    </Typography>
                                  </TableCell>

                                  <Divider />
                                </TableRow>
                              </>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  sx={
                    isMediumScreen
                      ? {
                          marginTop: "32px",
                          width: isMediumScreen ? "100%" : "50%",
                        }
                      : { marginLeft: "32px" }
                  }
                >
                  <Card
                    sx={{
                      padding: "16px",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography variant="h6">Packing List</Typography>
                    <IconButton
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        getPackingList();
                      }}
                    >
                      Packing List
                      <DownloadIcon />
                    </IconButton>
                    {/* <IconButton
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        getPackingListV0();
                      }}
                    >
                      Packing List (Old)
                      <DownloadIcon />
                    </IconButton> */}
                  </Card>
                  {!pickupDetails.signature && !pickupDetails.signedOn ? (
                    <Card
                      sx={{
                        padding: "16px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h6">Review & Sign</Typography>
                      <Typography sx={{ width: "100%" }}>Disclaimer</Typography>
                      <Typography sx={{ width: "100%" }}>
                        We hereby accept the quantities listed herein, and
                        release Industry Metal Co of any discrepancies in
                        regards to quantities
                      </Typography>

                      <Box sx={{ marginTop: "16px" }}>
                        <Typography>
                          <Checkbox
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                            checked={disclaimerChecked}
                            onChange={(e) => {
                              // setSaveToCatalog(e.target.checked);
                              setDisclaimerChecked(e.target.checked);
                            }}
                          />
                          I HEREBY ACCEPT THE QUANTITY AND CONDITION OF THE
                          MATERIALS PACKAGED HEREIN
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: isMediumScreen ? "center" : "flex-end",
                          marginBottom: "16px",
                        }}
                      >
                        <Box>
                          <TextField
                            fullWidth
                            sx={{ marginTop: "16px", marginBottom: "16px" }}
                            label="Print Name"
                            value={receivedBy}
                            onChange={(e) => {
                              setReceivedBy(e.target.value);
                            }}
                          />
                          {/* <Typography>Signature</Typography> */}
                          <Card
                            sx={{
                              background: "#d2d2d2",
                              width: 310,
                              height: 100,
                            }}
                          >
                            <ReactSignatureCanvas
                              ref={signatureRef}
                              backgroundColor="rgba(0, 0, 0, 0)"
                              penColor="black"
                              canvasProps={{
                                width: 310,
                                height: 100,
                                className: "sigCanvas",
                              }}
                            />
                          </Card>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <LoadingButton
                              loading={isRefreshing}
                              onClick={() => {
                                signatureRef.current.clear();
                              }}
                            >
                              Clear
                            </LoadingButton>
                            <LoadingButton
                              loading={isRefreshing}
                              onClick={() => {
                                handleApprove();
                              }}
                            >
                              Approve
                            </LoadingButton>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  ) : (
                    <Card
                      sx={{
                        padding: "16px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography variant="h6">
                        Review & Sign (Signed On{" "}
                        {moment(pickupDetails.signedOn)
                          .tz("America/Phoenix")
                          .format("MM/DD/YY")}
                        )
                      </Typography>
                      <Typography sx={{ width: "100%" }}>Disclaimer</Typography>
                      <Typography sx={{ width: "100%" }}>
                        We hereby accept the quantities listed herein, and
                        release Industry Metal Co of any discrepancies in
                        regards to quantities
                      </Typography>

                      <Box sx={{ marginTop: "16px" }}>
                        <Typography>
                          <Checkbox
                            style={{
                              margin: 0,
                              padding: 0,
                            }}
                            disabled
                            checked={pickupDetails.disclaimerChecked}
                          />
                          I HEREBY ACCEPT THE QUANTITY AND CONDITION OF THE
                          MATERIALS PACKAGED HEREIN
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: isMediumScreen ? "center" : "flex-end",
                          marginBottom: "16px",
                        }}
                      >
                        <Box>
                          <TextField
                            disabled
                            fullWidth
                            sx={{ marginTop: "16px", marginBottom: "16px" }}
                            label="Print Name"
                            value={pickupDetails.receivedBy}
                            InputProps={{ readOnly: true }}
                          />
                          {/* <Typography>Signature</Typography> */}

                          <Card
                            sx={{
                              background: "#d2d2d2",
                              // width: 310,
                              // height: 100,
                            }}
                          >
                            <ShopS3PresignedImage
                              style={
                                {
                                  // width: "310px",
                                  // height: "100px",
                                }
                              }
                              s3Key={pickupDetails.signature}
                            />
                          </Card>
                        </Box>
                      </Box>
                    </Card>
                  )}
                </Box>
              </Box>
              <Card sx={{ marginTop: "16px", padding: "16px" }}>
                <Typography variant="h6">Add Images</Typography>
                <Grid
                  container
                  padding={0}
                  spacing={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  {
                    //Saved Images
                    pickupImages &&
                      pickupImages.map((s3Key, index) => {
                        console.log("hello", s3Key);
                        return (
                          <Grid
                            item
                            key={index}
                            style={{
                              position: "relative",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <ShopS3PresignedCardImage
                              key={index}
                              width={100}
                              s3Key={s3Key}
                              style={{
                                cursor: "pointer",
                              }}
                            />
                            <Close
                              color="error"
                              style={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                let pickupImagesCopy = [...pickupImages];
                                pickupImagesCopy.splice(index, 1);
                                setPickupImages(pickupImagesCopy);
                              }}
                            />
                          </Grid>
                        );
                      })
                  }
                </Grid>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <label>
                    <input
                      ref={uploadRef}
                      style={{ display: "none" }}
                      type="file"
                      multiple
                      accept="image/*"
                      // capture="environment"
                      onChange={(event) => {
                        let filesAndLocalUrls = [];
                        for (let fileToUpload of event.target.files) {
                          const localUrl = URL.createObjectURL(fileToUpload);
                          filesAndLocalUrls.push({
                            file: fileToUpload,
                            localUrl,
                          });
                        }
                        console.log(filesAndLocalUrls);
                        setImageUploads((prev) => [
                          ...prev,
                          ...filesAndLocalUrls,
                        ]);
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        padding: 12,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: "#d2d2d2",
                          color: "#d2d2d2",
                          width: 100,
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Upload sx={{ fontSize: 40 }} />
                      </div>
                    </div>
                  </label>
                  <label>
                    <input
                      multiple
                      ref={cameraRef}
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      capture="environment"
                      onChange={(event) => {
                        let filesAndLocalUrls = [];
                        for (let fileToUpload of event.target.files) {
                          const localUrl = URL.createObjectURL(fileToUpload);
                          filesAndLocalUrls.push({
                            file: fileToUpload,
                            localUrl,
                          });
                        }
                        console.log(filesAndLocalUrls);
                        setImageUploads((prev) => [
                          ...prev,
                          ...filesAndLocalUrls,
                        ]);
                      }}
                    />
                    <div
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        padding: 12,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          borderStyle: "solid",
                          borderWidth: 1,
                          borderColor: "#d2d2d2",
                          color: "#d2d2d2",
                          width: 100,
                          height: 100,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CameraAlt sx={{ fontSize: 40 }} />
                      </div>
                    </div>
                  </label>
                </div>
                <Grid
                  container
                  padding={0}
                  spacing={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  {
                    //Unsaved Images
                    imageUploads.map((imageUpload, index) => (
                      <Grid
                        item
                        key={index}
                        style={{
                          position: "relative",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <FileAttachment
                          s3Key={imageUpload.s3Key}
                          identifier={orderDetails.company.id}
                          file={imageUpload.file}
                          localFileUrl={imageUpload.localUrl}
                          onDelete={(s3Key) => {
                            // TODO: onDelete
                            // delete s3Key from list
                            // remove imageUpload item
                            let imageUploadsCopy = [...imageUploads];
                            imageUploadsCopy.splice(index, 1);
                            setImageUploads(imageUploadsCopy);
                            let uploadedFileS3KeysCopy = [
                              ...uploadedFileS3Keys,
                            ];
                            let idxToRemove = uploadedFileS3KeysCopy.findIndex(
                              (key) => key == s3Key
                            );
                            if (idxToRemove) {
                              uploadedFileS3KeysCopy.splice(idxToRemove, 1);
                              setUploadedFileS3Keys(uploadedFileS3KeysCopy);
                            }
                          }}
                          onUploaded={(s3Key) => {
                            // TODO onUploaded
                            // console.log("UPLOADED", s3Key);
                            setUploadedFileS3Keys((prev) => [...prev, s3Key]);
                            let imageUploadsCopy = [...imageUploads];
                            imageUploadsCopy[index].s3Key = s3Key;
                            setImageUploads(imageUploadsCopy);

                            // Add uploaded images
                            // setPickupImages((prev) => [...prev, s3Key]);

                            // Delete from being uploaded
                            // let imageUploadsCopy = [...imageUploads];
                            // imageUploadsCopy.splice(index, 1);
                            // setImageUploads(imageUploadsCopy);
                            // let uploadedFileS3KeysCopy = [
                            //   ...uploadedFileS3Keys,
                            // ];
                            // let idxToRemove = uploadedFileS3KeysCopy.findIndex(
                            //   (key) => key == s3Key
                            // );
                            // if (idxToRemove) {
                            //   uploadedFileS3KeysCopy.splice(idxToRemove, 1);
                            //   setUploadedFileS3Keys(uploadedFileS3KeysCopy);
                            // }
                          }}
                        />
                      </Grid>
                    ))
                  }
                </Grid>
                <Box
                  disabled={imageUploads.length != uploadedFileS3Keys.length}
                  onClick={() => {
                    updatePickupImages();
                  }}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Button>Update Images</Button>
                </Box>
              </Card>

              <Box>
                <LoadingButton
                  loading={isRefreshing}
                  onClick={() => {
                    deletePickup();
                  }}
                >
                  Delete
                </LoadingButton>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default PickupDetails;
