import { Box } from "@mui/material";
import React from "react";
import NewPickup from "../../admin/ReadyForPickup/NewPickup";
import usePageName from "../../../hocs/usePageName";

const NewPickupPage = () => {
  usePageName({ heading: "New Pickup" });
  return (
    <Box>
      <NewPickup />
    </Box>
  );
};

export default NewPickupPage;
