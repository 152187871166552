import { Box } from "@mui/material";
import React from "react";
import SendQuoteDialog from "./SendQuoteDialog";
import QuoteForm from "./QuoteForm/QuoteForm";
import JobDetails from "./JobDetails";
import { useParams } from "react-router-dom";

const OrderPage = () => {
  const { orderId } = useParams();

  return (
    <Box>
      {/* TODO: Editable Quote Form */}
      {/* Pass in (quoteId: number|null) to load quote data or start with an empty quote object if creating a new one */}
      {/* QuoteForm in this page will always have a quoteId */}
      <QuoteForm
        quoteId={orderId}
        onSave={() => {
          // TODO: Save Order - re-generate quote pdf then save
        }}
      />

      {/* TODO: Job Details (non-editable). Pass in (jobId: number) */}
      {/* <JobDetails jobId={orderId} />

      <SendQuoteDialog /> */}
    </Box>
  );
};

export default OrderPage;
