import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  DatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AuthContext } from "../../../../context/AuthContext";
import { CheckCircle, Close } from "@mui/icons-material";
import moment from "moment-timezone";
import useShopEmployees from "../../../../hooks/useShopEmployees";

const DeleteJobDialog = ({
  dialogData,
  handleClose,
  handleSubmit,
  errorMessage,
}) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { shopEmployees } = useShopEmployees(authToken);

  const [calendarEvent, setCalendarEvent] = useState(null);
  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dateError, setDateError] = useState(false);
  const [assignedTo, setAssignedTo] = useState(null);
  const [phases, setPhases] = useState([]);

  const [scheduledSubPhases, setScheduledSubPhases] = useState([]);
  const [loadingPhases, setLoadingPhases] = useState(false);

  const [isOriginalDate, setOriginalDate] = useState(true); // To prevent updating times on inital load

  const getEventDetails = async (eventId) => {
    try {
      setLoadingPhases(true);
      const res = await ezorder.get(`/shop/schedule/events/${eventId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      let event = res.data.calendarEvent;
      setCalendarEvent(event);
      setAssignedTo(event.assignedTo);
      setDate(event.startDate);
      setStartDate(event.startDate);
      setEndDate(event.endDate);

      // setPhases(event.order.phases);
      setScheduledSubPhases([...event.subPhases]);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoadingPhases(false);
    }
  };

  const getJobPhases = async (jobId) => {
    try {
      setLoadingPhases(true);
      const res = await ezorder.get(`/shop/jobs/${jobId}/phases`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(res);
      let jobInfo = res.data.job;

      setPhases(jobInfo.phases);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoadingPhases(false);
    }
  };

  useEffect(() => {
    if (dialogData.open && dialogData.calendarEvent) {
      // TODO: get jobPhases
      getEventDetails(dialogData.calendarEvent.id);
      getJobPhases(dialogData.calendarEvent.order.id);
    } else {
      setAssignedTo(null);
      setDate(null);
      setPhases([]);
      setStartDate(null);
      setEndDate(null);
      setScheduledSubPhases([]);
    }
  }, [dialogData]);

  // When changing the date, update the date on the start and end time
  useEffect(() => {
    if (date && !isOriginalDate) {
      let start = moment(date)
        .tz("America/Phoenix")
        .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      let end = moment(date)
        .tz("America/Phoenix")
        .set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      console.log(start, end);
      setStartDate(start);
      setEndDate(end);
    }
  }, [date, isOriginalDate]);

  if (dialogData)
    return (
      <Dialog
        open={dialogData.open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
      >
        {dialogData.calendarEvent && (
          <>
            <DialogTitle sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                <Box>Delete Job</Box>
              </Box>
              <Box>
                <IconButton onClick={handleClose} sx={{}}>
                  <Close />
                </IconButton>
              </Box>
            </DialogTitle>

            <DialogContent>
              <Divider sx={{ marginBottom: "10px" }} />
              <Box>
                <DialogContentText>
                  {dialogData.calendarEvent.order &&
                    dialogData.calendarEvent.order.company &&
                    dialogData.calendarEvent.order.project &&
                    dialogData.calendarEvent.order.orderStatus && (
                      <>
                        {!dialogData.calendarEvent.order.orderStatus ||
                        !dialogData.calendarEvent.order.orderStatus.name ||
                        dialogData.calendarEvent.order.orderStatus.name ==
                          "SAVED" ||
                        dialogData.calendarEvent.order.orderStatus.name ==
                          "QUOTE_REQUESTED" ||
                        dialogData.calendarEvent.order.orderStatus.name ==
                          "ESTIMATE_APPROVAL_NEEDED"
                          ? "Quote "
                          : "Order "}
                        #{dialogData.calendarEvent.order.id} -{" "}
                        {dialogData.calendarEvent.order.company.name}
                        <DialogContentText sx={{ display: "block" }}>
                          {dialogData.calendarEvent.order.company
                            .isVendorOrSupplier
                            ? "Vendor/Supplier Customer"
                            : "Project"}
                          : {dialogData.calendarEvent.order.project.name}
                        </DialogContentText>
                        {dialogData.calendarEvent.order.vendorProjectName && (
                          <DialogContentText sx={{ display: "block" }}>
                            Project:{" "}
                            {dialogData.calendarEvent.order.vendorProjectName}
                          </DialogContentText>
                        )}
                        {dialogData.calendarEvent.order.subProject && (
                          <DialogContentText sx={{ display: "block" }}>
                            Sub-Project:{" "}
                            {dialogData.calendarEvent.order.subProject}
                          </DialogContentText>
                        )}
                        <DialogContentText sx={{ display: "block" }}>
                          {dialogData.calendarEvent.order.poNumber
                            ? `PO#: ${dialogData.calendarEvent.order.poNumber}`
                            : ""}
                        </DialogContentText>
                      </>
                    )}
                </DialogContentText>
                <DialogContentText>
                  Date:{" "}
                  {moment(date)
                    .tz("America/Phoenix")
                    .format("dddd, MMMM Do YYYY")}
                  {", "}
                  {moment(startDate)
                    .tz("America/Phoenix")
                    .format("h:mm a")} -{" "}
                  {moment(endDate).tz("America/Phoenix").format("h:mm a")}
                </DialogContentText>
                <DialogContentText>
                  Assigned To: {calendarEvent?.assignedTo?.fullName}
                </DialogContentText>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                onClick={() => {
                  setDateError(false);

                  if (endDate < startDate) setDateError("Invalid time range");

                  handleSubmit(
                    dialogData.calendarEvent.id,
                    assignedTo,
                    scheduledSubPhases,
                    startDate,
                    endDate
                  );
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    );
};

export default DeleteJobDialog;
