import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SelectProjectDialog from "./SelectProjectDialog";

const SelectProject = ({ selectedCompany, project, onSelect }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <TextField
        fullWidth
        size="small"
        disabled={selectedCompany == null || selectedCompany == undefined}
        label={
          selectedCompany && selectedCompany.isVendorOrSupplier
            ? "Vendor/Supplied Customer Name"
            : "Project"
        }
        inputProps={{ readOnly: true }}
        value={project ? project.name : ""}
        onClick={() => {
          if (selectedCompany) setOpen(true);
        }}
      />
      {selectedCompany && (
        <SelectProjectDialog
          selectedCompany={selectedCompany}
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
          onSelect={onSelect}
        />
      )}
    </>
  );
};

export default SelectProject;
