import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { Routes, Route, BrowserRouter, useLocation } from "react-router-dom";
import Dashboard from "../pages/admin/Dashboard";
import Settings from "../pages/admin/Settings/Settings";

import { QuickBooksContext } from "../context/QuickBooksContext";
import QuickBooksAuthPage from "../pages/QuickBooksAuthPage";
import TimeCards from "../pages/admin/TimeCards";
import Employees from "../pages/admin/Employees/Employees";
import CreateEmployee from "../pages/admin/Employees/CreateEmployee";
import ShopView from "../pages/admin/ShopView";
import NewOrderDetail from "../pages/admin/Orders/OrderDetails/OrderDetailsPage";
import CreateOrder from "../pages/admin/CreateNewOrderPage";
import Error404 from "../pages/error/Error404";
import ColorDetails from "../components/SettingsComponents/Colors/ColorDetails";
import PurchaseOrders from "../pages/admin/PurchaseOrders/PurchaseOrders";
import CreatePurchaseOrder from "../pages/admin/PurchaseOrders/CreatePurchaseOrder";
import PurchaseOrderDetails from "../pages/admin/PurchaseOrders/PurchaseOrderDetails";
import CreateNewOrderForCompany from "../pages/admin/CreateNewOrderForCompany";
import { SnackAlertContext } from "../context/SnackAlertContext";
import Customers from "../pages/admin/Customers/Customers";
import CustomerDetails from "../pages/admin/Customers/CustomerDetails";
import CreateCustomer from "../pages/admin/Customers/CreateCustomer";
import EditCustomerUser from "../pages/admin/Customers/EditCustomerUser";
import Sales from "../pages/admin/Sales/Sales";
import QuoteRequests from "../pages/admin/QuoteRequests/QuoteRequests";
import EmployeeDetails from "../pages/admin/Employees/EmployeeDetails";
import MasterTrimCatalog from "../pages/admin/MasterTrimCatalog/MasterTrimCatalog";
import SquareFootPricing from "../pages/admin/SquareFootPricing/SquareFootPricing";
import Orders from "../pages/admin/Orders/Orders";
import EditCustomerSettings from "../pages/admin/Customers/EditCustomerSettings";
import MasterTrimDetail from "../pages/admin/MasterTrimCatalog/MasterTrimDetail";
import PrintMasterTrimCatalog from "../pages/admin/MasterTrimCatalog/PrintMasterTrimCatalog";
import PrintCustomerStockTrimCatalog from "../pages/admin/Customers/PrintCustomerStockTrimCatalog";
import ShopSchedule from "../pages/admin/ShopSchedule/ShopSchedule";
import AdminColorCatalog from "../pages/admin/ColorCatalog/AdminColorCatalog";
import AdminPanelCatalog from "../pages/admin/PanelCatalog/AdminPanelCatalog";
import FsOptimizer from "../pages/admin/FsOptimizer/FsOptimizer";
import SentryTest from "../pages/admin/Sentry/SentryTest";
import Jobs from "../pages/admin/Jobs/Jobs";
import PickupDetailsPage from "../pages/admin/ReadyForPickup/PickupDetails";
import NewPickupPage from "../pages/admin/ReadyForPickup/NewPickupPage";
import OrderPickupHistoryPage from "../pages/admin/ReadyForPickup/OrderPickupHistoryPage";
import ReadyForPickupPage from "../pages/admin/ReadyForPickup/ReadyForPickupPage";
import NewQuotePage from "../pages/admin/QuotesV2/NewQuote/NewQuotePage";
import OrderPage from "../pages/admin/OrdersV2/Order/OrderPage";

const AdminRoutes = () => {
  const { role, authToken, companyId } = useContext(AuthContext);
  const { isQbAuthenticated } = useContext(QuickBooksContext);

  if (
    role === undefined ||
    authToken === undefined ||
    isQbAuthenticated === undefined
  ) {
    console.log(role, authToken, isQbAuthenticated);
    return <div>Loading...</div>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={isQbAuthenticated ? <Dashboard /> : <QuickBooksAuthPage />}
        >
          {/* TODO: create New Quote */}
          <Route path="/quotes/create" element={<NewQuotePage />} />
          {/* TODO: Order Page (editable or non-editable based on approval) */}
          <Route path="/quotes/:orderId" element={<NewOrderDetail />} />
          <Route path="/quotes/v2/:orderId" element={<OrderPage />} />
          <Route path="/orders/:orderId" element={<NewOrderDetail />} />
          <Route path="/orders/v2/:orderId" element={<OrderPage />} />

          <Route path="/" element={<Orders />} />
          <Route path="/sentry" element={<SentryTest />} />
          {/* Force QB Login Page */}
          <Route path="/qb" element={<QuickBooksAuthPage />} />

          <Route path="/sales" element={<Sales />} />
          <Route path="/customers" element={<Customers />} />

          <Route path="/customers/new" element={<CreateCustomer />} />
          <Route path="/customers/:id" element={<CustomerDetails />} />
          <Route
            path="/customers/:companyId/company-settings"
            element={<EditCustomerSettings />}
          />
          <Route
            path="/customers/:companyId/users/:userId"
            element={<EditCustomerUser />}
          />
          <Route path="callback" element={<QuickBooksAuthPage />} />
          <Route path="quotes" element={<QuoteRequests />} />
          <Route
            path="/orders/active"
            element={<Orders resource="orders" activeTab="active" />}
          />
          <Route
            path="/orders/in-progress"
            element={<Orders resource="orders" activeTab="in-progress" />}
          />
          <Route
            path="/orders/ready"
            element={<Orders resource="orders" activeTab="ready" />}
          />
          <Route
            path="/orders/fulfilled"
            element={<Orders resource="orders" activeTab="fulfilled" />}
          />
          <Route
            path="/orders/unpaid"
            element={<Orders resource="orders" activeTab="unpaid" />}
          />
          <Route
            path="/orders"
            element={<Orders resource="orders" activeTab="active" />}
          />

          <Route
            path="/company/:companyId/projects/:projectId/orders/create"
            element={<CreateNewOrderForCompany />}
          />
          <Route
            path="shop-view/active"
            element={<ShopView resource="shop-view" activeTab="active" />}
          />
          <Route
            path="shop-view/in-progress"
            element={<ShopView resource="shop-view" activeTab="in-progress" />}
          />
          <Route
            path="shop-view/ready"
            element={<ShopView resource="shop-view" activeTab="ready" />}
          />
          <Route
            path="shop-view"
            element={<ShopView resource="shop-view" activeTab="in-progress" />}
          />
          <Route path="jobs" element={<Jobs />} />
          <Route path="ready-for-pickup" element={<ReadyForPickupPage />} />
          <Route
            path="ready-for-pickup/:orderId"
            element={<OrderPickupHistoryPage />}
          />
          <Route
            path="ready-for-pickup/:orderId/new-pickup"
            element={<NewPickupPage />}
          />
          <Route
            path="ready-for-pickup/:orderId/pickups/:pickupId"
            element={<PickupDetailsPage />}
          />
          <Route path="shop-schedule" element={<ShopSchedule />} />
          <Route path="fs-optimizer" element={<FsOptimizer />} />
          <Route path="employees" element={<Employees />} />
          <Route path="employees/:id" element={<EmployeeDetails />} />
          <Route path="employees/new" element={<CreateEmployee />} />
          {/* <Route path="orders/create" element={<NewOrder />} /> */}

          <Route path="settings" element={<Settings />} />
          <Route path="panel-catalog" element={<AdminPanelCatalog />} />
          <Route path="color-catalog" element={<AdminColorCatalog />} />
          <Route path="color-catalog/:id" element={<ColorDetails />} />
          <Route path="time-cards" element={<TimeCards />}></Route>

          <Route
            path="/orders/:orderId/purchase-orders/new"
            element={<CreatePurchaseOrder />}
          />
          <Route path="orders/create" element={<CreateOrder />} />
          <Route path="purchase-orders" element={<PurchaseOrders />} />
          <Route path="purchase-orders/new" element={<CreatePurchaseOrder />} />
          <Route
            path="purchase-orders/:id"
            element={<PurchaseOrderDetails />}
          />
          <Route path="/master-trim-catalog" element={<MasterTrimCatalog />} />
          <Route
            path="/master-trim-catalog/print"
            element={<PrintMasterTrimCatalog />}
          />
          <Route
            path="/customer/:companyId/trims/stock"
            element={<PrintCustomerStockTrimCatalog />}
          />
          <Route
            path="/master-trim-catalog/:id"
            element={<MasterTrimDetail />}
          />
          <Route path="/sf-pricing" element={<SquareFootPricing />} />
          <Route path="*" element={<Error404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AdminRoutes;
