import { Box } from "@mui/material";
import React from "react";
import usePageName from "../../../hocs/usePageName";
import PickupDetails from "../../admin/ReadyForPickup/PickupDetails";

const PickupDetailsPage = () => {
  usePageName({ heading: "Pickup Details" });
  return (
    <Box>
      <PickupDetails />
    </Box>
  );
};

export default PickupDetailsPage;
