import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TrimAndFlashing from "./TrimAndFlashing";
import FlatSheet from "./FlatSheet";
import Coil from "./Coil";
import Panel from "./Panel";
import Accessory from "./Accessory";
import CopingCap from "./CopingCap";
import CopingCapCleat from "./CopingCapCleat";

const Items = ({ quote, defaultVariables, onUpdateQuote }) => {
  const theme = useTheme();

  const [productMap, setProductMap] = useState(new Map());

  const getProductMap = (quote) => {
    const prodMap = new Map();
    let quoteItems = quote.items ?? [];
    for (let i = 0; i < quoteItems.length; i++) {
      let orderItem = quoteItems[i];
      prodMap.set(`${orderItem.objectType}`, orderItem);
    }
    setProductMap(prodMap);
  };

  useEffect(() => {
    if (quote && quote.items) {
      getProductMap(quote);
    } else {
      setProductMap(new Map());
    }
  }, [quote]);

  return (
    <Box>
      <Box>
        {productMap.has("FlatSheet") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <FlatSheet
              flatSheet={productMap.get("FlatSheet")}
              onUpdateFlatSheet={(flatSheet) => {
                console.log("onUpdateFlatSheet", flatSheet);
                let quoteItems = quote.items ?? [];
                let quoteItemsCopy = [...quoteItems];
                for (let i = 0; i < quoteItemsCopy.length; i++) {
                  let item = quoteItemsCopy[i];
                  if (item.objectType == "FlatSheet") {
                    quoteItemsCopy[i] = flatSheet;
                  }
                }
                onUpdateQuote({ items: quoteItemsCopy });
              }}
            />
          </Box>
        )}
        {productMap.has("Coil") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <Coil
              coil={productMap.get("Coil")}
              onUpdateCoil={(coil) => {
                console.log("onUpdateCoil", coil);
                let quoteItems = quote.items ?? [];
                let quoteItemsCopy = [...quoteItems];
                for (let i = 0; i < quoteItemsCopy.length; i++) {
                  let item = quoteItemsCopy[i];
                  if (item.objectType == "Coil") {
                    quoteItemsCopy[i] = coil;
                  }
                }
                onUpdateQuote({ items: quoteItemsCopy });
              }}
            />
          </Box>
        )}
        {productMap.has("Panel") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <Panel
              coilCostPerSqFt={quote.coilCostPerSqFt}
              panel={productMap.get("Panel")}
              onUpdatePanel={(panel) => {
                console.log("onUpdatePanel", panel);
                let quoteItems = quote.items ?? [];
                let quoteItemsCopy = [...quoteItems];
                for (let i = 0; i < quoteItemsCopy.length; i++) {
                  let item = quoteItemsCopy[i];
                  if (item.objectType == "Panel") {
                    quoteItemsCopy[i] = panel;
                  }
                }
                onUpdateQuote({ items: quoteItemsCopy });
              }}
            />
          </Box>
        )}
        {productMap.has("TrimAndFlashing") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <TrimAndFlashing
              flatSheetSize={quote.flatSheetSize}
              defaultVariables={defaultVariables}
              companyId={quote.company.id}
              trimAndFlashing={productMap.get("TrimAndFlashing")}
              onUpdateTrimAndFlashing={(trimAndFlashing) => {
                console.log("onUpdateTrimAndFlashing", trimAndFlashing);
                let quoteItems = quote.items ?? [];
                let quoteItemsCopy = [...quoteItems];
                for (let i = 0; i < quoteItemsCopy.length; i++) {
                  let item = quoteItemsCopy[i];
                  if (item.objectType == "TrimAndFlashing") {
                    quoteItemsCopy[i] = trimAndFlashing;
                  }
                }
                onUpdateQuote({ items: quoteItemsCopy });
              }}
            />
          </Box>
        )}
        {productMap.has("BenchWork") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <Typography sx={{ flex: 1 }} variant="h6">
              BenchWork
            </Typography>
          </Box>
        )}
        {productMap.has("CopingCap") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <CopingCap
              flatSheetSize={quote.flatSheetSize}
              defaultVariables={defaultVariables}
              companyId={quote.company.id}
              copingCap={productMap.get("CopingCap")}
              onUpdateCopingCap={(copingCap) => {
                console.log("onUpdateCopingCap", copingCap);
                let quoteItems = quote.items ?? [];
                let quoteItemsCopy = [...quoteItems];
                for (let i = 0; i < quoteItemsCopy.length; i++) {
                  let item = quoteItemsCopy[i];
                  if (item.objectType == "CopingCap") {
                    quoteItemsCopy[i] = copingCap;
                  }
                }
                onUpdateQuote({ items: quoteItemsCopy });
              }}
            />
          </Box>
        )}
        {productMap.has("CopingCapCleat") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <CopingCapCleat
              flatSheetSize={quote.flatSheetSize}
              defaultVariables={defaultVariables}
              companyId={quote.company.id}
              copingCapCleat={productMap.get("CopingCapCleat")}
              onUpdateCopingCapCleat={(copingCapCleat) => {
                console.log("onUpdateCopingCapCleat", copingCapCleat);
                let quoteItems = quote.items ?? [];
                let quoteItemsCopy = [...quoteItems];
                for (let i = 0; i < quoteItemsCopy.length; i++) {
                  let item = quoteItemsCopy[i];
                  if (item.objectType == "CopingCapCleat") {
                    quoteItemsCopy[i] = copingCapCleat;
                  }
                }
                onUpdateQuote({ items: quoteItemsCopy });
              }}
              onUpdateQuote={onUpdateQuote}
              quote={quote}
            />
          </Box>
        )}
        {productMap.has("SplicePlate") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <Typography sx={{ flex: 1 }} variant="h6">
              SplicePlate
            </Typography>
          </Box>
        )}
        {productMap.has("Accessory") && (
          <Box>
            <Divider sx={{ marginTop: "16px", marginBottom: "16px" }} />
            <Accessory
              defaultVariables={defaultVariables}
              accessory={productMap.get("Accessory")}
              onUpdateAccessory={(accessory) => {
                console.log("onUpdateAccessory", accessory);
                let quoteItems = quote.items ?? [];
                let quoteItemsCopy = [...quoteItems];
                for (let i = 0; i < quoteItemsCopy.length; i++) {
                  let item = quoteItemsCopy[i];
                  if (item.objectType == "Accessory") {
                    quoteItemsCopy[i] = accessory;
                  }
                }
                onUpdateQuote({ items: quoteItemsCopy });
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Items;
