import { toThreeDecimals } from "../components/NewDetail/calculations/utils";

const SECTION_MARGIN = 12;
const FULL_WIDTH = 515;
const COLUMN_GAP = 20;

const HEADER = () => {
  return [
    {
      columns: [
        {
          image: "logo",
          width: 120,
        },
        {
          text: "Packing List",
          style: "header",
        },
      ],
      margin: [0, 0, 0, 8], // margin: [left, top, right, bottom]
    },
  ];
};

const LINE_TOP = () => {
  return [
    {
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
    },
  ];
};

const LINE_BELOW = () => {
  return [
    {
      canvas: [{ type: "line", x1: 0, y1: 0, x2: 515, y2: 0, lineWidth: 1 }],
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const JOB_DETAILS = (order) => {
  const {
    id,
    createdAt,
    poNumber,
    gauge,
    color,
    manufacturer,
    outTheDoorCost,
    company,
    project,
    notes,
    taxExemptForm,
    type,
    isCustomMaterial,
    customColor,
    customManufacturer,
    customGauge,
  } = order;

  let jobName;
  let customerName;
  let contactName;
  let contactEmail;
  let contactPhone;

  // GUEST VS MOBILE

  jobName = project.name;
  customerName = company.name;
  contactName = company.contactName;
  contactPhone = company.contactPhone;
  contactEmail = company.contactEmail;

  // Custom Manufacturer vs Regular
  let colorValue;
  let manufacturerValue;
  let gaugeValue;
  if (isCustomMaterial) {
    colorValue = customColor ? customColor : "";
    manufacturerValue = customManufacturer ? customManufacturer : "";
    gaugeValue = customGauge ? customGauge : "";
  } else {
    colorValue = color;
    manufacturerValue = manufacturer;
    gaugeValue = gauge;
  }

  return [
    {
      text: `JOB #${order.id}`,
      style: "subheader",
    },
    ...LINE_TOP(),
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*", "*"],

        body: [
          [
            { text: "CUSTOMER NAME:", style: "detailName" },
            { text: "PO #:", style: "detailName" },
            { text: "JOB NAME:", style: "detailName" },
            { text: "GAUGE:", style: "detailName" },
          ],
          [
            { text: `${customerName}`, style: "detailValue" },
            { text: `${poNumber}`, style: "detailValue" },
            { text: `${jobName}`, style: "detailValue" },
            { text: `${gaugeValue}G`, style: "detailValue" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*", "*"],

        body: [
          [
            { text: "COLOR:", style: "detailName" },
            { text: "MANUFACTURER:", style: "detailName" },
            { text: "", style: "detailName" },
            { text: "", style: "detailName" },
          ],
          [
            { text: `${colorValue}`, style: "detailValue" },
            { text: `${manufacturerValue}`, style: "detailValue" },
            { text: "", style: "detailValue" },
            { text: "", style: "detailValue" },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    ...LINE_BELOW(),
  ];
};

const ACCESSORIES = (accessoriesOrderItem, accessoryTransactions) => {
  if (!accessoriesOrderItem || !accessoryTransactions) {
    return [];
  }

  const items = [];
  let total = 0;
  for (let i = 0; i < accessoriesOrderItem.items.length; i++) {
    let item = accessoriesOrderItem.items[i];

    for (let accessoryTransaction of accessoryTransactions) {
      if (accessoryTransaction.pickupItem.itemName == item.name) {
        total += accessoryTransaction.quantity;

        items.push([
          { text: `${item.name}`, style: "tableCell" },
          { text: `${accessoryTransaction.quantity}`, style: "tableCell" },
          { text: `${item.unitOfMeasure}`, style: "tableCell" },
        ]);
      }
    }
  }

  return [
    {
      text: `ACCESSORIES / MISC`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*"],

        body: [
          [
            {
              text: "DESCRIPTION",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
            { text: "UOM", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          ...items,
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const FLAT_SHEET = (flatSheetItem, flatSheetTransactions) => {
  if (!flatSheetItem || !flatSheetTransactions) {
    return [];
  }

  return [
    {
      text: `FLAT SHEETS`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      unbreakable: true,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", 80],
        body: [
          [
            {
              text: "WIDTH (IN.)",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          [
            { text: `${flatSheetItem.width}`, style: "tableCell" },
            {
              text: `${flatSheetTransactions[0].quantity}`,
              style: "tableCell",
            },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: ``,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const COIL = (coilItem, coilTransactions) => {
  if (!coilItem || !coilTransactions) {
    return [];
  }

  return [
    {
      text: `COIL`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      unbreakable: true,
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", 80],
        body: [
          [
            { text: "WIDTH (IN.)", style: "tableHeader", fillColor: "#D3D3D3" },
            {
              text: "LINEAL FEET OF COIL",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
          ],
          [
            { text: `${coilItem.width}`, style: "tableCell" },
            {
              text: `${coilTransactions[0].quantity}`,
              style: "tableCell",
            },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      text: ``,
      style: "subheader",
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

// Longest to shortest
const sortPanelCutList = (panelCuts) => {
  panelCuts.sort((a, b) => {
    let aLength = parseFloat(a.feet) + parseFloat(a.inches) / 12;
    let bLength = parseFloat(b.feet) + parseFloat(b.inches) / 12;
    return bLength - aLength;
  });
};

const PANEL = (panelOrderItem, panelTransactions) => {
  // return empty array if panelOrderItem does not exist
  if (!panelOrderItem) return [];

  let panelCuts = [];
  let cutList = panelOrderItem.cuts;
  sortPanelCutList(cutList);

  for (let i = 0; i < cutList.length; i++) {
    let panelCut = cutList[i];
    for (let panelTransaction of panelTransactions) {
      if (panelTransaction.pickupItem.itemName == panelCut.name) {
        panelCuts.push([
          { text: `${panelCut.name}`, style: "tableCell" },
          { text: `${panelTransaction.quantity}`, style: "tableCell" },
          { text: `${panelCut.feet}`, style: "tableCell" },
          { text: `${panelCut.inches}`, style: "tableCell" },
          // { text: `${panelCut.linealFeet}`, style: "tableCell" }, // this is currently null, manually convert for now
          {
            // lineal feet of cut x quantity
            text: `${toThreeDecimals(
              panelTransaction.quantity * (panelCut.feet + panelCut.inches / 12)
            )}`,
            style: "tableCell",
          },
        ]);
      }
    }
  }

  return [
    {
      text: `PANEL CUTLIST`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    ...LINE_TOP(),
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*", "*", 100],

        body: [
          [
            { text: "SYSTEM:", style: "detailName" },
            { text: "PANEL NAME:", style: "detailName" },
            { text: "PANEL WIDTH:", style: "detailName" },
            { text: "MASTER COIL WIDTH:", style: "detailName" },
          ],
          [
            { text: `${panelOrderItem.panelSystem}`, style: "detailValue" },
            { text: `${panelOrderItem.panelType}`, style: "detailValue" },
            { text: `${panelOrderItem.panelWidth}`, style: "detailValue" },
            {
              text: `${panelOrderItem.calculations.masterCoilSizeNeeded}"`,
              style: "detailValue",
            },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    ...LINE_BELOW(),
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: [200, "*", "*", "*", "*"],

        body: [
          [
            {
              text: " PANEL LOCATION NAME",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
            { text: "FEET", style: "tableHeader", fillColor: "#D3D3D3" },
            { text: "INCHES", style: "tableHeader", fillColor: "#D3D3D3" },
            { text: "TOTAL LF", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          ...panelCuts,
        ],
      },
      margin: [0, 8, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders",
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: [200, "*", "*", "*", "*"],
        body: [
          [
            { text: ``, width: "*", style: "tableCell" },
            { text: ``, width: "*", style: "tableCell" },
            { text: ``, width: "*", style: "tableCell" },
            { text: ``, width: "*", style: "tableCell" },
            {
              text: `${panelOrderItem.calculations.linealFeetOfCoilNoWaste}`,
              style: "tableTotal",
            },
          ],
        ],
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const TRIM_AND_FLASHING = (trimOrderItem, trimTransactions) => {
  if (!trimOrderItem || !trimTransactions) return [];

  const trimPieces = [];
  let total = 0;
  for (let i = 0; i < trimOrderItem.trimPieces.length; i++) {
    let trimPiece = trimOrderItem.trimPieces[i];

    for (let trimTransaction of trimTransactions) {
      if (trimTransaction.pickupItem.itemName == trimPiece.name) {
        total += trimTransaction.quantity;

        trimPieces.push([
          { text: `${trimPiece.name}`, style: "tableCell" },
          { text: `${trimTransaction.quantity}`, style: "tableCell" },
        ]);
      }
    }
  }

  return [
    {
      text: `TRIM & FLASHING`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "PIECE NAME",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          ...trimPieces,
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders",
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            { text: ``, width: "*", style: "tableCell" },
            { text: `${total}`, style: "tableTotal" },
          ],
        ],
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const COPING_CAP = (copingCapOrderItem, copingCapTransactions) => {
  if (!copingCapOrderItem) return [];

  const capPieces = [];
  let total = 0;
  for (let i = 0; i < copingCapOrderItem.capPieces.length; i++) {
    let capPiece = copingCapOrderItem.capPieces[i];

    for (let copingCapTransaction of copingCapTransactions) {
      if (copingCapTransaction.pickupItem.itemName == capPiece.name) {
        total += copingCapTransaction.quantity;

        capPieces.push([
          { text: `${capPiece.name}`, style: "tableCell" },
          { text: `${copingCapTransaction.quantity}`, style: "tableCell" },
        ]);
      }
    }
  }

  return [
    {
      text: `COPING CAP`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "NAME / DESCRIPTION",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          ...capPieces,
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders",
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            { text: ``, width: "*", style: "tableCell" },
            { text: `${total}`, style: "tableTotal" },
          ],
        ],
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const CLEAT = (cleatOrderItem, cleatTramsactions) => {
  if (!cleatOrderItem || !cleatTramsactions) return [];

  const cleatPieces = [];
  let total = 0;
  for (let i = 0; i < cleatOrderItem.cleatPieces.length; i++) {
    let cleatPiece = cleatOrderItem.cleatPieces[i];

    for (let cleatTramsaction of cleatTramsactions) {
      if (cleatTramsaction.pickupItem.itemName == cleatPiece.name) {
        total += cleatTramsaction.quantity;

        cleatPieces.push([
          { text: `${cleatPiece.name}`, style: "tableCell" },
          { text: `${cleatTramsaction.quantity}`, style: "tableCell" },
        ]);
      }
    }
  }

  let lineSeparatorWidth = (FULL_WIDTH - COLUMN_GAP) / 2;

  return [
    {
      text: `CLEAT`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 0,
          x2: lineSeparatorWidth,
          y2: 0,
          lineWidth: 1,
        },
      ],
    },
    {
      layout: "noBorders", // optional
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*"],

        body: [
          [{ text: "GAUGE:", style: "detailName" }],
          [
            {
              text: `${cleatOrderItem.gauge}`,
              style: "detailValue",
            },
          ],
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      // Full width is: 515, minus 20 for the gap
      canvas: [
        {
          type: "line",
          x1: 0,
          y1: 0,
          x2: lineSeparatorWidth,
          y2: 0,
          lineWidth: 1,
        },
      ],
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "CLEAT DESCRIPTION",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          ...cleatPieces,
        ],
      },
      margin: [0, 8, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders",
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            { text: ``, width: "*", style: "tableCell" },
            { text: `${total}`, style: "tableTotal" },
          ],
        ],
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const SPLICE_PLATES = (splicePlatesOrderItem, splicePlateTransactionss) => {
  if (!splicePlatesOrderItem || !splicePlateTransactionss) return [];

  const plates = [];
  let total = 0;
  for (let i = 0; i < splicePlatesOrderItem.plates.length; i++) {
    let plate = splicePlatesOrderItem.plates[i];

    for (let splicePlateTransaction of splicePlateTransactionss) {
      let nameSplit = splicePlateTransaction.pickupItem.itemName.split(`"`); // 10.000" Splice Plate

      if (Number(nameSplit[0]) == Number(plate.length)) {
        total += splicePlateTransaction.quantity;

        plates.push([
          { text: `${plate.length}"`, style: "tableCell" },
          { text: `${splicePlateTransaction.quantity}`, style: "tableCell" },
        ]);
      }
    }
  }

  return [
    {
      text: `SPLICE PLATES`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "PIECE LENGTH",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          ...plates,
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders",
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            { text: ``, width: "*", style: "tableCell" },
            { text: `${total}`, style: "tableTotal" },
          ],
        ],
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const BENCH_WORK = (benchWorkOrderItem, benchWorkTransactions) => {
  if (!benchWorkOrderItem || !benchWorkTransactions) return [];

  const benchWorkPieces = [];
  let total = 0;
  for (let i = 0; i < benchWorkOrderItem.benchWorkPieces.length; i++) {
    for (let benchWorkTransaction of benchWorkTransactions) {
      let benchWorkPiece = benchWorkOrderItem.benchWorkPieces[i];
      if (benchWorkTransaction.pickupItem.itemName == benchWorkPiece.name) {
        total += benchWorkTransaction.quantity;

        benchWorkPieces.push([
          { text: `${benchWorkPiece.name}`, style: "tableCell" },
          { text: `${benchWorkTransaction.quantity}`, style: "tableCell" },
        ]);
      }
    }
  }

  return [
    {
      text: `BENCH WORK`,
      style: "subheader",
      margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            {
              text: "DESCRIPTION",
              style: "tableHeader",
              fillColor: "#D3D3D3",
            },
            { text: "QTY", style: "tableHeader", fillColor: "#D3D3D3" },
          ],
          ...benchWorkPieces,
        ],
      },
      margin: [0, 4, 0, 0], // margin: [left, top, right, bottom]
    },
    {
      layout: "noBorders",
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ["*", "*"],
        body: [
          [
            { text: ``, width: "*", style: "tableCell" },
            { text: `${total}`, style: "tableTotal" },
          ],
        ],
      },
      margin: [0, 0, 0, 0], // margin: [left, top, right, bottom]
    },
  ];
};

const TWO_COLUMNS = (contents1, contents2) => {
  return [
    {
      columnGap: COLUMN_GAP,
      columns: [
        {
          width: "*",
          stack: [[...contents1]],
        },
        {
          width: "*",
          stack: [[...contents2]],
        },
      ],
    },
  ];
};

const SIMPLE = (productMap, pickupMap) => {
  // count how many simple products
  let simpleProducts = [];

  const trimOrderItem = productMap.get("TrimAndFlashing");
  const copingCapOrderItem = productMap.get("CopingCap");
  const cleatOrderItem = productMap.get("CopingCapCleat");
  const splicePlatesOrderItem = productMap.get("SplicePlate");
  const benchWorkOrderItem = productMap.get("BenchWork");

  const trimPickupItem = pickupMap.get("TrimAndFlashing");
  const copingCapPickupItem = pickupMap.get("CopingCap");
  const cleatPickupItem = pickupMap.get("CopingCapCleat");
  const splicePlatesPickupItem = pickupMap.get("SplicePlate");
  const benchWorkPickupItem = pickupMap.get("BenchWork");

  if (trimOrderItem && trimPickupItem) {
    simpleProducts.push(TRIM_AND_FLASHING(trimOrderItem, trimPickupItem));
  }
  if (copingCapOrderItem && copingCapPickupItem) {
    simpleProducts.push(COPING_CAP(copingCapOrderItem, copingCapPickupItem));
  }
  if (cleatOrderItem && cleatPickupItem) {
    simpleProducts.push(CLEAT(cleatOrderItem, cleatPickupItem));
  }
  if (splicePlatesOrderItem && splicePlatesPickupItem) {
    simpleProducts.push(
      SPLICE_PLATES(splicePlatesOrderItem, splicePlatesPickupItem)
    );
  }
  if (benchWorkOrderItem && benchWorkPickupItem) {
    simpleProducts.push(BENCH_WORK(benchWorkOrderItem, benchWorkPickupItem));
  }

  // Set Columns for existing simple products

  let definition = [];

  let productIndex = 0;
  while (productIndex < simpleProducts.length) {
    let left = simpleProducts[productIndex];
    let right = [];
    if (productIndex + 1 < simpleProducts.length) {
      right = simpleProducts[productIndex + 1];
    }

    definition = [...definition, ...TWO_COLUMNS(left, right)];
    productIndex += 2;
  }

  return definition;
};

const SIGNATURE = () => {
  return [
    {
      margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
      unbreakable: true,
      stack: [
        [
          {
            text: "Disclaimer",
            bold: true,
            fontSize: 10,
            margin: [0, SECTION_MARGIN, 0, 0], // margin: [left, top, right, bottom]
          },
        ],
        [
          {
            text: "We hereby accept the quantities listed herein, and release Industry Metal Co of any discrepancies in regards to quantities.",
            fontSize: 10,
          },
        ],
        [
          {
            text: "I HEREBY ACCEPT THE QUANTITY AND CONDITION OF THE MATERIALS PACKAGED HEREIN",
            bold: true,
            fontSize: 10,
            margin: [0, 10, 0, 0], // margin: [left, top, right, bottom]
          },
        ],
        [
          {
            text: "X __________________________________",
            bold: true,
            fontSize: 16,
          },
        ],
      ],
    },
    {
      columns: [
        {
          image: "qrCode",
          width: 120,
        },
      ],
      margin: [0, 0, 0, 8], // margin: [left, top, right, bottom]
    },
  ];
};

const getPickupPackingListDocDefinition = (order, pickup, qrDataURL) => {
  // TODO: adjust the product map to only include items on the pickup
  let pickupMap = new Map();
  for (let i = 0; i < pickup.transactions.length; i++) {
    let pickupTransaction = pickup.transactions[i];
    if (pickupMap.has(pickupTransaction.objectType)) {
      let existingItems = pickupMap.get(pickupTransaction.objectType);
      pickupMap.set(pickupTransaction.objectType, [
        ...existingItems,
        pickupTransaction,
      ]);
    } else {
      pickupMap.set(pickupTransaction.objectType, [pickupTransaction]);
    }
  }

  // Put order items in a map by type
  let productMap = new Map();
  for (let i = 0; i < order.items.length; i++) {
    let orderItem = order.items[i];
    productMap.set(`${orderItem.objectType}`, orderItem);
  }

  var docDefinition = {
    pageSize: "A4",
    content: [
      ...HEADER(),
      ...JOB_DETAILS(order),
      ...ACCESSORIES(productMap.get("Accessory"), pickupMap.get("Accessory")),
      ...FLAT_SHEET(productMap.get("FlatSheet"), pickupMap.get("FlatSheet")),
      ...COIL(productMap.get("Coil"), pickupMap.get("Coil")),
      ...PANEL(productMap.get("Panel"), pickupMap.get("Panel")),
      ...SIMPLE(productMap, pickupMap),
      ...SIGNATURE(),
    ],

    footer: function (currentPage, pageCount) {
      return {
        margin: [40, 10, 40, 0], // margin: [left, top, right, bottom]
        columns: [
          {
            text: `Created on ${new Date().toDateString()}`,
            alignment: "left",
            style: "footerText",
          },
          {
            text: currentPage.toString() + " of " + pageCount,
            alignment: "center",
            style: "footerText",
          },
          // {
          //   text: "EZ-ORDER Ver.1.0",
          //   alignment: "right",
          //   style: "footerText",
          // },
        ],
      };
    },

    styles: {
      header: {
        fontSize: 24,
        bold: true,
        alignment: "right",
      },
      subheader: {
        bold: true,
        fontSize: 10,
      },
      detailName: {
        bold: true,
        fontSize: 10,
      },
      detailValue: {
        fontSize: 8,
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        alignment: "center",
      },
      tableCell: {
        fontSize: 8,
        alignment: "center",
      },
      tableTotal: {
        fontSize: 8,
        bold: true,
        alignment: "center",
      },
      footerText: {
        fontSize: 8,
      },
    },
    defaultStyle: {
      fontSize: 12,
    },
    images: {
      // logo: "https://ezorder-public.s3.us-east-2.amazonaws.com/logo_black_horizontal.png",
      logo: "https://ezorder-public.s3.us-east-2.amazonaws.com/industry-metal-co-long-logo.png",
      qrCode: qrDataURL,
    },
  };

  return docDefinition;
};

export default getPickupPackingListDocDefinition;
