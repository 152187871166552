import {
  Box,
  Card,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ExpandableImage from "../../../../../../components/ExpandableImage";
import usePresignedUrl from "../../../../../../hooks/usePresignedUrl";
import { useTheme } from "@emotion/react";
import { Close } from "@mui/icons-material";
import { toTwoDecimals } from "../../../../../../components/NewDetail/calculations/utils";
import TextFieldDebouncedNoInitialLoad from "../../../../../../components/TextFieldDebouncedNoInitialLoad";

const TrimPiece = ({ piece, onUpdatePiece, onRemovePiece }) => {
  const theme = useTheme();
  const presignedUrl = usePresignedUrl(piece.trim.image);

  return (
    <Box
      sx={{
        padding: "16px",
        display: "flex",
        flexDirection: "row",
        position: "relative",
        marginBottom: "8px",
      }}
    >
      <ExpandableImage
        src={presignedUrl}
        imageTitle={piece.trim.name}
        // thumbnailHeight={100}
        // thumbnailWidth={100}
      />
      <Stack direction={"column"} spacing={1} sx={{ marginLeft: "8px" }}>
        <TextFieldDebouncedNoInitialLoad
          size="small"
          label="Name"
          value={piece.trim.name}
          onChange={(e) => {
            onUpdatePiece({
              ...piece,
              name: e.target.value,
            });
          }}
        />

        {/* <TextFieldDebouncedNoInitialLoad
          size="small"
          label="Notes"
          value={piece.notes}
          onChange={(e) => {
            onUpdatePiece({
              ...piece,
              notes: e.target.value,
            });
          }}
        /> */}

        <TextFieldDebouncedNoInitialLoad
          size="small"
          type="number"
          onFocus={(e) => e.target.select()}
          label="Quantity"
          value={piece.quantity}
          onChange={(e) => {
            onUpdatePiece({
              ...piece,
              quantity: parseFloat(e.target.value).toFixed(0),
            });
          }}
        />
        <TextFieldDebouncedNoInitialLoad
          size="small"
          sx={{
            fieldset: {
              borderColor: piece.trim.isMasterCatalog
                ? null
                : theme.palette.primary.main,
              borderWidth: 3,
            },
          }}
          type="number"
          onFocus={(e) => e.target.select()}
          label="Stretch Out"
          value={piece.stretchOut}
          disabled={piece.trim.isMasterCatalog}
          onChange={(e) => {
            onUpdatePiece({
              ...piece,
              stretchOut: e.target.value,
            });
          }}
        />
        <TextFieldDebouncedNoInitialLoad
          size="small"
          sx={{
            fieldset: {
              borderColor: piece.trim.isMasterCatalog
                ? null
                : theme.palette.primary.main,
              borderWidth: 3,
            },
          }}
          type="number"
          onFocus={(e) => e.target.select()}
          label="Hits"
          value={piece.hits}
          disabled={piece.trim.isMasterCatalog}
          // onChange={(e) => {
          //   e.target.value = parseFloat(e.target.value).toFixed(0);
          //   handleInlineEdit("hits")(e);
          // }}
          onChange={(e) => {
            onUpdatePiece({
              ...piece,
              hits: parseFloat(e.target.value).toFixed(0),
            });
          }}
        />
      </Stack>
      {piece.calculations && (
        <Stack direction={"column"} spacing={1} sx={{ marginLeft: "8px" }}>
          <TextFieldDebouncedNoInitialLoad
            size="small"
            type="number"
            onFocus={(e) => e.target.select()}
            label="Material Cost"
            symbol="$"
            position="start"
            value={piece.calculations.materialCost}
            // onChange={(e) => handleChange("material_cost")(e)}
            // isOveridden={overrides?.material_cost}
            // handleReset={(e) => resetOverride("material_cost")(e)}
          />
          <TextFieldDebouncedNoInitialLoad
            size="small"
            type="number"
            onFocus={(e) => e.target.select()}
            label="Est. Labor Hrs."
            value={toTwoDecimals(
              piece.calculations.estimatedLaborHours
            ).toFixed(0)}
            // onChange={(e) => {
            //   e.target.value = parseFloat(e.target.value).toFixed(0);
            //   handleChange("total_estimated_labor_hours")(e);
            // }}
            // isOveridden={overrides?.total_estimated_labor_hours}
            // handleReset={(e) => resetOverride("total_estimated_labor_hours")(e)}
          />
          <TextFieldDebouncedNoInitialLoad
            size="small"
            type="number"
            onFocus={(e) => e.target.select()}
            label="Est. Labor Cost"
            value={piece.calculations.estimatedLaborCost}
            // onChange={(e) => handleChange("total_estimated_labor_cost")(e)}
            // isOveridden={overrides?.total_estimated_labor_cost}
            // handleReset={(e) => resetOverride("total_estimated_labor_cost")(e)}
            symbol="$"
            position="start"
          />
          <TextFieldDebouncedNoInitialLoad
            size="small"
            type="number"
            onFocus={(e) => e.target.select()}
            label="Material & labor"
            symbol="$"
            position="start"
            value={piece.calculations.materialAndLabor}
            // onChange={(e) => handleChange("material_and_labor")(e)}
            // isOveridden={overrides?.material_and_labor}
            // handleReset={(e) => resetOverride("material_and_labor")(e)}
          />
        </Stack>
      )}

      {piece.calculations && (
        <Stack direction={"column"} spacing={1} sx={{ marginLeft: "8px" }}>
          {/* <TextFieldDebouncedNoInitialLoad
            size="small"
            type="number"
            onFocus={(e) => e.target.select()}
            label="GP %"
            value={
              piece.calculations.grossProfitMargin
                ? toTwoDecimals(piece.calculations.grossProfitMargin * 100.0)
                : 0
            }
            // onChange={(e) => handleChange("gross_profit_margin")(e)}
            // isOveridden={overrides?.gross_profit_margin}
            // handleReset={(e) => resetOverride("gross_profit_margin")(e)}
          />
          <TextFieldDebouncedNoInitialLoad
            disabled
            size="small"
            type="number"
            onFocus={(e) => e.target.select()}
            label="GP $"
            value={piece.calculations.grossProfitDollars}
            // onChange={(e) => {
            //   e.target.value = parseFloat(e.target.value).toFixed(0);
            //   handleChange("gross_profit_dollars")(e);
            // }}
            // isOveridden={overrides?.gross_profit_dollars}
            // handleReset={(e) => resetOverride("gross_profit_dollars")(e)}
          /> */}
          <TextFieldDebouncedNoInitialLoad
            disabled
            size="small"
            type="number"
            onFocus={(e) => e.target.select()}
            label="Sell Price"
            value={piece.calculations.totalSellPrice}
            // onChange={(e) => handleChange("total_sell_price")(e)}
            // isOveridden={overrides?.total_sell_price}
            // handleReset={(e) => resetOverride("total_sell_price")(e)}
            symbol="$"
            position="start"
          />
          <TextFieldDebouncedNoInitialLoad
            disabled
            size="small"
            type="number"
            onFocus={(e) => e.target.select()}
            label="Price Per Piece"
            value={piece.calculations.pricePerPiece}
            symbol="$"
            position="start"
            // onChange={(e) => handleChange("price_per_piece")(e)}
            // isOveridden={overrides?.price_per_piece}
            // handleReset={(e) => resetOverride("price_per_piece")(e)}
          />
        </Stack>
      )}

      <IconButton
        sx={{ position: "absolute", top: 0, right: 0 }}
        onClick={onRemovePiece}
      >
        <Close />
      </IconButton>
    </Box>
  );
};

export default TrimPiece;
