import Box from "@mui/material/Box";
import { Outlet, useNavigate } from "react-router-dom";
import ErrorBoundary from "../error/ErrorBoundary";
import SnackAlert from "../../components/SnackAlert";
import { useTheme } from "@emotion/react";
import { useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { SnackAlertContext } from "../../context/SnackAlertContext";

const AuthDashboard = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const {
    openSnackAlert,
    setOpenSnackAlert,
    snackAlertSeverity,
    snackAlertTitle,
    snackAlertMessage,
  } = useContext(SnackAlertContext);
  return (
    <div>
      <div
        style={{
          // minWidth: "100vw",
          minHeight: "100vh",
          background: theme.palette.appBar
            ? theme.palette.appBar.background
            : "inherit",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "24px",
            paddingBottom: "24px",
          }}
        >
          <img
            src="/industry-metal-co-square-logo-light-outlinedicon.png"
            height={150}
            onClick={() => navigate("/")}
          />
        </Box>
        <ErrorBoundary lightText>
          <Outlet />
        </ErrorBoundary>
      </div>
      <SnackAlert
        open={openSnackAlert}
        setOpen={setOpenSnackAlert}
        severity={snackAlertSeverity}
        title={snackAlertTitle}
        message={snackAlertMessage}
      />
    </div>
  );
};

export default AuthDashboard;
