import {
  Box,
  CircularProgress,
  Modal,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import WorkOrderButton from "../../../components/PdfButtons/WorkOrderButton";
import PackingListButton from "../../../components/PdfButtons/PackingListButton";
import { useTheme } from "@emotion/react";
import { toTwoDecimals } from "../../../components/NewDetail/calculations/utils";

const OrderTableRow = ({ onSelect, order }) => {
  const theme = useTheme();
  const [loadingInvoice, setIsLoadingInvoice] = useState(false);
  const { authToken, ezorder } = useContext(AuthContext);
  const [invoice, setInvoice] = useState(null);
  const [pdfDialogOpen, setPdfDialogOpen] = useState(false);

  // TODO: query backend to query QB for invoice details
  // const getInvoiceDetails = async (controller) => {
  //   try {
  //     setIsLoadingInvoice(true);
  //     let data = {
  //       headers: {
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     };
  //     if (controller) {
  //       data.signal = controller.signal;
  //     }

  //     const response = await ezorder.get(
  //       `/admin/orders/${order.id}/invoice`,
  //       data
  //     );

  //     setInvoice(response.data.invoice);
  //   } catch (error) {
  //     console.log("Failed To Load Invoice for Order", order.id);
  //   } finally {
  //     setIsLoadingInvoice(false);
  //   }
  // };

  // useEffect(() => {
  //   // console.log("OrderTableRow useEffect()");
  //   const controller = new AbortController();
  //   if (
  //     (order !== null || order !== undefined) &&
  //     order.invoiceId &&
  //     !isPendingQuote
  //   ) {
  //     // console.log("Invoice ID found for Order: ", order.name);

  //     getInvoiceDetails(controller);
  //   }

  //   return () => {
  //     controller.abort(); // abort api call on unmount for cleanup
  //   };
  // }, [order]);

  return (
    <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell
        onClick={() => {
          onSelect(order);
        }}
      >
        {order.id}
      </TableCell>

      <TableCell
        onClick={() => {
          onSelect(order);
        }}
      >
        {order.project.name}
      </TableCell>

      <TableCell
        onClick={() => {
          onSelect(order);
        }}
      >
        {order.company.creditType}
      </TableCell>

      <TableCell
        align="right"
        onClick={() => {
          onSelect(order);
        }}
      >
        {order.estimateLastSentForApproval
          ? moment(order.estimateLastSentForApproval).format("MM/DD/YY")
          : "N/A"}
      </TableCell>

      <TableCell
        onClick={() => {
          onSelect(order);
        }}
      >
        {moment(order.estimateApprovalDate).format("MM/DD/YY")}
      </TableCell>
      {/* {!isPendingQuote && (
        <TableCell
          allign="right"
          onClick={() => {
            onSelect(order);
          }}
        >
          {loadingInvoice ? (
            <CircularProgress sx={{ margin: "4px" }} />
          ) : (
            <Typography sx={{ textAlign: "right" }}>
              {invoice ? `$${invoice.Balance}` : "N/A"}
            </Typography>
          )}
        </TableCell>
      )}
      {!isPendingQuote && (
        <TableCell
          allign="right"
          onClick={() => {
            onSelect(order);
          }}
        >
          {loadingInvoice ? (
            <CircularProgress sx={{ margin: "4px" }} />
          ) : (
            <Typography sx={{ textAlign: "right" }}>
              {invoice
                ? `${moment(invoice.DueDate).format("MM/DD/YY")}`
                : "N/A"}
            </Typography>
          )}
        </TableCell>
      )} */}

      <TableCell>${toTwoDecimals(order.outTheDoorCost)}</TableCell>
      {/* <TableCell
        align="right"
        onClick={() => {
          if (
            order.estimatePdf
            // order.orderStatus.name === "ESTIMATE_APPROVED" ||
            // order.orderStatus.name === "PENDING_PAYMENT" ||
            // order.orderStatus.name === "IN_PROGRESS" ||
            // order.orderStatus.name === "READY_FOR_PICKUP" ||
            // order.orderStatus.name === "CLOSED"
          )
            setPdfDialogOpen(true);
        }}
      >
        {order.estimatePdf ? (
          // order.orderStatus.name === "ESTIMATE_APPROVED" ||
          // order.orderStatus.name === "PENDING_PAYMENT" ||
          // order.orderStatus.name === "IN_PROGRESS" ||
          // order.orderStatus.name === "READY_FOR_PICKUP" ||
          // order.orderStatus.name === "CLOSED"
          <PictureAsPdfIcon />
        ) : (
          "N/A"
        )}
      </TableCell>

      <Modal
        open={pdfDialogOpen}
        onClose={() => {
          console.log("On Close");
          setPdfDialogOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            background: theme.palette.secondary.main,
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download PDFs
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            <WorkOrderButton orderId={order.id} />
            <WorkOrderButton
              orderId={order.id}
              isMaterialAndLaborSummary={true}
            />
            <PackingListButton orderId={order.id} />
          </Box>
        </Box>
      </Modal> */}
    </TableRow>
  );
};

export default OrderTableRow;
