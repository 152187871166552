import { Box, Typography } from "@mui/material";
import React from "react";
import ShopCalendar from "../../admin/ShopSchedule/ShopCalendar/ShopCalendar";
import usePageName from "../../../hocs/usePageName";

const Jobs = () => {
  usePageName({ heading: "Schedule" });
  return (
    <Box>
      <ShopCalendar />
    </Box>
  );
};

export default Jobs;
