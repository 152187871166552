import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  Box,
  Button,
  Typography,
  TextField,
  Dialog,
  useMediaQuery,
  DialogContent,
  DialogTitle,
  ListItemText,
  ListItem,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import { useTheme } from "@emotion/react";
import useCustomerProjectsByAdmin from "../../../hooks/useCustomerProjectsByAdmin";
import useCompanies from "../../../hooks/useCompanies";
import SearchBar from "../../../components/SearchBar";
import InfiniteScroller from "../../../components/InfiniteScroller/InfiniteScroller";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const CreateNewQuoteDialog = ({ open, onClose }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const { authToken, ezorder } = useContext(AuthContext);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [
    company_searchQuery,
    company_setSearchQuery,
    company_results,
    company_onRefresh,
    company_refreshing,
    company_onLoadMore,
    company_loadingMore,
    company_total,
    company_hasMore,
  ] = useCompanies(authToken, 20);
  const [
    project_searchQuery,
    project_setSearchQuery,
    project_results,
    project_onRefresh,
    project_refreshing,
    project_onLoadMore,
    project_loadingMore,
    project_total,
    project_hasMore,
  ] = useCustomerProjectsByAdmin(authToken, 20, selectedCompany?.id);
  const [projectName, setProjectName] = useState("");
  const handleClose = () => {
    setProjectName("");
    onClose();
  };
  const [isSubmitting, setIsSubmitting] = useState("");
  const handleSubmitProject = async ({ name }) => {
    try {
      if (!name) return;
      setIsSubmitting(true);
      const result = await ezorder.post(
        `/admin/companies/${selectedCompany.id}/projects`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      navigate(
        `/company/${selectedCompany.id}/projects/${result.data.project.id}/orders/create`
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      scroll={"paper"}
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Create New Quote</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          {!selectedCompany && (
            <Box>
              <DialogContentText>Select Company</DialogContentText>
              <Box>
                <SearchBar
                  label="Search Companies"
                  searchQuery={company_searchQuery}
                  setSearchQuery={company_setSearchQuery}
                />
                <InfiniteScroller
                  items={company_results}
                  renderItem={(item, index) => {
                    return (
                      <ListItem
                        onClick={() => {
                          setSelectedCompany(item);
                          company_onRefresh();
                        }}
                        key={item.id}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            background: "#eeeeee",
                          },
                        }}
                      >
                        <ListItemText
                          primary={item.name}
                          secondary={
                            <>
                              <Typography>
                                Contact Name: {item.contactName}
                              </Typography>
                              <Typography>
                                Contact Email: {item.contactEmail}
                              </Typography>
                              <Typography>
                                Contact Phone: {item.contactPhone}
                              </Typography>
                              <Typography>
                                Owner: {item.owner.fullName}, {item.owner.email}
                                {item.owner.phone
                                  ? `, ${item.owner.phone}`
                                  : ""}
                              </Typography>
                            </>
                          }
                        />
                      </ListItem>
                    );
                  }}
                  onRefresh={company_onRefresh}
                  onEndReached={company_onLoadMore}
                  loading={company_refreshing || company_loadingMore}
                  ListFooterComponent={() => {
                    return (
                      <ListFooter
                        hasMore={company_hasMore}
                        onClick={company_onLoadMore}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          )}
          {selectedCompany && (
            <Box sx={{ width: "100%" }}>
              <DialogContentText>
                Create an Order For A New Project
              </DialogContentText>
              <Box sx={{ display: "flex" }}>
                <TextField
                  sx={{ flex: 1, marginTop: "8px" }}
                  size="small"
                  label="New Project"
                  value={projectName}
                  onChange={(e) => {
                    setProjectName(e.target.value);
                  }}
                />
                <Button
                  size="small"
                  sx={{ marginLeft: "16px" }}
                  variant="contained"
                  onClick={() =>
                    handleSubmitProject({
                      name: projectName,
                    })
                  }
                  disabled={isSubmitting}
                >
                  <Add /> New Project
                </Button>
              </Box>
            </Box>
          )}
          {selectedCompany && (
            <Box>
              <DialogContentText>
                Or Select Project from {selectedCompany.name}
              </DialogContentText>
              <Box>
                <SearchBar
                  label="Search Projects"
                  searchQuery={project_searchQuery}
                  setSearchQuery={project_setSearchQuery}
                />
                <InfiniteScroller
                  items={project_results}
                  renderItem={(item, index) => {
                    return (
                      <ListItem
                        onClick={() => {
                          navigate(
                            `/company/${selectedCompany.id}/projects/${item.id}/orders/create`
                          );
                        }}
                        key={index}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            background: "#eeeeee",
                          },
                        }}
                      >
                        <ListItemText primary={item.name} />
                      </ListItem>
                    );
                  }}
                  onRefresh={project_onRefresh}
                  onEndReached={project_onLoadMore}
                  loading={project_refreshing || project_loadingMore}
                  ListFooterComponent={() => {
                    return (
                      <ListFooter
                        hasMore={project_hasMore}
                        onClick={project_onLoadMore}
                      />
                    );
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateNewQuoteDialog;
