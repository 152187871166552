import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
  DatePicker,
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import JobSelect from "./JobSelect";
import { AuthContext } from "../../../../context/AuthContext";
import useShopEmployees from "../../../../hooks/useShopEmployees";
import { Check, CheckCircle, Close } from "@mui/icons-material";

const ScheduleJobDialog = ({
  dialogData,
  handleClose,
  handleSubmit,
  errorMessage,
}) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { shopEmployees } = useShopEmployees(authToken);

  const [jobId, setjobId] = useState(dialogData?.jobId);
  const [date, setDate] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [jobError, setJobError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [phases, setPhases] = useState([]);
  const [assignedTo, setAssignedTo] = useState(null);

  const [scheduledSubPhases, setScheduledSubPhases] = useState([]);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingPhases, setLoadingPhases] = useState(false);

  const getJobPhases = async (jobId) => {
    try {
      setLoadingPhases(true);
      const res = await ezorder.get(`/shop/jobs/${jobId}/phases`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      console.log(res);
      let jobInfo = res.data.job;

      setPhases(jobInfo.phases);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoadingPhases(false);
    }
  };

  useEffect(() => {
    if (dialogData && dialogData.jobId) {
      // setStartDate(
      //   moment(dialogData.date).tz("America/Phoenix").startOf("d").toDate()
      // );
      setjobId(dialogData.jobId);
    } else {
      setDate(null);
      setStartDate(null);
      setEndDate(null);
    }
  }, [dialogData]);

  // When changing the date, update the date on the start and end time
  useEffect(() => {
    if (date) {
      let start = moment(date)
        .tz("America/Phoenix")
        .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      let end = moment(date)
        .tz("America/Phoenix")
        .set({ hour: 15, minute: 0, second: 0, millisecond: 0 })
        .toDate();
      console.log(start, end);
      setStartDate(start);
      setEndDate(end);
    }
  }, [date]);

  useEffect(() => {
    if (jobId) {
      // TODO: get jobPhases
      getJobPhases(jobId);
      setScheduledSubPhases([]);
    } else {
      setPhases([]);
      setScheduledSubPhases([]);
    }
  }, [jobId]);

  return (
    <Dialog
      open={dialogData.open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogTitle
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
        }}
      >
        <Typography
          sx={{
            flex: 1,
            fontFamily: "inherit",
            fontWeight: "inherit",
            fontSize: "inherit",
          }}
        >
          Schedule a Job
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
          }}
        >
          <IconButton onClick={handleClose} sx={{}}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          {/* Employee Select */}
          <Box sx={{ marginTop: "12px" }}>
            <TextField
              size="small"
              fullWidth
              label="Assign To"
              select
              value={assignedTo?.id}
              onChange={(e) => {
                let selectedEmployee = shopEmployees.find(
                  (employee) => employee.id == e.target.value
                );
                setAssignedTo(selectedEmployee);
              }}
            >
              {shopEmployees &&
                shopEmployees.map((employee, index) => (
                  <MenuItem key={employee.id} value={employee.id}>
                    {employee.fullName}
                  </MenuItem>
                ))}
            </TextField>
          </Box>

          <Box sx={{ width: "100%" }}>
            {/* Start Date */}
            <Box sx={{ marginTop: "12px" }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  sx={{ marginRight: "10px", marginBottom: "15px" }}
                  format="MM/DD/YYYY"
                  label="Date"
                  value={date}
                  onChange={(newValue) => {
                    setDate(newValue?.toDate());
                  }}
                  renderInput={(params) => (
                    <TextField size="small" fullWidth sx={{}} {...params} />
                  )}
                />
                {date && (
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <DesktopTimePicker
                      label="From"
                      value={startDate}
                      onChange={(newValue) => {
                        console.log(newValue?.toDate());
                        setStartDate(newValue?.toDate());
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          sx={{ marginTop: "8px", marginRight: "8px" }}
                          {...params}
                        />
                      )}
                    />
                    <DesktopTimePicker
                      label="To"
                      value={endDate}
                      onChange={(newValue) => {
                        console.log(newValue?.toDate());
                        setEndDate(newValue?.toDate());
                      }}
                      renderInput={(params) => (
                        <TextField
                          size="small"
                          fullWidth
                          sx={{ marginTop: "8px", marginLeft: "8px" }}
                          {...params}
                        />
                      )}
                    />
                  </Box>
                )}
              </LocalizationProvider>
            </Box>

            {dateError ? (
              <Typography sx={{ color: "#d32f2f", marginTop: "5px" }}>
                Please select a valid date and time
              </Typography>
            ) : (
              <></>
            )}
          </Box>

          {/* Jobs - select */}
          <Box sx={{ marginTop: "12px" }}>
            {dialogData.jobId ? (
              <JobSelect
                size="small"
                initialValue={dialogData.jobId}
                // defaultValue={null} // make sure this is not a state that changes
                onSelect={(id) => {
                  setJobError(false);
                  setjobId(id);
                }}
              />
            ) : (
              <JobSelect
                size="small"
                initialValue={jobId}
                // defaultValue={null} // make sure this is not a state that changes
                onSelect={(id) => {
                  setJobError(false);
                  setjobId(id);
                }}
              />
            )}

            {jobError ? (
              <Typography sx={{ color: "#d32f2f" }}>
                Please select select a Job
              </Typography>
            ) : (
              <></>
            )}
          </Box>

          <Divider sx={{ marginY: "10px" }} />

          <Typography variant="h6">Tasks</Typography>

          {/* Phases */}
          {loadingPhases ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "200px",
              }}
            >
              <CircularProgress size="3rem" />
            </Box>
          ) : (
            <Box>
              {phases &&
                phases.map((phase) => (
                  <Box key={phase.id} sx={{ marginTop: "12px" }}>
                    <Typography>{phase.name}</Typography>
                    <Box sx={{ paddingLeft: "16px" }}>
                      {phase.subPhases &&
                        phase.subPhases.map((subPhase) => {
                          let lastScheduled = "TBD";
                          if (
                            subPhase.calendarEvents &&
                            subPhase.calendarEvents.length > 0
                          ) {
                            let latest = null;
                            for (let event of subPhase.calendarEvents) {
                              latest =
                                latest > new Date(event.endDate)
                                  ? latest
                                  : new Date(event.endDate);
                            }

                            lastScheduled = `${
                              subPhase.calendarEvents[0].assignedTo
                                ? subPhase.calendarEvents[0].assignedTo.fullName
                                : ""
                            } on  ${moment(latest)
                              .tz("America/Phoenix")
                              .format("MM/DD/YY")}`;
                          }

                          return (
                            <Box key={subPhase.id}>
                              <Box>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    magin: 0,
                                    padding: 0,
                                  }}
                                >
                                  {subPhase.isComplete ? (
                                    <CheckCircle color="success" />
                                  ) : (
                                    <Checkbox
                                      disabled={subPhase.isComplete}
                                      style={{
                                        magin: 0,
                                        padding: 0,
                                      }}
                                      checked={
                                        // TODO: IF this subPhase is in the calendarEvent.subPhases, then true. Else false
                                        scheduledSubPhases.some(
                                          (scheduledSubPhase) =>
                                            scheduledSubPhase.id == subPhase.id
                                        )
                                      }
                                      onChange={(e) => {
                                        // TODO: add to calendarEvent.subPhases
                                        if (e.target.checked) {
                                          setScheduledSubPhases([
                                            ...scheduledSubPhases,
                                            subPhase,
                                          ]);
                                        } else {
                                          let scheduledSubPhasesCopy = [
                                            ...scheduledSubPhases,
                                          ];
                                          let idxToRemove =
                                            scheduledSubPhasesCopy.findIndex(
                                              (scheduledSubPhase) =>
                                                scheduledSubPhase.id ==
                                                subPhase.id
                                            );
                                          scheduledSubPhasesCopy.splice(
                                            idxToRemove,
                                            1
                                          );
                                          setScheduledSubPhases(
                                            scheduledSubPhasesCopy
                                          );
                                        }
                                      }}
                                    />
                                  )}

                                  <Typography>
                                    {subPhase.name} (last assigned -{" "}
                                    {lastScheduled})
                                  </Typography>
                                </div>
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                ))}
            </Box>
          )}

          {errorMessage ? (
            <Typography sx={{ color: "#d32f2f", marginTop: "5px" }}>
              {errorMessage}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (!jobId) {
              setJobError(true);
              return null;
            }
            setJobError(false);
            if (!startDate || !endDate) {
              setDateError(true);
              return null;
            }
            setDateError(false);

            if (endDate < startDate) setDateError("Invalid time range");

            handleSubmit(
              jobId,
              assignedTo,
              scheduledSubPhases,
              startDate,
              endDate
            );
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleJobDialog;
