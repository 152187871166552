import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  Box,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useOrdersAdmin from "../../../hooks/useOrdersAdmin";
import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import OrderTableRow from "../Orders/OrderTableRow";
import { useInView } from "react-intersection-observer";
import { useDebounce } from "use-debounce";
import InfiniteScrollerTable from "../../../components/InfiniteScroller/InfiniteScrollerTable";
import { useTheme } from "@emotion/react";
import SearchBar from "../../../components/SearchBar";

const CustomerOrders = ({ companyId = null }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { authToken } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    orders,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
  ] = useOrdersAdmin(authToken, 50, companyId);

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5, //Number between 0 and 1 indicating the percentage that should be visible before triggering. Can also be an array of numbers, to create multiple trigger points.
  });

  const [inViewDebounce] = useDebounce(inView, 1000);

  useEffect(() => {
    if (orders.length > 0 && inView) {
      console.log("Footer Element In View. Load More...");
      onLoadMore();
    }
  }, [inViewDebounce]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <SearchBar
        size="large"
        label="Search Orders"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />

      <InfiniteScrollerTable
        TableContainerComponent={Paper}
        TableStyle={{ minWidth: "600px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Credit Type</TableCell>
              <TableCell>Quote Sent</TableCell>
              <TableCell>Date Approved</TableCell>
              <TableCell>$</TableCell>
            </TableRow>
          </TableHead>
        )}
        items={orders}
        renderRow={(item, index) => {
          return (
            <OrderTableRow
              key={item.id}
              order={item}
              onSelect={(order) => {
                navigate(`/orders/${order.id}`);
              }}
            />
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
    </Box>
  );
};
export default CustomerOrders;
