import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment-timezone";
import DebouncedSlider from "../../../components/DebouncedSlider";
import { useNavigate, useParams } from "react-router-dom";
import { Add, Close, NotificationAdd } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const NewPickup = () => {
  const { orderId } = useParams();
  const { ezorder, authToken } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [pickupType, setPickupType] = useState("PICKUP");
  const [pickupItems, setPickupItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOrderWithPickupHistory = async (orderId) => {
    try {
      setLoading(true);
      const res = await ezorder.get(`/shop/jobs/${orderId}/pickup-history`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrderDetails(res.data.order);

      // Max out toReceive
      let items = res.data.order.pickupHistory.items;
      for (let item of items) {
        item.toReceive = Number(item.quantity) - Number(item.quantityReceived);
      }
      setPickupItems(items);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  const savePickup = async () => {
    try {
      setLoading(true);

      let transactions = [];
      for (let pickupItem of pickupItems) {
        if (pickupItem.toReceive > 0)
          transactions.push({
            quantity: pickupItem.toReceive,
            objectType: pickupItem.objectType,
            itemName: pickupItem.name,
            uom: pickupItem.uom,
            pickupItem: { id: pickupItem.id },
          });
      }

      let data = {
        transactions,
        pickupType,
      };
      const res = await ezorder.post(
        `/shop/jobs/${orderId}/pickup-history/new-pickup`,
        data,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      navigate(`/ready-for-pickup/${orderId}/pickups/${res.data.pickup.id}`);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (orderId) {
      getOrderWithPickupHistory(orderId);
    }
  }, [orderId]);

  return (
    <Box>
      {orderDetails && (
        <>
          <Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                {orderDetails &&
                  orderDetails.company &&
                  orderDetails.project &&
                  orderDetails.orderStatus && (
                    <>
                      {!orderDetails.orderStatus ||
                      !orderDetails.orderStatus.name ||
                      orderDetails.orderStatus.name == "SAVED" ||
                      orderDetails.orderStatus.name == "QUOTE_REQUESTED" ||
                      orderDetails.orderStatus.name ==
                        "ESTIMATE_APPROVAL_NEEDED"
                        ? "Quote "
                        : "Order "}
                      #{orderDetails.id} - {orderDetails.company.name}
                      <DialogContentText sx={{ display: "block" }}>
                        {orderDetails.company.isVendorOrSupplier
                          ? "Vendor/Supplier Customer"
                          : "Project"}
                        : {orderDetails.project.name}
                      </DialogContentText>
                      {orderDetails.vendorProjectName && (
                        <DialogContentText sx={{ display: "block" }}>
                          Project: {orderDetails.vendorProjectName}
                        </DialogContentText>
                      )}
                      {orderDetails.subProject && (
                        <DialogContentText sx={{ display: "block" }}>
                          Sub-Project: {orderDetails.subProject}
                        </DialogContentText>
                      )}
                      <DialogContentText sx={{ display: "block" }}>
                        {orderDetails.poNumber
                          ? `PO#: ${orderDetails.poNumber}`
                          : ""}
                      </DialogContentText>
                    </>
                  )}
                <Button
                  sx={{ padding: 0, margin: 0 }}
                  onClick={() => {
                    navigate(`/orders/${orderDetails.id}`);
                  }}
                >
                  View Order
                </Button>
              </Box>
            </Box>
          </Box>
          {loading || !orderDetails ? (
            <Box
              sx={{
                width: "100%",
                minHeight: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size="3rem" />
            </Box>
          ) : (
            <Box>
              <TextField
                select
                fullWidth
                sx={{ marginTop: "16px" }}
                label="Type"
                value={pickupType}
                onChange={(e) => {
                  setPickupType(e.target.value);
                }}
              >
                <MenuItem value={"PICKUP"}>Pickup</MenuItem>
                <MenuItem value={"DELIVERY"}>Delivery</MenuItem>
              </TextField>
              <TableContainer component={Box} sx={{ margin: 0, padding: 0 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Item</TableCell>
                      {!isSmallScreen && (
                        <TableCell align="left" sx={{ width: "120px" }}>
                          Received
                        </TableCell>
                      )}
                      <TableCell align="left" sx={{ width: "70px" }}>
                        Available
                      </TableCell>
                      <TableCell align="left" sx={{ width: "120px" }}>
                        Receive
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pickupItems.map((item, index) => {
                      if (
                        Number(item.quantity) - Number(item.quantityReceived) >
                        0
                      )
                        return (
                          <>
                            <TableRow key={item.id}>
                              <TableCell align="left">
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {item.itemName}
                                </Typography>
                                <Typography variant="caption">
                                  {item.objectType}
                                </Typography>
                              </TableCell>
                              {!isSmallScreen && (
                                <TableCell align="left">
                                  <Typography>
                                    {item.quantityReceived} / {item.quantity}{" "}
                                    {item.uom}
                                  </Typography>
                                </TableCell>
                              )}

                              <TableCell align="left">
                                {item.quantity - item.quantityReceived}{" "}
                                {item.uom}
                              </TableCell>
                              <TableCell align="left">
                                <TextField
                                  InputProps={{
                                    inputProps: {
                                      min: 0,
                                      max:
                                        item.quantity - item.quantityReceived,
                                    },
                                  }}
                                  type="number"
                                  label={item.uom}
                                  value={item.toReceive}
                                  onFocus={(event) => {
                                    event.target.select();
                                  }}
                                  onChange={(e) => {
                                    let val = e.target.value;
                                    // 0 to max
                                    if (
                                      val >= 0 &&
                                      val <=
                                        item.quantity - item.quantityReceived
                                    ) {
                                      let copy = [...pickupItems];
                                      copy[index].toReceive = val;
                                      setPickupItems(copy);
                                    }
                                  }}
                                />
                              </TableCell>
                              <Divider />
                            </TableRow>
                          </>
                        );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Pickups */}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                navigate(`/ready-for-pickup/${orderId}`);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                savePickup();
              }}
            >
              Save
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default NewPickup;
