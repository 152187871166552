import { Box, Typography } from "@mui/material";
import moment from "moment-timezone";
import React from "react";

const ShopCalendarEvent = ({ calendarEvent, onClick }) => {
  let { order } = calendarEvent;
  return (
    <Box style={{ flex: 1 }} onClick={onClick}>
      <Typography sx={{ overflow: "hidden", fontSize: "12px" }}>
        {moment(calendarEvent.startDate).tz("America/Phoenix").format("h:mm a")}{" "}
        - {moment(calendarEvent.endDate).tz("America/Phoenix").format("h:mm a")}
      </Typography>
      <Typography sx={{ overflow: "hidden", fontSize: "12px" }}>
        Order #{order.id}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        }}
        color="#c0c0c0"
      >
        Customer: {order.company.name}
      </Typography>
      {order.project && (
        <Typography
          variant="body1"
          color="#c0c0c0"
          sx={{
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          {order.company.isVendorOrSupplier ? "Vendor's Customer" : "Project"}:{" "}
          {order.project.name}
        </Typography>
      )}
      {order.vendorProjectName && (
        <Typography
          variant="body1"
          color="#c0c0c0"
          sx={{
            fontSize: "12px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
          }}
        >
          Project: {order.vendorProjectName}
        </Typography>
      )}
      <Typography
        variant="body1"
        color="#c0c0c0"
        sx={{
          fontSize: "12px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
        }}
      >
        PO#: {order.poNumber}
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
          display: "-webkit-box",
          overflow: "hidden",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
        }}
        color="#c0c0c0"
      >
        Color: {order.isCustomMaterial ? order.customColor : order.color} -{" "}
        {order.isCustomMaterial ? order.customManufacturer : order.manufacturer}
      </Typography>
    </Box>
  );
};

export default ShopCalendarEvent;
