import { Box, Typography } from "@mui/material";
import React from "react";
import ReadyForPickup from "./ReadyForPickup";

const ReadyForPickupPage = () => {
  return (
    <Box>
      <Typography variant="h4" component="div">
        Ready For Pickup
      </Typography>
      <ReadyForPickup />
    </Box>
  );
};

export default ReadyForPickupPage;
