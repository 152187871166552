import React from "react";
import OrderPickupHistory from "../../admin/ReadyForPickup/OrderPickupHistory";
import usePageName from "../../../hocs/usePageName";

const OrderPickupHistoryPage = () => {
  usePageName({ heading: "Pickup History" });
  return <OrderPickupHistory />;
};

export default OrderPickupHistoryPage;
