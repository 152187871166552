import { Autocomplete, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../../context/AuthContext";

const JobSelect = (props) => {
  const { authToken, ezorder } = useContext(AuthContext);
  const [jobs, setJobs] = useState([]);

  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [initialJobIdValue, setInitialJobIdValue] = useState(
    props.initialValue
  );

  const getAvailableJobs = async (controller) => {
    try {
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }

      const response = await ezorder.get(`/shop/jobs`, data);

      if (response.status == 200) {
        setJobs(response.data.jobs);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    getAvailableJobs();
  }, []);

  useEffect(() => {
    if (value) props.onSelect(value.id);
    else {
      props.onSelect(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (initialJobIdValue && jobs && jobs.length > 0) {
      setValue(jobs.find((j) => j.id == initialJobIdValue));
    }
  }, [initialJobIdValue, jobs]);

  if (jobs && jobs.length > 0)
    return (
      <Autocomplete
        size={props.size ? props.size : "medium"}
        disablePortal
        value={value}
        inputValue={inputValue}
        onChange={(Event, newValue, reason, details) => {
          //   if (reason == "selectOption") setValue(newValue);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        // value is a customer object so match the string name to the object
        // defaultValue={jobs.find((j) => j.id == props.initialJobIdValue)}
        // defaultValue={jobs.find((j) => j.id == props.defaultValue)}
        options={jobs}
        getOptionLabel={(option) =>
          `Job #${option.id} - ${option.project.name}`
        }
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id;
        }}
        renderInput={(params) => <TextField {...params} label="Job" />}
      />
    );
};

export default JobSelect;
