import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import moment from "moment-timezone";

const CustomerPickups = ({ companyId = null }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [pickups, setPickups] = useState([]);
  const [loading, setLoading] = useState(false);

  const { authToken, ezorder } = useContext(AuthContext);

  const getCustomerPickups = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      let response;

      response = await ezorder.get(
        `/admin/companies/${companyId}/pickups`,
        data
      );

      if (response.status == 200) {
        setPickups(response.data.pickups);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (companyId) getCustomerPickups();
  }, [companyId]);

  if (companyId && pickups)
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Pickup ID</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>Signed On</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={11} align="center">
                    <CircularProgress size="3rem" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {pickups.map((pickup, index) => {
                    return (
                      <>
                        <TableRow
                          key={pickup.id}
                          hover
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(
                              `/ready-for-pickup/${pickup.pickupHistory.order.id}`
                            );
                          }}
                        >
                          <TableCell>Pickup {pickup.id}</TableCell>
                          <TableCell align="left">
                            {moment(pickup.createdAt)
                              .tz("America/Phoenix")
                              .format("MM/DD/YYYY")}
                          </TableCell>
                          <TableCell align="left">
                            {pickup.pickupType}
                          </TableCell>
                          <TableCell>
                            Order {pickup.pickupHistory.order.id}:{" "}
                            {pickup.pickupHistory.order.project.name}
                          </TableCell>

                          <TableCell align="left">
                            {pickup.signedOn
                              ? moment(pickup.signedOn)
                                  .tz("America/Phoenix")
                                  .format("MM/DD/YYYY")
                              : ""}
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={{ fontWeight: "bold" }}>
                              {pickup.pickupStatus}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
};
export default CustomerPickups;
