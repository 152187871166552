import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Badge from "@mui/material/Badge";
import { Box, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";
import { HeadingContext } from "../../../context/HeadingContext";
import { useNavigate } from "react-router-dom";
import MenuDrawer from "./MenuDrawer";
import ErrorBoundary from "../../error/ErrorBoundary";
import { NotificationContext } from "../../../context/NotificationContext";
import { AccessTime, LocalShipping } from "@mui/icons-material";
import { Check, Draw } from "@mui/icons-material";
import { useTheme } from "@emotion/react";

const BottomNav = (props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { unreadCount } = React.useContext(NotificationContext);
  const [value, setValue] = React.useState("home");
  const { headingText, captionText } = React.useContext(HeadingContext);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setDrawerOpen((prevState) => !prevState);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <MenuDrawer open={drawerOpen} toggleDrawer={handleDrawerToggle} />
      <Box>
        <AppBar
          component="nav"
          position="fixed"
          elevation={0}
          sx={{
            background: theme.palette.appBar
              ? theme.palette.appBar.background
              : "inherit",
            color: theme.palette.appBar
              ? theme.palette.appBar.contrastText
              : "inherit",
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              sx={{ position: "fixed", color: "white" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <img
                  src="/industry-metal-co-square-logo-light-outlinedicon.png"
                  height={50}
                  onClick={() => navigate("/projects")}
                />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Box component="main">
          <Toolbar />
          <Box className="Page-Heading">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                position: "fixed",
                marginBottom: "2rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {headingText && (
                  <Typography color variant="h5">
                    {headingText}
                  </Typography>
                )}
                {captionText && (
                  <Typography variant="subtitle2">{captionText}</Typography>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              overflow: "auto",
              height: "100vh",
              flexGrow: 1,
              marginTop: "3rem",
              paddingTop: "1rem",
            }}
          >
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
            <Toolbar />
          </Box>
        </Box>
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            marginTop: "3rem",
            zIndex: 10, // just above the calendar events on shop calendar
          }}
        >
          <BottomNavigation
            value={value}
            onChange={handleChange}
            sx={{
              marginTop: "3rem",
              background: theme.palette.appBar
                ? theme.palette.appBar.background
                : "#141414",
            }}
          >
            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              color="secondary"
              label="Home"
              value="home"
              icon={<HomeOutlinedIcon />}
              onClick={() => navigate("/")}
            />

            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              color="secondary"
              label="Pickup"
              value="pickup"
              icon={<LocalShipping />}
              onClick={() => navigate("/ready-for-pickup")}
            />

            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              color="secondary"
              label="Time Cards"
              value="time-cards"
              icon={
                <Badge badgeContent={unreadCount} color="primary">
                  <AccessTime />
                </Badge>
              }
              onClick={() => navigate("/time-cards")}
            />

            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              color="secondary"
              label="Time Approvals"
              value="time-approvals"
              icon={
                <Badge badgeContent={unreadCount} color="primary">
                  <Check />
                </Badge>
              }
              onClick={() => navigate("/time-approvals")}
            />

            <BottomNavigationAction
              sx={{
                minWidth: "52px",
                color: theme.palette.appBar
                  ? theme.palette.appBar.contrastText
                  : "inherit",
              }}
              color="secondary"
              label="Menu"
              value="menu"
              icon={<MenuOutlinedIcon />}
              onClick={handleDrawerToggle}
            />
          </BottomNavigation>
        </Box>
      </Box>
    </>
  );
};
export default BottomNav;
