import {
  Box,
  Button,
  Card,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AdornedInput from "../../../../../../components/AdornedInput";
import TextFieldDebouncedNoInitialLoad from "../../../../../../components/TextFieldDebouncedNoInitialLoad";
import {
  toThreeDecimals,
  toTwoDecimals,
} from "../../../../../../components/NewDetail/calculations/utils";
import { useTheme } from "@emotion/react";
import usePanelSystems from "../../../../../../hooks/usePanelSystems";
import calculatePanelCut from "../../../../../../components/NewDetail/calculations/panelCut";
import { Close } from "@mui/icons-material";
import AddPanelForm from "./AddPanelForm";

const Panel = ({ coilCostPerSqFt, panel, onUpdatePanel }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [showCutForm, setShowCutForm] = useState(false);

  const { panelSystems, getPanelSystems, refreshing } = usePanelSystems();
  const initCut = { name: "", quantity: "", feet: "", inches: "" };
  const [newCut, setNewCut] = useState(initCut);
  const [showPanelForm, setShowPanelForm] = useState(false);
  const toggleForm = () => {
    setNewCut(initCut);
    setShowPanelForm(!showPanelForm);
  };
  const handleChangeNewCutChange = (fieldName) => (e) => {
    let val = e.target.value;
    if (fieldName == "quantity" || fieldName == "feet") {
      val = Math.round(val);
    }
    if (fieldName == "inches") {
      val = toThreeDecimals(val);
      if (val < 0) val = 0;
      // if (val >= 12) val = 0;
    }
    setNewCut((curr) => ({ ...curr, [fieldName]: val }));
  };

  // New
  const getPanelSystemIndexByName = (panelSystemDescription) => {
    let i;
    for (i = 0; i < panelSystems.length; i++) {
      let system = panelSystems[i];
      if (system.description === panelSystemDescription) return i;
    }
    return 0;
  };

  const getTypeOptions = (panelSystemDescription) => {
    return panelSystems.find(
      (system) => system.description === panelSystemDescription
    ).panelTypes;
  };

  const getWidthOptions = (panelSystemDescription, panelTypeDescription) => {
    let panelTypes = getTypeOptions(panelSystemDescription);
    let i;
    for (i = 0; i < panelTypes.length; i++) {
      let panelType = panelTypes[i];
      if (panelType.description === panelTypeDescription)
        return panelType.panelWidths;
    }
  };

  const handleSystemSelect = (systemIndex) => {
    const system = panelSystems[systemIndex];
    const defaultPanelType = system.panelTypes[0];
    // panelWidths is an array of strings
    let defaultPanelWidth = parseFloat(defaultPanelType.panelWidths[0]);

    // IF "Flush Wall, SL-175, Striated With Clip Relief" then default Panel Width to 18
    if (defaultPanelType.description == "SL-175, Striated With Clip Relief") {
      if (defaultPanelType.panelWidths.includes("18")) defaultPanelWidth = 18;
    }
    // action({
    //   type: "UPDATE PANEL: SETTINGS",
    //   payload: {
    //     panelSystem: system.description,
    //     panelType: defaultPanelType.description,
    //     panelTakeUp: defaultPanelType.panelTakeUp,
    //     panelWidth: defaultPanelWidth,
    //     profile: "",
    //   },
    // });

    onUpdatePanel({
      ...panel,
      panelSystem: system.description,
      panelType: defaultPanelType.description,
      panelTakeUp: defaultPanelType.panelTakeUp,
      panelWidth: defaultPanelWidth,
      profile: "",
    });
  };

  const handleTypeSelect = (panelSystem, systemType) => {
    // // Use reducer to update default width selected
    let widthOptions = getWidthOptions(panelSystem, systemType);
    let typeOptions = getTypeOptions(panelSystem);
    let selectePaneltype = typeOptions.find((t) => t.description == systemType);

    let defaultPanelWidth = parseFloat(widthOptions[0]);
    // IF "Flush Wall, SL-175, Striated With Clip Relief" then default Panel Width to 18
    if (systemType == "SL-175, Striated With Clip Relief") {
      if (widthOptions.includes("18")) defaultPanelWidth = 18;
    }

    onUpdatePanel({
      ...panel,
      panelType: systemType,
      panelTakeUp: selectePaneltype.panelTakeUp,
      panelWidth: defaultPanelWidth,
    });
  };

  const handleAddPanelCut = (cut) => {
    let cutsCopy = [...panel.cuts];
    cutsCopy.push(cut);

    onUpdatePanel({ ...panel, cuts: cutsCopy });
  };

  if (panelSystems.length == 0) return null;

  return (
    <Box>
      <Typography sx={{ flex: 1 }} variant="h6">
        Panel
      </Typography>
      <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumScreen ? "column" : "row",
          }}
        >
          <Box sx={{}}>
            <Typography>
              Square Foot Pricing: $
              {coilCostPerSqFt ? parseFloat(coilCostPerSqFt) : 0}
            </Typography>
            <Box
              sx={{ marginTop: "8px", display: "flex", flexDirection: "row" }}
            >
              <TextField
                sx={{ width: "200px" }}
                select
                variant="outlined"
                label="Panel System"
                id="system"
                name="Panel System"
                size="small"
                fullWidth
                value={
                  panel.panelSystem
                    ? getPanelSystemIndexByName(panel.panelSystem)
                    : null
                }
                onChange={(e) => handleSystemSelect(e.target.value)}
              >
                {panelSystems &&
                  panelSystems?.map((sys, index) => (
                    <MenuItem key={sys.description} value={index}>
                      {sys.description}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                sx={{ width: "300px", marginLeft: "8px" }}
                select
                variant="outlined"
                label="Panel Type"
                id="type"
                name="Panel Type"
                size="small"
                fullWidth
                value={panel.panelType}
                onChange={(e) => {
                  handleTypeSelect(panel.panelSystem, e.target.value);
                }}
              >
                {panel.panelSystem &&
                  getTypeOptions(panel.panelSystem).map((type) => (
                    <MenuItem key={type.description} value={type.description}>
                      {type.description}
                    </MenuItem>
                  ))}
              </TextField>
              <TextField
                sx={{ width: "100px", marginLeft: "8px" }}
                select
                variant="outlined"
                label="Panel Width"
                id="width"
                name="Panel Width"
                size="small"
                fullWidth
                value={panel.panelWidth}
                onChange={(e) => {
                  onUpdatePanel({
                    ...panel,
                    panelWidth: e.target.value,
                  });
                }}
              >
                {panel.panelSystem &&
                  panel.panelType &&
                  getWidthOptions(panel.panelSystem, panel.panelType).map(
                    (width) => (
                      <MenuItem value={Number(width)} key={width}>
                        {width}
                      </MenuItem>
                    )
                  )}
              </TextField>
            </Box>
          </Box>
        </Box>
        <Stack direction="column" spacing={2}>
          <Typography>Material & Labor</Typography>
          <Stack direction="column" spacing={2}>
            <Stack direction="row" spacing={2}>
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil Size Needed"
                value={panel.calculations.coilSizeNeeded}
                // onChange={handleChange("coil_size_needed")}
                // isOveridden={overrides?.coil_size_needed}
                // handleReset={resetOverride("coil_size_needed")}
                inputProps={{ readOnly: true }}
                disabled
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Master Coil Size Needed"
                value={panel.calculations.masterCoilSizeNeeded}
                // onChange={handleChange("master_coil_size_needed")}
                // isOveridden={overrides?.master_coil_size_needed}
                // handleReset={resetOverride("master_coil_size_needed")}
                inputProps={{ readOnly: true }}
                disabled
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil Drop/Excess"
                value={panel.calculations.coilDrop}
                // onChange={handleChange("coil_drop")}
                // isOveridden={overrides?.coil_drop}
                // handleReset={resetOverride("coil_drop")}
                inputProps={{ readOnly: true }}
                disabled
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coils Cut From Master"
                value={panel.calculations.coilsCutFromMaster}
                // onChange={handleChange("coils_cut_from_master")}
                // isOveridden={overrides?.coils_cut_from_master}
                // handleReset={resetOverride("coils_cut_from_master")}
                inputProps={{ readOnly: true }}
                disabled
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <TextFieldDebouncedNoInitialLoad
                size="small"
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Waste Rate"
                value={panel.calculations.waste}
                // onChange={handleChange("waste")}
                // isOveridden={overrides?.waste}
                // handleReset={resetOverride("waste")}
                onChange={(e) => {
                  // Update variables instead of override
                  let variables = {
                    ...panel.variables,
                    waste: parseFloat(e.target.value),
                  };

                  onUpdatePanel({
                    ...panel,
                    variables: variables,
                  });
                }}
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil LF (no waste)"
                value={panel.calculations.linealFeetOfCoilNoWaste}
                // onChange={handleChange("lineal_feet_of_coil_no_waste")}
                // isOveridden={overrides?.lineal_feet_of_coil_no_waste}
                // handleReset={resetOverride("lineal_feet_of_coil_no_waste")}
                inputProps={{ readOnly: true }}
                disabled
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil LF (with waste)"
                value={panel.calculations.linealFeetOfCoilWithWaste}
                // onChange={handleChange("lineal_feet_of_coil_with_waste")}
                // isOveridden={overrides?.lineal_feet_of_coil_with_waste}
                // handleReset={resetOverride("lineal_feet_of_coil_with_waste")}
                inputProps={{ readOnly: true }}
                disabled
              />
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Coil Sq Ft (with waste)"
                value={panel.calculations.squareFeetOfCoilNeededWithWaste}
                // onChange={handleChange("square_feet_of_coil_needed_with_waste")}
                // isOveridden={overrides?.square_feet_of_coil_needed_with_waste}
                // handleReset={resetOverride(
                //   "square_feet_of_coil_needed_with_waste"
                // )}
                inputProps={{ readOnly: true }}
                disabled
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <AdornedInput
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                onFocus={(e) => e.target.select()}
                label="Finished Panel Sq. Ft."
                value={panel.calculations.finishedPanelSquareFeet}
                // onChange={handleChange("finished_panel_square_feet")}
                // isOveridden={overrides?.finished_panel_square_feet}
                // handleReset={resetOverride("finished_panel_square_feet")}
                inputProps={{ readOnly: true }}
                disabled
              />
            </Stack>
          </Stack>

          <Typography>Cost Breakdown</Typography>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Total Coil Cost"
              value={panel.calculations.materialCost}
              symbol="$"
              position="start"
              // onChange={handleChange("material_cost")}
              // isOveridden={overrides?.material_cost}
              // handleReset={resetOverride("material_cost")}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Est. Lumber Cost"
              value={panel.calculations.estimatedLumberCost}
              symbol="$"
              position="start"
              // onChange={handleChange("estimated_lumber_cost")}
              // isOveridden={overrides?.estimated_lumber_cost}
              // handleReset={resetOverride("estimated_lumber_cost")}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Est. Man Hours"
              value={panel.calculations.estimatedLaborHours}
              // onChange={handleChange("total_estimated_labor_hours")}
              // isOveridden={overrides?.total_estimated_labor_hours}
              // handleReset={resetOverride("total_estimated_labor_hours")}
            />
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Est. Labor Cost"
              value={panel.calculations?.estimatedLaborCost}
              // onChange={(e) => handleChange("total_estimated_labor_cost")(e)}
              // isOveridden={overrides?.total_estimated_labor_cost}
              // handleReset={(e) =>
              //   resetOverride("total_estimated_labor_cost")(e)
              // }
              symbol="$"
              position="start"
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Total Hard Cost"
              value={panel.calculations.totalHardCost}
              symbol="$"
              position="start"
              // onChange={handleChange("total_hard_cost")}
              // isOveridden={overrides?.total_hard_cost}
              // handleReset={resetOverride("total_hard_cost")}
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="GP %"
              value={
                panel.calculations?.grossProfitMargin
                  ? toTwoDecimals(panel.calculations?.grossProfitMargin * 100.0)
                  : 0
              }
              // onChange={(e) => {
              //   e.target.value = parseFloat(
              //     parseFloat(e.target.value).toFixed(4) / 100.0
              //   ).toFixed(4);
              //   handleChange("gross_profit_margin")(e);
              // }}
              // isOveridden={overrides?.gross_profit_margin}
              // handleReset={resetOverride("gross_profit_margin")}
              symbol="%"
              position="start"
            />

            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Gross Profit"
              value={panel.calculations.grossProfitDollars}
              symbol="$"
              position="start"
              // onChange={handleChange("gross_profit_dollars")}
              // isOveridden={overrides?.gross_profit_dollars}
              // handleReset={resetOverride("gross_profit_dollars")}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Total Sell Price"
              value={panel.calculations.totalSellPrice}
              symbol="$"
              position="start"
              // onChange={handleChange("total_sell_price")}
              // isOveridden={overrides?.total_sell_price}
              // handleReset={resetOverride("total_sell_price")}
            />
            <AdornedInput
              type="number"
              onWheel={(event) => {
                event.preventDefault();
              }}
              onFocus={(e) => e.target.select()}
              label="Panel Price/Sq. Ft."
              value={panel.calculations.panelPricePerSquareFoot}
              symbol="$"
              position="start"
              // onChange={handleChange("panel_price_per_square_foot")}
              // isOveridden={overrides?.panel_price_per_square_foot}
              // handleReset={resetOverride("panel_price_per_square_foot")}
            />
          </Stack>
        </Stack>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: ".5rem" }}>
            <Typography variant="overline">Cut List:</Typography>
            {!showCutForm && (
              <Button
                size="small"
                variant="text"
                onClick={() => {
                  setShowCutForm(true);
                }}
              >
                + Add
              </Button>
            )}
          </Box>
          {showCutForm && (
            <AddPanelForm
              onSubmit={(cut) => {
                // TODO
                handleAddPanelCut(cut);
                setShowCutForm(false);
              }}
              onClose={() => {
                setShowCutForm(false);
              }}
            />
          )}
          {panel.cuts.map((cut, index) => {
            // TODO: transfer this logic to QuoteForm.calculatePanel?
            let panelCutCalculations = calculatePanelCut(
              cut,
              panel,
              panel.calculations,
              panel.calculations,
              panel.overrides
            );
            return (
              <Box
                key={cut.name + index}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: ".5rem",
                  border: "1px solid #E0E0E0",
                  borderRadius: ".5rem",
                }}
              >
                <TextFieldDebouncedNoInitialLoad
                  variant="standard"
                  size="small"
                  label="Name"
                  value={cut.name}
                  onChange={(e) => {
                    // action({
                    //   type: "UPDATE PANEL: EDIT CUT",
                    //   payload: {
                    //     index: index,
                    //     update: {
                    //       name: e.target.value,
                    //     },
                    //   },
                    // })
                  }}
                />
                <TextFieldDebouncedNoInitialLoad
                  sx={{ marginLeft: "8px" }}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="Quantity"
                  value={cut.quantity}
                  onChange={(e) => {
                    //  action({
                    //   type: "UPDATE PANEL: EDIT CUT",
                    //   payload: {
                    //     index: index,
                    //     update: {
                    //       quantity: e.target.value,
                    //     },
                    //   },
                    // })
                  }}
                />
                <TextFieldDebouncedNoInitialLoad
                  sx={{ marginLeft: "8px" }}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="Feet"
                  value={cut.feet}
                  onChange={(e) => {
                    // action({
                    //   type: "UPDATE PANEL: EDIT CUT",
                    //   payload: {
                    //     index: index,
                    //     update: {
                    //       feet: e.target.value,
                    //     },
                    //   },
                    // });
                  }}
                />
                <TextFieldDebouncedNoInitialLoad
                  sx={{ marginLeft: "8px" }}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="Inches"
                  value={cut.inches}
                  onChange={(e) => {
                    // action({
                    //   type: "UPDATE PANEL: EDIT CUT",
                    //   payload: {
                    //     index: index,
                    //     update: {
                    //       inches: e.target.value,
                    //     },
                    //   },
                    // })
                  }}
                />
                <TextFieldDebouncedNoInitialLoad
                  sx={{ marginLeft: "8px" }}
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="LF Coil (no Waste)"
                  value={panelCutCalculations.linealFeetOfCoilNoWaste}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
                <TextFieldDebouncedNoInitialLoad
                  type="number"
                  onWheel={(event) => {
                    event.preventDefault();
                  }}
                  variant="standard"
                  size="small"
                  label="Finished Panel Sq Ft"
                  value={panelCutCalculations.finishedPanelSquareFeet}
                  InputProps={{
                    readOnly: true,
                    disableUnderline: true,
                  }}
                />
                <Tooltip title="Remove">
                  <IconButton
                    size="small"
                    onClick={() => {
                      //   action({
                      //     type: "UPDATE PANEL: REMOVE CUT",
                      //     payload: index,
                      //   })
                    }}
                  >
                    <Close fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Panel;
