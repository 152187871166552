import {
  Box,
  Button,
  Card,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import AdornedInput from "../../../../../../components/AdornedInput";
import TextFieldDebouncedNoInitialLoad from "../../../../../../components/TextFieldDebouncedNoInitialLoad";
import { toTwoDecimals } from "../../../../../../components/NewDetail/calculations/utils";
import { useTheme } from "@emotion/react";

const Coil = ({ coil, onUpdateCoil }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <Typography sx={{ flex: 1 }} variant="h6">
        Coil
      </Typography>
      <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isMediumScreen ? "column" : "row",
          }}
        >
          <Box sx={{}}>
            <Typography>Details</Typography>
            <Box
              sx={{ marginTop: "8px", display: "flex", flexDirection: "row" }}
            >
              <TextFieldDebouncedNoInitialLoad
                sx={{
                  width: "150px",
                }}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                size="small"
                label="Lineal Feet"
                value={coil.linealFeet ? parseFloat(coil.linealFeet) : null}
                onChange={(e) => {
                  onUpdateCoil({
                    ...coil,
                    linealFeet: e.target.value,
                  });
                }}
              />
              <TextFieldDebouncedNoInitialLoad
                sx={{
                  width: "150px",
                  marginLeft: "8px",
                }}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                variant="outlined"
                label="Width (in.)"
                id="width"
                name="Width (in.)"
                size="small"
                value={coil.width ? parseFloat(coil.width) : null}
                onChange={(e) => {
                  onUpdateCoil({
                    ...coil,
                    width: e.target.value,
                  });
                }}
              />
            </Box>
          </Box>
          <Box sx={{ marginLeft: isMediumScreen ? 0 : "8px" }}>
            <Typography>Cost Breakdown</Typography>
            <Box
              sx={{ marginTop: "8px", display: "flex", flexDirection: "row" }}
            >
              <TextFieldDebouncedNoInitialLoad
                sx={{ flex: 1, maxWidth: "150px" }}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                size="small"
                label="Coil Cost (per Square Feet)"
                value={coil.coilCalculations?.costPerSquareFeetOfCoil}
                onChange={(e) => {
                  let coilCalculationsCopy = {
                    ...coil.coilCalculations,
                    costPerSquareFeetOfCoil: parseFloat(e.target.value),
                  };

                  onUpdateCoil({
                    ...coil,
                    coilCalculations: coilCalculationsCopy,
                  });
                }}
              />
              <TextFieldDebouncedNoInitialLoad
                sx={{ flex: 1, maxWidth: "150px", marginLeft: "8px" }}
                onFocus={(e) => e.target.select()}
                type="number"
                onWheel={(event) => {
                  event.preventDefault();
                }}
                size="small"
                label="Square Feet of Coil"
                value={coil.coilCalculations?.squareFeetOfCoil}
                onChange={(e) => {
                  let coilCalculationsCopy = {
                    ...coil.coilCalculations,
                    squareFeetOfCoil: parseFloat(e.target.value),
                  };

                  onUpdateCoil({
                    ...coil,
                    coilCalculations: coilCalculationsCopy,
                  });
                }}
              />
              <TextField
                sx={{ flex: 1, maxWidth: "150px", marginLeft: "8px" }}
                disabled
                size="small"
                type="number"
                onFocus={(e) => e.target.select()}
                label="Total Coil Sheet Cost"
                symbol="$"
                position="start"
                value={coil.coilCalculations.materialCost}
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography>Pricing</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMediumScreen ? "column" : "row",
              marginTop: "8px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TextFieldDebouncedNoInitialLoad
                sx={{ flex: 1, maxWidth: "150px" }}
                size="small"
                type="number"
                onFocus={(e) => e.target.select()}
                label="GP %"
                value={
                  coil.coilCalculations?.grossProfitMargin
                    ? toTwoDecimals(
                        coil.coilCalculations?.grossProfitMargin * 100.0
                      )
                    : 0
                }
                //   onChange={(e) => {
                //     e.target.value = parseFloat(
                //       parseFloat(e.target.value).toFixed(4) / 100.0
                //     ).toFixed(4);
                //     handleChange("gross_profit_margin")(e);
                //   }}
                //   isOveridden={overrides?.gross_profit_margin}
                //   handleReset={resetOverride("gross_profit_margin")}
                symbol="%"
                position="start"
              />
              <TextField
                sx={{
                  flex: 1,
                  maxWidth: "150px",
                  marginLeft: "8px",
                }}
                size="small"
                disabled
                type="number"
                onFocus={(e) => e.target.select()}
                label="Gross Profit"
                symbol="$"
                position="start"
                value={coil.coilCalculations.grossProfitDollars}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: isMediumScreen ? "8px" : 0,
                marginLeft: isMediumScreen ? 0 : "8px",
              }}
            >
              <TextField
                sx={{ flex: 1, maxWidth: "150px" }}
                size="small"
                disabled
                type="number"
                onFocus={(e) => e.target.select()}
                label="Total Sell Price"
                symbol="$"
                position="start"
                value={coil.coilCalculations.totalSellPrice}
              />
              <TextField
                sx={{ flex: 1, maxWidth: "150px", marginLeft: "8px" }}
                size="small"
                disabled
                type="number"
                onFocus={(e) => e.target.select()}
                label="Coil Price (per Square Foot)"
                value={coil.coilCalculations.pricePerSquareFeetOfCoil}
                symbol="$"
                position="start"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
              <TextField
                sx={{ flex: 1, maxWidth: "150px", marginLeft: "8px" }}
                size="small"
                disabled
                type="number"
                onFocus={(e) => e.target.select()}
                label="Coil Price (per Lineal Foot)"
                value={coil.coilCalculations.pricePerLinealFootOfCoil}
                symbol="$"
                position="start"
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Coil;
