import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogContentText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import moment from "moment-timezone";
import useSchedule from "../../../hooks/useSchedule";
import PickupDialog from "./PickupDialog";

const DEFAULT_SCHEDULE_JOB_DIALOG_DATA = {
  open: false,
  date: null,
  jobId: null,
};

const ReadyForPickup = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { scheduleEvent, updateEvent } = useSchedule();
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [scheduleJobDialogData, setScheduleJobDialogData] = useState(
    DEFAULT_SCHEDULE_JOB_DIALOG_DATA
  );
  const [loading, setLoading] = useState(false);

  const { authToken, ezorder } = useContext(AuthContext);

  const getJobsReadyForPickup = async (controller) => {
    try {
      setLoading(true);
      let data = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      if (controller) {
        data.signal = controller.signal;
      }
      let response = await ezorder.get(`/shop/jobs/ready-for-pickup`, data);

      if (response.status == 200) {
        setJobs(response.data.jobs);
      } else {
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobsReadyForPickup();
  }, []);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Project</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Credit Type</TableCell>
              <TableCell>Date Approved</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  <CircularProgress size="3rem" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {jobs.map((job, index) => {
                  return (
                    <>
                      <TableRow
                        key={job.id}
                        hover
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          // setSelectedJob(job);
                          navigate(`/ready-for-pickup/${job.id}`);
                        }}
                      >
                        <TableCell>{job.id}</TableCell>
                        <TableCell>{job.project.name}</TableCell>
                        <TableCell>
                          {job.company && job.company.name
                            ? job.company.name
                            : "GUEST"}
                        </TableCell>
                        <TableCell>{job.company.creditType}</TableCell>
                        <TableCell>
                          {moment(job.estimateApprovalDate)
                            .tz("America/Phoenix")
                            .format("MM/DD/YY")}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <PickupDialog
        open={selectedJob != null}
        jobDetails={selectedJob}
        handleSchedule={(jobId) => {
          setScheduleJobDialogData({ open: true, jobId: jobId });
          setSelectedJob(null);
        }}
        handleClose={() => {
          setSelectedJob(null);
        }}
      />
    </Box>
  );
};
export default ReadyForPickup;
