import { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Dialog,
  useMediaQuery,
  DialogContent,
  DialogTitle,
  ListItemText,
  ListItem,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import ListFooter from "../../../../../components/InfiniteScroller/ListFooter";
import { useTheme } from "@emotion/react";
import useCompanies from "../../../../../hooks/useCompanies";
import SearchBar from "../../../../../components/SearchBar";
import InfiniteScroller from "../../../../../components/InfiniteScroller/InfiniteScroller";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../context/AuthContext";

const SelectCustomerDialog = ({ open, handleClose, onSelect }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const { authToken } = useContext(AuthContext);

  const [
    company_searchQuery,
    company_setSearchQuery,
    company_results,
    company_onRefresh,
    company_refreshing,
    company_onLoadMore,
    company_loadingMore,
    company_total,
    company_hasMore,
  ] = useCompanies(authToken, 20);

  return (
    <Dialog
      scroll={"paper"}
      fullScreen={isSmallScreen}
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>Select Customer</DialogTitle>
      <DialogContent>
        <SearchBar
          label="Search Companies"
          searchQuery={company_searchQuery}
          setSearchQuery={company_setSearchQuery}
        />
        <InfiniteScroller
          items={company_results}
          renderItem={(item, index) => {
            return (
              <ListItem
                onClick={() => {
                  onSelect(item);
                  handleClose();
                }}
                key={item.id}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    background: "#eeeeee",
                  },
                }}
              >
                <ListItemText
                  primary={item.name}
                  secondary={
                    <>
                      <Typography>Contact Name: {item.contactName}</Typography>
                      <Typography>
                        Contact Email: {item.contactEmail}
                      </Typography>
                      <Typography>
                        Contact Phone: {item.contactPhone}
                      </Typography>
                      <Typography>
                        Owner: {item.owner.fullName}, {item.owner.email}
                        {item.owner.phone ? `, ${item.owner.phone}` : ""}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            );
          }}
          onRefresh={company_onRefresh}
          onEndReached={company_onLoadMore}
          loading={company_refreshing || company_loadingMore}
          ListFooterComponent={() => {
            return (
              <ListFooter
                hasMore={company_hasMore}
                onClick={company_onLoadMore}
              />
            );
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectCustomerDialog;
