import { Check, Close } from "@mui/icons-material";
import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";

const AddPanelForm = ({ onSubmit, onClose }) => {
  const [cut, setCut] = useState({});
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: ".5rem",
        marginBottom: ".5rem",
      }}
    >
      <TextField
        onFocus={(e) => e.target.select()}
        size="small"
        label="Name"
        value={cut.name}
        onChange={(e) => {
          setCut((prev) => ({ ...prev, name: e.target.value }));
        }}
      />
      <TextField
        onFocus={(e) => e.target.select()}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        size="small"
        label="Quantity"
        value={cut.quantity}
        onChange={(e) => {
          setCut((prev) => ({ ...prev, quantity: e.target.value }));
        }}
      />
      <TextField
        onFocus={(e) => e.target.select()}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        size="small"
        label="Feet"
        value={cut.feet}
        onChange={(e) => {
          setCut((prev) => ({ ...prev, feet: e.target.value }));
        }}
      />
      <TextField
        onFocus={(e) => e.target.select()}
        type="number"
        onWheel={(event) => {
          event.preventDefault();
        }}
        size="small"
        label="Inches"
        value={cut.inches}
        onChange={(e) => {
          setCut((prev) => ({ ...prev, inches: e.target.value }));
        }}
      />
      <Button
        color="success"
        size="small"
        variant="outlined"
        onClick={() => {
          onSubmit(cut);
        }}
      >
        <Check />
      </Button>
      <Button
        color="error"
        size="small"
        variant="outlined"
        onClick={() => {
          setCut({});
          onClose();
        }}
      >
        <Close />
      </Button>
    </Box>
  );
};

export default AddPanelForm;
