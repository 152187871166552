import { Box, Button, Grid, Typography } from "@mui/material";
import TestDragAndDrop from "./TestDragAndDrop";
import TestCalendar from "./TestCalendar";
import CustomCalendar from "./CustomCalendar";
import ShopCalendar from "./ShopCalendar/ShopCalendar";

const ShopSchedule = ({}) => {
  return (
    <Box>
      <Typography variant="h4" component="div">
        Shop Schedule
      </Typography>

      {/* <ShopScheduleList /> */}
      {/* <TestDragAndDrop /> */}
      {/* <TestCalendar /> */}
      {/* <CustomCalendar /> */}
      <ShopCalendar />
    </Box>
  );
};

export default ShopSchedule;
