import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, Close, NotificationAdd } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";

const PickupDialog = ({ open, jobDetails, handleClose, handleSchedule }) => {
  const { ezorder, authToken } = useContext(AuthContext);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const getOrderWithPickupHistory = async (orderId) => {
    try {
      setLoading(true);
      const res = await ezorder.get(`/shop/jobs/${orderId}/pickup-history`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setOrderDetails(res.data.order);
    } catch (error) {
      console.log(error);
      // setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (jobDetails) {
      getOrderWithPickupHistory(jobDetails.id);
    }
  }, [jobDetails]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth={true}
      fullScreen={isSmallScreen}
    >
      {jobDetails && (
        <>
          <DialogTitle>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ flex: 1 }}>
                {jobDetails &&
                  jobDetails.company &&
                  jobDetails.project &&
                  jobDetails.orderStatus && (
                    <>
                      {!jobDetails.orderStatus ||
                      !jobDetails.orderStatus.name ||
                      jobDetails.orderStatus.name == "SAVED" ||
                      jobDetails.orderStatus.name == "QUOTE_REQUESTED" ||
                      jobDetails.orderStatus.name == "ESTIMATE_APPROVAL_NEEDED"
                        ? "Quote "
                        : "Order "}
                      #{jobDetails.id} - {jobDetails.company.name}
                      <DialogContentText sx={{ display: "block" }}>
                        {jobDetails.company.isVendorOrSupplier
                          ? "Vendor/Supplier Customer"
                          : "Project"}
                        : {jobDetails.project.name}
                      </DialogContentText>
                      {jobDetails.vendorProjectName && (
                        <DialogContentText sx={{ display: "block" }}>
                          Project: {jobDetails.vendorProjectName}
                        </DialogContentText>
                      )}
                      {jobDetails.subProject && (
                        <DialogContentText sx={{ display: "block" }}>
                          Sub-Project: {jobDetails.subProject}
                        </DialogContentText>
                      )}
                      <DialogContentText sx={{ display: "block" }}>
                        {jobDetails.poNumber
                          ? `PO#: ${jobDetails.poNumber}`
                          : ""}
                      </DialogContentText>
                    </>
                  )}
                <Button
                  sx={{ padding: 0, margin: 0 }}
                  onClick={() => {
                    navigate(`/orders/${orderDetails.id}`);
                  }}
                >
                  View Order
                </Button>
              </Box>
              <Box>
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          {loading || !orderDetails ? (
            <Box
              sx={{
                width: "100%",
                minHeight: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress size="3rem" />
            </Box>
          ) : (
            <DialogContent>
              <TableContainer component={Box} sx={{ margin: 0, padding: 0 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Item</TableCell>
                      {!isSmallScreen && (
                        <TableCell align="left" sx={{ width: "120px" }}>
                          Received
                        </TableCell>
                      )}
                      <TableCell align="left" sx={{ width: "70px" }}>
                        Available
                      </TableCell>
                      {/* <TableCell align="left" sx={{ width: "120px" }}>
                        Receive
                      </TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderDetails.pickupHistory &&
                      orderDetails.pickupHistory.items &&
                      orderDetails.pickupHistory.items.map((item, index) => {
                        return (
                          <>
                            <TableRow key={item.id}>
                              <TableCell align="left">
                                <Typography sx={{ fontWeight: "bold" }}>
                                  {item.itemName}
                                </Typography>
                                <Typography variant="caption">
                                  {item.objectType}
                                </Typography>
                              </TableCell>
                              {!isSmallScreen && (
                                <TableCell align="left">
                                  <Typography>
                                    {item.quantityReceived} / {item.quantity}{" "}
                                    {item.uom}
                                  </Typography>
                                </TableCell>
                              )}

                              <TableCell align="left">
                                {item.quantity - item.quantityReceived}{" "}
                                {item.uom}
                              </TableCell>
                              {/* <TableCell align="left">
                                <TextField
                                  label={item.uom}
                                  value={item.quantity - item.quantityReceived}
                                />
                              </TableCell> */}
                              <Divider />
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              {/* Pickups */}
              <Box>
                <Typography variant="h6">Pickups</Typography>
                <TableContainer component={Box} sx={{ margin: 0, padding: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Date</TableCell>

                        {/* <TableCell align="left" sx={{ width: "120px" }}>
                        Receive
                      </TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderDetails.pickupHistory &&
                        orderDetails.pickupHistory.pickups &&
                        orderDetails.pickupHistory.pickups.map(
                          (pickup, index) => {
                            return (
                              <>
                                <TableRow key={pickup.id}>
                                  <TableCell align="left">
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {pickup.createdAt}
                                    </Typography>
                                  </TableCell>

                                  {/* <TableCell align="left">
                                <TextField
                                  label={item.uom}
                                  value={item.quantity - item.quantityReceived}
                                />
                              </TableCell> */}
                                  <Divider />
                                </TableRow>
                              </>
                            );
                          }
                        )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </DialogContent>
          )}

          <DialogActions>
            <Button
            //   onClick={() => {
            //     handleSchedule(orderDetails.id);
            //   }}
            >
              <NotificationAdd sx={{ paddingRight: "4px" }} />
              Ready Notification
            </Button>
            <Button
              onClick={() => {
                navigate(`/ready-for-pickup/${jobDetails.id}/new-pickup`);
              }}
            >
              <Add sx={{ paddingRight: "4px" }} />
              Pickup
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default PickupDialog;
