import { Box, Typography } from "@mui/material";
import NewPickup from "./NewPickup";

const NewPickupPage = () => {
  return (
    <Box>
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        New Pickup
      </Typography>
      <NewPickup />
    </Box>
  );
};

export default NewPickupPage;
