import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  useMediaQuery,
  MenuItem,
} from "@mui/material";

import ListFooter from "../../../components/InfiniteScroller/ListFooter";
import { useInView } from "react-intersection-observer";
import { useDebounce } from "use-debounce";
import InfiniteScrollerTable from "../../../components/InfiniteScroller/InfiniteScrollerTable";
import { useTheme } from "@emotion/react";
import SearchBar from "../../../components/SearchBar";
import useQuotesAdmin from "../../../hooks/useQuotesAdmin";
import { AdminQuoteAndOrderCountContext } from "../../../context/AdminQuoteAndOrderCountContext";
import CreateNewQuoteDialog from "./CreateNewQuoteDialog";
import QuoteRow from "./QuoteRow";

const QuoteRequests = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [newQuoteModalOpen, setNewQuoteModalOpen] = useState(false);
  const { refreshQuoteCount } = useContext(AdminQuoteAndOrderCountContext);

  const { authToken, ezorder } = useContext(AuthContext);
  const [
    searchQuery,
    setSearchQuery,
    orders,
    onRefresh,
    refreshing,
    onLoadMore,
    loadingMore,
    total,
    hasMore,
    statusFilter,
    setStatusFilter,
  ] = useQuotesAdmin(authToken, 50, null);

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0.5, //Number between 0 and 1 indicating the percentage that should be visible before triggering. Can also be an array of numbers, to create multiple trigger points.
  });

  const [inViewDebounce] = useDebounce(inView, 1000);

  useEffect(() => {
    refreshQuoteCount();
  }, []);

  useEffect(() => {
    if (orders.length > 0 && inView) {
      console.log("Footer Element In View. Load More...");
      onLoadMore();
    }
  }, [inViewDebounce]);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <CreateNewQuoteDialog
        open={newQuoteModalOpen}
        onClose={() => {
          setNewQuoteModalOpen(false);
        }}
      />

      <Typography variant="h4" component="div">
        Pending Quotes
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "16px",
        }}
      >
        <Button
          size="large"
          style={{ height: "100%", minHeight: "54px", marginTop: "8px" }}
          color="success"
          onClick={() => {
            //navigate("/orders/create");
            setNewQuoteModalOpen(true);
          }}
          variant="outlined"
        >
          + New Quote
        </Button>
        <Button
          size="large"
          style={{ height: "100%", minHeight: "54px", marginTop: "8px" }}
          color="success"
          onClick={() => {
            navigate("/quotes/create");
          }}
          variant="outlined"
        >
          + New Quote V2
        </Button>
        <Box sx={{ marginLeft: "8px", flex: 1 }}>
          <SearchBar
            size="large"
            label="Search Orders (By Project/Vendor Customer, Sub-Project, PO #)"
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            containerStyle={{ background: "blue" }}
          />
        </Box>
        <TextField
          sx={{ width: "200px", marginLeft: "8px" }}
          margin="normal"
          select
          label="Status"
          value={statusFilter}
          onChange={(e) => {
            setStatusFilter(e.target.value);
          }}
        >
          <MenuItem value={""}>All</MenuItem>
          <MenuItem value={"QUOTE_REQUESTED"}>
            Quote Requested By Customer
          </MenuItem>
          <MenuItem value={"ESTIMATE_APPROVAL_NEEDED"}>
            Quoted - Approval Needed By Customer
          </MenuItem>
        </TextField>
      </Box>
      <InfiniteScrollerTable
        TableContainerComponent={Paper}
        TableStyle={{ minWidth: "600px" }}
        TableHead={() => (
          <TableHead>
            <TableRow>
              <TableCell>Sales Rep</TableCell>
              <TableCell>ID</TableCell>

              <TableCell>Customer Name</TableCell>
              <TableCell align="right">Project</TableCell>
              <TableCell>PO #</TableCell>
              <TableCell align="right">Date Created</TableCell>
              <TableCell align="right">Quote Sent</TableCell>
              <TableCell align="right">$</TableCell>
            </TableRow>
          </TableHead>
        )}
        items={orders}
        renderRow={(item, index) => {
          return (
            <QuoteRow
              key={index}
              isPendingQuote={true}
              order={item}
              onSelect={(order) => {
                navigate(`/quotes/${order.id}`);
              }}
            />
          );
        }}
        onRefresh={onRefresh}
        onEndReached={onLoadMore}
        loading={refreshing || loadingMore}
        ListFooterComponent={() => {
          return <ListFooter hasMore={hasMore} onClick={onLoadMore} />;
        }}
      />
    </Box>
  );
};
export default QuoteRequests;
